import React, { useState, useEffect } from 'react';
import { queryVms } from '../../../utils/vmsQuery';
import { ShowTileArea } from '../../widgets/tile-areas';
import UserContext from '../../contexts/UserContext';


const UserFavorites = () => {
	const [tiles, setTiles] = useState([]);
	const { pageRefresh } = React.useContext(UserContext);
	let params = {};
	useEffect(() => {
		const getFavs = async () => {
			const responseFavs = [];
			const favPromises = await makeApicall();
			if (favPromises) {
				Promise.all(favPromises).then((values) => {
					if (values.length > 0) {
						values.map((value) => {
							if (value.count > 0) responseFavs.push(value?.results[0] || {})
						})
						setTiles(responseFavs)
					}
				});
			}
		}
		getFavs()
	}, [pageRefresh])
	const makeApicall = async () => {
		try {
			const items = await (JSON.parse(window.localStorage.getItem('favorites'))?.filter(item => item.type !== 'channel'));
			if (!items) {
				setTiles(tiles)
				return null;
			}
			return items.map(async (item) => {
				params = item.group_id;
				const response = await queryVms({
					guid: params,
				});
				return response;
			})
		} catch (e) {
			console.log(e);

		}
	}
	return (
		<div className="userfavorites">
			{tiles.length > 0 && <ShowTileArea items={tiles} ordering={2} title={'Favourite Shows'} />}
		</div>
	)
};

export default UserFavorites;