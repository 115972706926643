import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './OverlayMessageModal.scss'

const OverlayMessageModal = (props) => {
	const { heading, subHeading, buttonTextLeft, buttonTextRight, buttonLeftClick, buttonRightClick } = props;
	const [isLink, setIsLink] = useState(false)
	const [linkText, setlinkText] = useState([])

	useEffect(() => {
		if (subHeading.includes('https')) {
			const firstIndex = subHeading.indexOf('https');
			const linkEnd = subHeading.indexOf(' ', firstIndex)
			const firstTextSection = subHeading.slice(0, firstIndex)
			const linkSection = subHeading.slice(firstIndex, linkEnd)
			const secondSection = subHeading.slice(linkEnd)
			setlinkText([firstTextSection, linkSection, secondSection])
			setIsLink(true)
		} else {
			setIsLink(false)
		}
	}, [])


	return (<div className='OverlayModelWrapper'>
		<div className='OverlayModel'>
			<div className='heading'>
				{heading}
			</div>
			{isLink ? (
				<div >
					<p>{linkText[0]}
						<a className='subHeadingLink' href={linkText[1]} target='_blank' rel='noreferrer' >{linkText[1]}</a>
						{linkText[2]}</p>
				</div>
			) : (
				<div>
					<p>{subHeading}</p>
				</div>)}
			<div className='button-box'>
				<button className='overlayBtn button-primary' onClick={() => buttonLeftClick()}>{buttonTextLeft}</button>
				<button className='overlayBtn button-primary' onClick={() => buttonRightClick()}>{buttonTextRight}</button>
			</div>
		</div>
	</div>)
}

OverlayMessageModal.propTypes = {
	heading: PropTypes.string,
	subHeading: PropTypes.string,
	buttonTextLeft: PropTypes.string,
	buttonTextRight: PropTypes.string,
	buttonLeftClick: PropTypes.func,
	buttonRightClick: PropTypes.func,
}

export default OverlayMessageModal;