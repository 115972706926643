import React, { Component } from 'react';

import './Error.scss';

class Error extends Component {
	render() {
		return (
			<div className="Error">
				<h2 className="Error-title">Sorry!</h2>
				<span className="Error-message">Something&apos;s wrong! We&apos;re working hard to fix it. Try again soon.</span>
				<a href="/" className="Error-button button-primary">Go to Homepage</a>
			</div>
		)
	}
}

export default Error;
