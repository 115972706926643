import { getValueForKey } from './helpers';

const geolocationEndpointURL = process.env.REACT_APP_GEOLOCATION_ENDPOINT;

export const geolocationCookieName = 'globaltvgeolocation';

export const geolocationAcquiredEvent = 'geolocationacquired';

class Geolocation {
	acquiringGeolocation = false;
	location = null;

	acquireGeolocation() {
		if (this.acquiringGeolocation) { return; }

		this.acquiringGeolocation = true;
		const event = document.createEvent('Event');
		event.initEvent(geolocationAcquiredEvent, true, true);

		if (!this.parseGeolocationCookie()) {
			const xhr = new XMLHttpRequest();
			xhr.open('GET', `${geolocationEndpointURL}?format=json`, true);
			xhr.responseType = 'json';
			xhr.onload = () => {
				const { status } = xhr;
				let { response } = xhr;
				if (status === 200 && response !== null) {
					if (typeof response === 'string') {
						response = JSON.parse(response);
					}

					const latitude    = response.location && response.location.latitude;
					const longitude   = response.location && response.location.longitude;
					const subdivision = response.subdivisions && response.subdivisions[0] && response.subdivisions[0].names.en;
					const city        = response.city && response.city.names.en;

					this.setGeolocationCookie(latitude, longitude, subdivision, city);

					document.dispatchEvent(event);
				} else {
					console.error('Geolocation error');
					console.error(xhr.response);
				}
			};

			xhr.send();
		} else {
			document.dispatchEvent(event);
		}
	}

	setGeolocationCookie(latitude, longitude, subdivision, city) {
		this.location = {
			latitude,
			longitude,
			subdivision,
			city,
		}

		const date = new Date();
		date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));

		document.cookie = `${geolocationCookieName}=lat=${latitude}|long=${longitude}|sudivision=${subdivision}|city=${city};path=/;expires=${date.toUTCString()}`;
	}

	parseGeolocationCookie() {
		const cookieValue = getValueForKey(document.cookie, geolocationCookieName);

		if (cookieValue === false) {
			return false;
		} else {
			this.location = {
				latitude: getValueForKey(cookieValue, 'lat', '=', '|'),
				longitude: getValueForKey(cookieValue, 'long', '=', '|'),
				sudivision: getValueForKey(cookieValue, 'sudivision', '=', '|'),
				city: getValueForKey(cookieValue, 'city', '=', '|'),
			}

			return true;
		}
	}

	distanceToLocation(latitude, longitude) {
		// Uses Haversine Formula
		const r = 6371; // diameter of earth, in kilometers

		if (!this.location) {
			return -1;
		}

		const deltaLat = this.degreesToRadians(this.location.latitude - latitude);
		const deltaLong = this.degreesToRadians(this.location.longitude - longitude);
		const locationLatRad = this.degreesToRadians(this.location.latitude);
		latitude = this.degreesToRadians(latitude);

		const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) + Math.sin(deltaLong / 2) * Math.sin(deltaLong / 2) * Math.cos(latitude) * Math.cos(locationLatRad);
		const c = 2 * r * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		return c;
	}

	degreesToRadians(degrees) {
		return degrees * Math.PI / 180;
	}
}

const geolocation = new Geolocation();

export default geolocation;
