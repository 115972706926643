import React, { useEffect, useState } from 'react';
import moment from 'moment'
import fromVms from '../../utility/fromVms';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import '../../partials/ShowSection/ShowSection.scss';
import './ChannelMetaData.scss'
import LogoImageFromVMS from '../../utility/logoImageFromVMS';
import { byOrigin } from '../../../brands/brands';
import { truncateWithEllipsis } from '../../../utils/helpers';

const StyledDiv = styled.div`
	display: flex;
	gap:16px;
	align-self: center;
	font-size: 16px;
	height: 332px
	color: black;
	position: sticky;
    left: 170px;
`
const Styledbutton = styled.span`
	display: flex;
	align-items: center;
	background : #ffffff;
	height: 16px;
	color: #000000;
	font-size : 12px;
	font-weight : 700;
	padding : 3px;
	margin-right: 5px;
`
const ChannelMetaData = (props) => {
	const { tile } = props;
	const router = useHistory();
	const [channel, setChannel] = useState(tile);
	const { origin, label, data, guid, resources } = channel;
	const { episode_title, episode_number, season_number, show_type, rating, captioned, description, artwork, generic_episode, year } = data;
	const placeholderImage = byOrigin(origin).placeholderImages.showVertical
	let season, episode ;
	season = episode = '';
	const excluded_ShowTypes = ['Soaps', 'News', 'Talk Show']
	const showTypeCheck = excluded_ShowTypes.filter(text => {return (show_type?.search(text) === 0)}).length === 0
	if (label != 'Movie' && !generic_episode && showTypeCheck) {
		episode = (episode_number && (/^[0-9]+$/.test(episode_number))) ? `E${episode_number}` : '';    // Test for Jargon Values
		season = season_number ? `S${season_number}` : ''
	}
	const title = (season || episode) ? ` | ${episode_title}` : `${episode_title}`;
	let movie_year = year && label === 'Movie' ? `${year} | ` : ''
	// Artwork poster for Schedule Grid
	let imageUrl, altTag;
	if (origin === 'globalnewsott') {
		imageUrl = resources.find((resource) => resource.tag === 'show_vertical').uri;
		altTag = `Global ${label}`
	}
	else {
		const posterImage = artwork ? (label === 'Movie' ? artwork?.movie_poster : artwork?.poster) : placeholderImage
		imageUrl = artwork ? `https://cdn.tvpassport.com/image/show/480x720/${posterImage}` : placeholderImage
		altTag = `${label} image`
	}

	const getLabel = (show) => {
		let now = moment();
		const start = moment.unix(show.start_time_code).format('h:mm A')
		const end = moment.unix(show.end_time_code)
		if (channel.type === 'schedule') {
			if (now.unix() >= show.start_time_code && now.unix() <= show.end_time_code) {
				const diff = end.diff(moment())
				const remaining = moment.duration(diff)
				return { time: `On Now - ${start} - ${end.format('h:mm A')}`, remaining: `${remaining.minutes()}m remaining` }
			} else if (show.start_time_code >= now.unix() && show.end_time_code <= now.endOf('day').unix()) {
				return { time: `On Today - ${start} - ${end.format('h:mm A')}`, remaining: '' }
			} else {
				const start = moment.unix(show.start_time_code).format('h:mm A');
				return { time: `On ${moment.unix(show.start_time_code).format('dddd, MMMM DD, YYYY')} - ${start} - ${end.format('h:mm A')} `, remaining: '' }
			}
		} else return { time: 'On Now - Live News 24/7', remaining: '' }
	}
	const channelCTA = () => {
		const channelId = channel.parent ?? guid  // channelId will be the parent guid in case of show data from any channel
		return router.push(`/channel/${channelId}/?action=play`, router?.location?.pathname);
	}
	useEffect(() => {
		setChannel(tile)
	}, [tile])
	return (
		<StyledDiv >
			<div className='artwork'>
				<img src={imageUrl} alt={altTag}
					onError={(e) => {
						if (e.target.src !== placeholderImage) {
							e.target.onerror = null;
							e.target.src = placeholderImage;
						}
					}} />
			</div>
			<div className={'ShowSection'} style={{ margin: 0, padding: 0 }}>
				{/* renders origin logo */}
				<div className='MetaData-header'>
					<span><LogoImageFromVMS classes={'ShowSection-logo ShowSection-logo-' + origin} origin={origin} /></span>
					<span style=
						{{
							fontWeight: '500',
							marginLeft: '24px'
						}}>
						{getLabel(channel).time}
					</span>
					<span style=
						{{
							fontWeight: '400',
							marginLeft: '14px',
							color: '#b7b7b7'
						}}>
						{getLabel(channel).remaining}
					</span>
					<button aria-label="Close Show Section" className="Close-cross Close-show-metadata" onClick={() => props.closeShowMetadata()}>
						<span className="Close">&times;</span>
					</button>
				</div>

				{/* renders show metadata*/}
				<span className='MetaData-title'>{label === 'Movie' ? episode_title : label}</span>
				{channel.type === 'schedule' &&
					<div className="Metadata-Subtitle">
						{data?.new ? <Styledbutton> NEW </Styledbutton> : null}
						{<span style={{ fontWeight: '500', marginRight: '5px' }}>{`${season} ${episode}  `}</span>}
						{(episode_title && label !== 'Movie') &&
							<span className ='MetaData-label'>
								{truncateWithEllipsis(title,100)}
							</span>}
					</div>}
				{description && <span className="MetaData-description">{description}</span>}
				{channel.type === 'schedule' && data.cast && <div className='Metadata-cast'>{data.cast}</div>}
				{channel.type === 'schedule' && <span className='Metadata-rating'>
					{movie_year}{show_type && `${show_type} | `}{rating && `${rating} |`}{captioned ? <Styledbutton style={{ marginLeft: '5px' }}>cc</Styledbutton> : null}
				</span>}

				{/* renders watch button*/}
				<div className='metadata-watch-buttons'>
					<button onClick={channelCTA} draggable="false" className="Movie-play-button button-primary">
						Watch Channel
					</button>
				</div>
			</div>
		</StyledDiv>
	)
}
const propMapping = (props) => {
	return {
		guid: props.videoId,

	}
}
ChannelMetaData.propTypes = {
	tile: PropTypes.object,
	parent: PropTypes.string,
	items: PropTypes.array,
	origin: PropTypes.string,
	videoId: PropTypes.string,
	closeShowMetadata: PropTypes.func,
}

export default fromVms(ChannelMetaData, propMapping);