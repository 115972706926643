import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SocialIcon.scss';
import siFacebook from '../../../../assets/icons/si-facebook.svg';
import siTwitter from '../../../../assets/icons/si-twitter.svg';
import siInstagram from '../../../../assets/icons/si-instagram.svg';

const SOCIAL_SITES = {
	facebook: {
		image: siFacebook,
		displayName: 'Facebook',
		url: 'https://www.facebook.com/GlobalTV/',
	},
	twitter: {
		image: siTwitter,
		displayName: 'Twitter',
		url: 'https://twitter.com/globaltv/',
	},
	instagram: {
		image: siInstagram,
		displayName: 'Instagram',
		url: 'https://www.instagram.com/globaltv/',
	}
}

class SocialIcon extends Component {
	static propTypes = {
		type: PropTypes.string,
	}

	render() {
		const site = SOCIAL_SITES[this.props.type];
		const linkProps = {
			className: `SocialIcon SocialIcon--${this.props.type}`,
			href: site.url,
			target: '_blank',
			rel: 'noopener noreferrer',
			title: `Visit us on ${site.displayName}`,
		}

		return (
			(this.props.type === 'twitter') ?
				<a {...linkProps}  >
					<img src={site.image} />
				</a>
				:
				<a {...linkProps} style={{ backgroundImage: `url(${site.image})` }}>{linkProps.title}</a>
		)
	}
}

export default SocialIcon;
