import React, { Component } from 'react';
import withSearch from '../../utility/withSearch';

import './NotFound.scss';

class NotFound extends Component {
	render() {
		return (
			<div className="NotFound">
				<h2 className="NotFound-title">404 Error</h2>
				<span className="NotFound-message">Sorry! We can&apos;t seem to find the page you&apos;re looking for.</span>
				<a href="/" className="NotFound-button button-primary">Go to Homepage</a>
			</div>
		)
	}
}

export default withSearch(NotFound);
