import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Error.scss';

class CustomError extends Component {
	static propTypes = {
		message: PropTypes.string,
		buttonText: PropTypes.string,
		link: PropTypes.string,
	}
	render() {
		const buttonText = this.props.buttonText || 'Go to Homepage';
		const link = this.props.link || '/';
		return (
			<div className="Error custom-error">
				<h2 className="Error-title">Sorry!</h2>
				<span className="Error-message">{this.props.message}</span>
				<a href={link} className="Error-button button-primary">{buttonText}</a>
			</div>
		)
	}
}

export default CustomError;