import React from 'react';
import PropTypes from 'prop-types'
import checkMark from '../../../assets/icons/checkMark.svg';
import './AppPromoSection.scss'


const AppPromoSection = (props) => {
	const { heading, subHeading, buttonText, bodyDescription, callbackCTA } = props
	return (
		<>
			<div className='AppInfo-textBox'>
				<h2 className='textBox-heading'>{heading}</h2>
				<p className='textBox-subHeading'>{subHeading}</p>
			</div>
			<div className='AppInfo-body'>
				{bodyDescription.map(text => {
					return (<div className='AppInfo-description' key ={text}>
						<img style={{ padding: 10 }} src={checkMark} />
						<p>{text}</p>
					</div>)
				})}
			</div>
			<button className='button-primary button-promo' onClick={() => callbackCTA()}>{buttonText}</button>
		</>
	)
}

AppPromoSection.propTypes = {
	heading: PropTypes.string,
	subHeading: PropTypes.string,
	buttonText: PropTypes.string,
	bodyDescription: PropTypes.array,
	callbackCTA: PropTypes.func
}

export default AppPromoSection;