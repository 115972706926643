import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UserContext from '../../contexts/UserContext';
import BrandBar from '../BrandBar/BrandBar';
import GenreBar from '../GenreBar/GenreBar';
import ContinueWatching from '../ContinueWatching/ContinueWatching';
import fromVms from '../../utility/fromVms';
import { addingPermutiveValues } from '../../../utils/permutiveHelper';
import AdvertisementBanner from '../Hero/AdvertisementBanner';
import Hero from '../Hero/Hero';
import ShowTileArea from '../../widgets/tile-areas/ShowTileArea/ShowTileArea';
import VideoTileArea from '../../widgets/tile-areas/VideoTileArea/VideoTileArea';
import UserFavorites from '../UserFavorites/UserFavorites';
import { queryVms } from '../../../utils/vmsQuery';

const continueWatching = (
	<ContinueWatching key={'continueWatching'} />
)

const userFavorites = (
	<UserFavorites key={'userfavorites'} />
)

const FeaturedView = ({ items, showHero, isFree, viewId, section }) => {
	const [data, setData] = useState([])
	useEffect(() => {
		let responses = [];
		const getResponses = async () => {
			const values = await makeAPIRequest(items.map(item => { return { guid: item.guid, order: item.data.default_ordering } }
			))
			Promise.all(values).then((values) => {
				if (values.length > 0) {
					values.map((item) => {
						responses.push(item)
					})
					setData(responses)
				}
			});
		}
		getResponses()
	}, [items])

	const makeAPIRequest = async (collections) => {
		try {
			return collections.map(async (collection) => {
				const { results } = await queryVms({
					limit: 99,
					ordering: collection.order || 'label',
					parent: collection.guid,
					type: ['query', 'media', 'episode', 'channel', 'series', 'movie', 'document'],
				});
				return { guid: collection.guid, results };
			})
		} catch (e) {
			console.log(e);
		}
	}
	const { isHome, signedIn, authenticated_origins, selectedBDU } = React.useContext(UserContext);
	const login = (signedIn && authenticated_origins) ? 'authenticated' : 'non-authenticated';
	let sections = [];
	let [checkPermitive, setCheckPermitive] = React.useState(false);  // Stop multiple rendering event
	let checkPage = localStorage.getItem('currentPage');
	if (!checkPage) {
		localStorage.setItem('currentPage', window.location.pathname.includes('series') ? 'Shows' : 'Home');
		checkPage = localStorage.getItem('currentPage');
	}
	// Sort based on Curated Order shown in the VMS dashboard
	let viewComponents = items.filter((i) => i.order !== null).sort((a, b) => (a.order - b.order));

	viewComponents = viewComponents.map((item, index) => {

		const { data: { title, deeplink_id, collection_id, default_ordering }, guid, label, type, tags } = item;
		const collectionItems = data.find(collection => collection.guid === guid)?.results;
		const dataType = data.find(collection => collection.guid === guid)?.results[0]?.type
		sections.push(label);
		var componentProps = {};
		/**
		 * Set ordering for components
		 * this will override the specific component defined props
		 */
		if (default_ordering) {
			componentProps.ordering = default_ordering;
		}
		tags.map(tag => {
			if (tag.type === 'behaviour_decorator') {
				componentProps.behaviour = tag.value
			}
		})
		// Display rotator
		if (type === 'rotator' || title === 'Global Stream Rotator') {
			return showHero && <Hero count={10} title={title} order={index} key={guid} parent={guid} />;
		}

		if (tags.find(tag => tag.value === 'brand_row')) {
			// Display brand bar
			if (collection_id === 'brand_picker') {
				return <BrandBar
					key={guid}
					parentId={guid}
					title={title}
					orderNumber={index}
					type={['origin', 'document']}
					urlPrefix='/shows/'
				/>
			}
			//Display Genre bar
			else {
				return <GenreBar
					key={guid}
					parentId={guid}
					title={title}
					orderNumber={index}
					section={section}
					urlPrefix={`/segmentedview/${guid}`}
				/>
			}
		}

		if (type === 'advertisement') {
			const { resources } = item
			let imageUrl = resources.find((resource) => resource.tag === 'promo_background')?.uri;
			let link = resources.find((resource) => resource.type === 'link')?.uri;
			let tag = tags.find((tag) => tag.type === 'action_decorator')?.value
			return <AdvertisementBanner item =  {item} key={item.label} origin={item.origin} altTag={item.label} heroImageURL={imageUrl} link={link} tag={tag} />
		}

		let checkForVideoTile = ['channel', 'media', 'episode'].find(type => { return dataType === type })
		let checkForShowTile = ['series', 'movie', 'document'].find(type => { return dataType === type })

		if (checkForVideoTile) {
			return <VideoTileArea
				items={collectionItems}
				key={guid}
				ordering={'label'}
				title={title}
				parentId={guid}
				itemsProps={true}
				expanded={dataType === 'channel' ? false : true}
				order={index}
				type={dataType} // mandatory to pass type as 'channel' for customized videoTile experience
				{...componentProps}
			/>
		}
		/**
		 * Display shortform video content from VMS.
		 * We need to add custom metadata called collection_id = 'shortform_video_content'
		 */
		else if (dataType === 'query' && collection_id === 'shortform_video_content') {
			return <VideoTileArea
				count={99}
				key={guid}
				ordering={'label'}
				parentId={guid}
				seasonId={guid} // Need this to set the parent ID
				title={title}
				order={index}
				expanded={true}
				type={['media', 'episode']}
				{...componentProps}
			/>
		}

		else if (dataType === 'query') {
			return <ShowTileArea
				count={99}
				key={guid}
				ordering={'label'}
				parentId={guid}
				seasonId={guid} // Need this to set the parent ID
				title={title}
				order={index}
				expanded={true}
				type={['movie', 'episode', 'series']}
				{...componentProps}
			/>
		}

		// For rest of the items, display ShowTileArea
		else if (checkForShowTile) {
			return <ShowTileArea
				items={collectionItems}
				enableCTA={!title.includes('Global')}
				key={guid}
				parentId={guid}
				title={label}
				order={index}
				brands={deeplink_id}
				{...componentProps}
				isFree={isFree}
			/>
		}
	})

	if (viewComponents.length) {
		// Add permutive
		// Add-in Continue to Watch
		if (isHome) {
			if (!checkPermitive) {
				setCheckPermitive(!checkPermitive);
				addingPermutiveValues({
					content: {
						type: 'Home'
					},
					user: {
						auth: {
							status: login,
							...(login && { bdu: selectedBDU })
						}
					}
				});

			}
			viewComponents.splice(2, 0, continueWatching);
			viewComponents.splice(3, 0, userFavorites);

		} else {
			if (!checkPermitive) {
				setCheckPermitive(!checkPermitive);
				if (checkPage == 'News') {
					addingPermutiveValues({
						content: {
							type: 'News'
						},
						user: {
							auth: {
								status: login,
								...(login && { bdu: selectedBDU })
							}
						}
					})
				}

			}
		}
		return viewComponents;
	} else {
		return null;
	}
}

const propMapping = ({ viewId }) => {
	return {
		parent: viewId,
		limit: 50,
	}
}

FeaturedView.propTypes = {
	showHero: PropTypes.bool,
	isFree: PropTypes.bool,
	items: PropTypes.any,
	viewId: PropTypes.string,
	section: PropTypes.string
}

export default fromVms(FeaturedView, propMapping, true);
