import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import withSearch from '../../utility/withSearch';
import { queryVms } from '../../../utils/vmsQuery';
import Spinner from '../../partials/Spinner/Spinner';
import UserContext from '../../contexts/UserContext';

import * as Views from '../';

/**
 * Set the view according to the view ID
 * - Get the view ID
 * - Fetch the view data
 * - render the view
 */

class ViewFinder extends Component {
	static contextType = UserContext;

	static propTypes = {
		location: PropTypes.object,
		match: PropTypes.shape({
			params: PropTypes.shape({
				viewGuid: PropTypes.string,
			})
		})
	}

	constructor() {
		super();

		this.state = {
			errorState: false,
			viewObjects: false,
		}
	}

	componentDidMount() {
		if (!this.state.viewObjects) {
			this.fetchFeaturedViews();
		}
	}

	componentDidUpdate() {
		/**
		 * Fetch when user navigate through menu
		 */
		if (this.state.viewObjects.guid !== this.props.match.params.viewGuid) {
			this.fetchFeaturedViews();
		}
	}

	/**
	 * Get the view object and set to viewObjects
	 */
	fetchFeaturedViews = async () => {
		try {
			const viewData = await queryVms({
				guid: this.props.match.params.viewGuid,
				limit: 1,
			});

			viewData.results.forEach(view => {
				let viewObj = {
					guid: view.guid,
					name: view.data.name,
					viewId: view.data.view_id,
				};
				this.setState({ viewObjects: viewObj })
				const query = queryString.parse(window.location.search);

				if (query.origin) {
					viewObj.origin = query.origin;
				}

				// Set last knows view to userContext
				this.context.update('lastView', viewObj);
			});

		} catch (e) {
			this.setState({ errorState: 'Failed to fetch views' });
		}
	}

	render() {
		if (this.state.viewObjects) {

			/**
			 * Homepage view
			 */
			if (this.state.viewObjects.viewId === 'web_home_view' || this.state.viewObjects.viewId === 'news-shared') {
				return <Views.Home {...this.props} viewId={this.state.viewObjects.guid} page={this.state.viewObjects.name} view={this.state.viewObjects} location={window.location} />
			}

			/**
			 * Free Section view
			 */
			if (this.state.viewObjects.viewId === 'free-shared') {
				return <Views.FreeSection {...this.props} viewId={this.state.viewObjects.guid} page={this.state.viewObjects.name} view={this.state.viewObjects} location={window.location} />
			}

			/**
			 * Shows page view
			 */
			if (this.state.viewObjects.viewId === 'shared_view_shows') {
				return <Views.Shows {...this.props} viewId={this.state.viewObjects.guid} page={this.state.viewObjects.name} view={this.state.viewObjects} />
			}

			/**
			 * Live TV view
			 */
			if (this.state.viewObjects.viewId === 'schedule-shared') {
				return <Views.ScheduleTv {...this.props} viewId={this.state.viewObjects.guid} page={this.state.viewObjects.name} view={this.state.viewObjects} />
			}

			/**
			 * Holiday Section view
			 */
			if (this.state.viewObjects.viewId === 'holiday-shared') {
				return <Views.HolidaySection {...this.props} viewId={this.state.viewObjects.guid} page={this.state.viewObjects.name} view={this.state.viewObjects} location={window.location} />
			}
		}

		return <Spinner color="white" />

	}
}

export default withSearch(ViewFinder);
