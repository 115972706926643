import React, { Component } from 'react';
import UserContext from '../../contexts/UserContext';
import SearchIcon from '../../../assets/icons/search-icon-gray.svg';

import './SearchInput.scss';

class SearchInput extends Component {
	static contextType = UserContext;

	state = {
		bccSearchQuery: '',
		showSearchInput: false,
		typingTimeout: 0,
	}

	setQuery = e => {
		this.context.update('searchResultsCount', 0); // Cleanup on query change
		this.setState({ bccSearchQuery: e.target.value });

		// Set query only once user stopped typing. Limits unnecessary API calls
		if (this.state.typingTimeout) {
			clearTimeout(this.state.typingTimeout);
		}

		/**
		 * Update searchQuery context when user type more than 2 chars
		 */
		if ( e.target.value.length > 2 ) {
			this.setState({
				typingTimeout: setTimeout(() => {
					this.context.update('searchQuery', this.state.bccSearchQuery)
				}, 500)
			})
		}
	}

	showSearchInput = () => {
		this.setState({ showSearchInput: !this.state.showSearchInput })
	}

	resetSearchBox = () => {
		this.context.update('searchQuery', '')
		this.setState({ bccSearchQuery: '' })

		setTimeout(() => {
			this.setState({
				showSearchInput: false,
			})
		}, 3000);
	}

	render() {
		return (
			<>
				{ this.state.showSearchInput ?
					<div className='Search'>
						<input
							className='Search-box'
							onChange={e => this.setQuery(e)}
							placeholder='Search Title, Genre or Keyword'
							ref={i => i && i.focus()}
							type='text'
							value={this.state.bccSearchQuery}
						/>
						<button className='Search-box-icon'>
							{this.context.searchQuery ?
								<div className='Search-close-icon' onClick={() => this.resetSearchBox()}>&times;</div> :
								<img alt='Search Icon' className='Search-icon' src={SearchIcon}/>
							}
						</button>
					</div> :
					<div className='Search-icon-wrapper' onClick={() => this.showSearchInput()}><img alt='Search Icon' className='Search-icon' src={SearchIcon}/></div>
				}
			</>
		)
	}
}

export default SearchInput;