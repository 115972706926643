import React from 'react';
import { Link } from 'react-router-dom';
import SocialIcon from '../../widgets/social/SocialIcon/SocialIcon';
import './Footer.scss';

const Footer = () => {
	return (
		<div className="Footer">
			<div className="Footer-socialLinks">
				<SocialIcon type="facebook" />
				<SocialIcon type="twitter" />
				<SocialIcon type="instagram" />
			</div>

			<ul className="Footer-nav" role="navigation">
				<li className="Footer-nav-item">
					<a className="Footer-nav-item-link" href="https://www.corusent.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy</a>
				</li>
				<li className="Footer-nav-item">
					<a className="Footer-nav-item-link" href="https://www.corusent.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use</a>
				</li>
				<li className="Footer-nav-item">
					<a className="Footer-nav-item-link" href="https://www.corusent.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
				</li>
				<li className="Footer-nav-item">
					<a className="Footer-nav-item-link" href="https://www.globaltv.com/faq/" target="_blank" rel="noopener noreferrer">FAQ</a>
				</li>
				<li className="Footer-nav-item">
					<a className="Footer-nav-item-link" href="https://www.globaltv.com/faq/#contact" target="_blank" rel="noopener noreferrer">Contact Us</a>
				</li>
				<li className="Footer-nav-item">
					<a className="Footer-nav-item-link" href="https://www.corusent.com/advertising/" target="_blank" rel="noopener noreferrer">Advertise</a>
				</li>
				<li className="Footer-nav-item">
					<a className="Footer-nav-item-link" href="https://www.globaltv.com/faq/#faq-section-3-question-1" target="_blank" rel="noopener noreferrer">What is the Global TV App?</a>
				</li>
				<li className="Footer-nav-item">
					<Link className="Footer-nav-item-link" to="/globaltvapp">Global TV App</Link>
				</li>
			</ul>
			<div className="Footer-copyrightText">
				<span>© Corus Entertainment Inc., {new Date().getFullYear()}.</span>
				<span>All rights reserved. Corus Television.</span>
			</div>
			<a className="Footer-corusLogo" href="https://www.corusent.com/" target="_blank" rel="noopener noreferrer">Corus</a>
		</div>
	)
}

export default Footer;
