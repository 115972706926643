import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import './OnNowOnNext.scss';

import { formatTime } from '../../../../utils/helpers';
import OnNowOnNextLoader from './OnNowOnNextLoader';

const COMING_UP_ITEMS = 4;

class OnNowOnNext extends Component {
	static propTypes = {
		channelId: PropTypes.number,
		delay: PropTypes.number,
		isHideSchedule: PropTypes.bool,
		match: PropTypes.shape({
			params: PropTypes.shape({
				brand: PropTypes.string,
			}).isRequired,
		}).isRequired,
		stationTimezone: PropTypes.string,
	};

	// Cached calculated timezone offset from Toronto time based on station timezone.
	// Needed for error correction from Canwest API.
	timeOffset = 0;

	state = {
		loading: true,
		errorState: false,
		onNow: {
			showName: '',
			time: null,
		},
		comingUp: [],
	};

	// Reference to 5s trigger to update
	timeoutRef = null;

	// Lock variable to prevent multiple API calls
	apiCallInProgress = false;

	componentDidMount() {
		if (this.props.channelId !== null) {
			this.loadData();
		}
		this.timeoutRef = setInterval(() => this.triggerUpdate(), 2000);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.channelId !== this.props.channelId) {
			this.loadData();
		}
	}

	componentWillUnmount() {
		clearInterval(this.timeoutRef);
	}

	loadData() {
		this.setState({
			loading: true,
		})
		this.apiCallInProgress = true;

		const scheduleApiUrl = new URL(process.env.REACT_APP_SCHEDULE_ENDPOINT);
		scheduleApiUrl.searchParams.append('channelId', this.props.channelId);
		scheduleApiUrl.searchParams.append('days', '2');
		scheduleApiUrl.searchParams.append('light', 'true');

		const request = new Request(scheduleApiUrl);

		window.fetch(request).then(
			(response) => { response.json().then(
				(data) => this.finishLoading(data),
				(reason) => this.errorState(reason)
			) },
			(reason) => this.errorState(reason)
		);
	}

	finishLoading(data) {
		this.apiCallInProgress = false;

		let onNow =  {
			showName: '',
			time: null,
		};
		let comingUp = [];
		const currentTime = new Date();

		this.cacheTimeOffset();
		const parsedData = data
			.map(item => this.parseScheduleItem(item))
			.sort((a, b) => a.time - b.time);

		parsedData.forEach(item => {
			if (item.time < currentTime) {
				onNow = item;
			} else {
				comingUp.push(item);
			}
		});

		let delay = !this.props.delay ? 1500 : this.props.delay;

		setTimeout(() => {
			this.setState({
				loading: false,
				onNow,
				comingUp,
			});
		}, delay);
	}

	errorState(reason) {
		this.setState({
			loading: false,
			errorState: true,
		});
		console.log(reason);
	}

	triggerUpdate() {
		const comingUp = this.state.comingUp;
		if (!comingUp.length) return;
		if (comingUp[0].time < Date.now()) {
			if (comingUp.length - 1 < COMING_UP_ITEMS) {
				if (!this.apiCallInProgress) {
					this.loadData();
				}
			} else {
				this.setState({
					onNow: comingUp[0],
					comingUp: comingUp.slice(1),
				});
			}
		}
	}

	// Calculates the time offset between Toronto time and the chosen station's time.
	// Needed for Canwest API date correction.
	cacheTimeOffset() {
		const date = new Date();

		const stationDate = new Date(date.toLocaleString('en-US', { timeZone: this.props.stationTimezone }));
		const torontoDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/Toronto' }));

		this.timeOffset = stationDate - torontoDate;
	}

	parseScheduleItem(item) {
		const timestamp = item.ProgramStartTime.substr(6, 13);
		const time = Number.parseInt(timestamp) - this.timeOffset;

		return {
			showName: item.RootTitleName,
			time,
		}
	}

	render() {
		if (this.props.isHideSchedule) return null;

		return (
			<div className="OnNowOnNext">
				{this.state.loading ?
					<OnNowOnNextLoader />
					:
					<>
						<div className="OnNowOnNext-onNow">
							<div className="OnNowOnNext-onNow-title">On Now</div>
							<div className="OnNowOnNext-onNow-details">{this.state.onNow?.showName}</div>
							<div className="OnNowOnNext-onNow-time">{formatTime(this.state.onNow?.time, this.props.stationTimezone)}</div>
						</div>
						<div className="OnNowOnNext-comingUp">
							<div className="OnNowOnNext-comingUp-title">Coming Up</div>
							{this.state.comingUp.slice(0, COMING_UP_ITEMS).map((item, index) => (
								<div key={index} className="OnNowOnNext-comingUp-item">
									<div className="OnNowOnNext-comingUp-item-time">{formatTime(item.time, this.props.stationTimezone)}</div>
									<div className="OnNowOnNext-comingUp-item-showName">{item.showName}</div>
								</div>
							))}
						</div>
					</>
				}
			</div>
		);
	}
}

export default withRouter(OnNowOnNext);
