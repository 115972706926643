import React, { useContext, useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
import { getCookie } from '../../../utils/helpers';
import UserContext from '../../contexts/UserContext';
import ShowTileArea from '../../widgets/tile-areas/ShowTileArea/ShowTileArea';
import './Search.scss';

const Search = () => {
	const userContext = useContext(UserContext)
	let { searchQuery, searchResultsCount, update, configData: { configuration: { entries: { brands } } } } = userContext;
	let [totalCount, settotalCount] = useState(0);
	const globalRef = useRef();
	let origins = [];

	for (let key in brands) {
		origins.push(brands[key].origin)
	}

	useEffect(() => {
		if (globalRef.current && globalRef.current.props.searchKey === searchQuery) {
			var searchCounterTimeOut = setTimeout(() => {
				totalCount = globalRef.current.state.items.length
				settotalCount(totalCount);
				update('searchResultsCount', totalCount);
			}, 1000);
		}

		return () => {
			clearTimeout(searchCounterTimeOut);
		}
	}, [searchQuery, searchResultsCount])

	useEffect(() => {
		const consent = getCookie('consentCheck')
		if (consent !== 'declined') {
			window.CorusAdobeAnalytics.trackLink(true, {
				'corus.searchterm': searchQuery,
			}, 'Search term: ' + searchQuery);
		}
	}, [searchQuery]);

	return (
		<div className='Search-tile-grid'>
			<div className="Search-title">
				{totalCount && <CountUp
					start={0}
					end={totalCount}
					duration={2}
				/>} {totalCount === 1 ? 'result' : 'results'} for &quot;{searchQuery}&quot;
			</div>
			<ShowTileArea title="Search Results" ref={globalRef} origins={origins} isGrid={true} enableCTA={true} searchKey={searchQuery} />
		</div>
	);
};

export default Search;