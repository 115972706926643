/*Permitive function*/

export const addingPermutiveValues = async ({ ...props }) => {
	const salt = 'matt';
	const url = 'https://api.ipify.org';

	async function sendIPAddress() {
		try {
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`Failed to fetch IP address. Status: ${response.status}`);
			}
			const ipAddress = await response.text();

			// Use crypto.subtle for hashing
			const encoder = new TextEncoder();
			const data = encoder.encode(salt + ipAddress);
			const buffer = await crypto.subtle.digest('SHA-256', data);
			const salted_ip = Array.from(new Uint8Array(buffer))
				.map(byte => byte.toString(16).padStart(2, '0'))
				.join('');

			// Store hashed IP address in sessionStorage
			sessionStorage.setItem('hashed_userId', salted_ip);

			// Identify with Permutive
			window?.permutive?.identify([
				{
					id: salted_ip,
					tag: 'ip_address',
					priority: 0
				}
			]);

			// Addon for Permutive
			window?.permutive?.addon('web', { page: { ...props } });
		} catch (error) {
			console.error('Error in addingPermutiveValues:', error);
		}
	}

	sendIPAddress();
};