import React, { Component } from 'react';
import PropTypes from 'prop-types';

import fromVms from '../../utility/fromVms';
import VideoPlayer from '../../widgets/video/VideoPlayer/VideoPlayer';

class LiveTVVideoPlayer extends Component {

	static propTypes = {
		items: PropTypes.array,
		loading: PropTypes.bool,
		children: PropTypes.any,
		videoID: PropTypes.string,
		playerRef: PropTypes.any,
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.loading !== this.props.loading) {
			return true;
		}

		return false;
	}

	render() {
		if (this.props.loading || !this.props.items.length) {
			// TODO: proper loading state?
			return null;
		} else {
			const video = this.props.items[0];
			return (
				<VideoPlayer
					liveTv={true}
					origin={video.origin}
					ref={this.props.playerRef}
					videoId={video.guid}
					requireAuth={video.origin !== 'globalnewsott'}
					{...video}
				>
					{this.props.children}
				</VideoPlayer>
			)
		}
	}
}

const propMapping = (props) => {
	return {
		guid: props.videoID,
	}
}

export default fromVms(LiveTVVideoPlayer, propMapping);