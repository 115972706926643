import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserContext from '../../contexts/UserContext';
import withSearch from '../../utility/withSearch';
import FeaturedView from '../../partials/FeaturedView/FeaturedView';
import withView from '../../utility/withView';
import './Home.scss';

class Home extends Component {
	static contextType = UserContext;

	static propTypes = {
		viewId: PropTypes.string,
		view: PropTypes.object,
	}

	componentDidMount() {
		/**
		 * Validate the homepage
		 * - Check no pathname
		 * - Check the view name to identify the homepage
		 */
		if ( window.location.pathname === '/' || ( this.props.view && this.props.view.name === 'Homepage' ) ) {
			this.context.update('isHome', true);
			this.context.update('lastView', null);
		}
	}

	componentWillUnmount() {
		this.context.update('isHome', false);
		this.context.update('withRotator', false);
	}

	render() {
		let viewId = this.props.view ? this.props.view.guid : this.props.viewId;
		return (
			<div className="Home">
				<div className="HomeLoader" />
				<FeaturedView showHero={true} viewId={viewId} viewIsReady={this.viewIsReady} />
			</div>
		)
	}
}

export default withSearch(withView(Home, 'web_home_view'));