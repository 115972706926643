import React from 'react';

const UserContext = React.createContext();

export const createDefaultState = stateHost => {
	return {
		signedIn: false,
		authorizedResources: ['globalnewsott'],
		authenticated_origins:['globalnewsott'],
		authorizedResourcesChecked: false,
		authZToken: null,
		authTrackingId: null,
		mvpds: [],
		isHome: false,
		withRotator: false,
		isFullScreen: false,
		displayAuth: false,
		isEastlinkDialogShown: false,// check for display of Eastlink message only ones
		adobeUid: null, //null if not queried, false if queried but empty
		isAuthInProgress: true,
		isVmsAuthInProgress: true,
		searchQuery: '',
		searchResultsCount: 0,
		selectedBDU: null,
		lastView: null,
		loadedView: null,
		configData: null,
		channelData: null,
		pageRefresh: null, // Manually trigger the page refresh and need to reload the component everywhere.
		region: '',
		filteredView: ['web_home_view', 'news-shared','bdu','shared_view_shows', 'schedule-shared', 'free-shared', 'holiday-shared'], //  check to display only the desired views in the navigation menu
		update: (key, value) => {
			stateHost.setState({ [key]: value });
		},
		updateIfChanged: (key, value) => {
			if (stateHost.state[key] !== value) {
				stateHost.setState({ [key]: value });
			}
		},
		setState: (state, callback = null) => {
			stateHost.setState(state, callback);
		}
	}
}

export default UserContext;