import React from 'react';

export default function OnNowOnNextLoader() {
	const numberOfBlocks = 2;

	return (
		<>
			{[...Array(numberOfBlocks).keys()].map((number) => (
				<div className="OnNowOnNext-outer" key={number}>
					<div className="OnNowOnNext-inner">
						<div className="Thick-line"></div>
						<div className="Thin-line"></div>
						<div className="Thin-line"></div>
						<div className="Thin-line"></div>
						<div className="Thin-line"></div>
					</div>
				</div>
			))}
		</>
	)
}
