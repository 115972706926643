import React, { useState } from 'react'
import PropTypes from 'prop-types'

const GeneralFAQSection = ({ question, answer }) => {

	const [showAnswer, setShowAnswer] = useState(false)
	return (
		<div className={`Question-dialog Question-dialog--${showAnswer}`} >
			<div className='Question-section' onClick={() => { setShowAnswer(!showAnswer) }}>{question}</div>
			{showAnswer && <div className='Answer-section'>{answer}</div>}
		</div>
	)
}

GeneralFAQSection.propTypes = {
	question: PropTypes.string,
	answer: PropTypes.string,
}

export default GeneralFAQSection;