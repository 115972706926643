/* eslint-disable */

import { getCookie } from "../../../../utils/helpers";

// Global s variable for AppMeasurement.
var s; 

(function() {
    var MAX_HIERARCHY_LEVELS = 4;
    var PAGE_TRACKING_DELIMETER = '|';
    var settings;
    var TRACKABLE = 'trackable';
    var TRACKABLE_TITLE = 'trackable-title';
    var TRACKABLE_ITEM = 'trackable-item';
    var TRACKABLE_LINK_TITLE = 'trackable-link-title';
    var CONTEXT_DATA_PLACEHOLDER = 'NA';
    
    // Private util functions **************************************************************
    var util = {
        location: window.location, // Cache window.location
        date: new Date(), // Cache date object
        getHourOfDay: function () {
            return this.date.getHours();
        },
        getDayOfWeek: function () {
            var weekdays = new Array("Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday");
            return weekdays[this.date.getDay()];
        },
        isWeekend: function () {
            var dayOfTheWeek = this.getDayOfWeek();
            if (dayOfTheWeek == "Sunday" || dayOfTheWeek == "Saturday")
                return true;
            else
                return false;
        },
        getReferringDomain: function () {
            return document.referrer;
        },

        // Link tracking util functions
        generateContextDataString: function (dataFormat, trackingData) {
            var dataString = dataFormat.replace(/{{widgetName}}/, trackingData.widgetName)
                                        .replace(/{{widgetPosition}}/, typeof (trackingData.widgetPosition) !== 'undefined' ? trackingData.widgetPosition : CONTEXT_DATA_PLACEHOLDER)
                                        .replace(/{{widgetTitle}}/, typeof (trackingData.widgetTitle) !== 'undefined' && trackingData.widgetTitle.length > 0 ? trackingData.widgetTitle : CONTEXT_DATA_PLACEHOLDER)
                                        .replace(/{{itemPosition}}/, typeof (trackingData.itemPosition) !== 'undefined' && trackingData.itemPosition > 0 ? trackingData.itemPosition : CONTEXT_DATA_PLACEHOLDER)
                                        .replace(/{{itemLinkTitle}}/, typeof (trackingData.itemLinkTitle) !== 'undefined' && trackingData.itemLinkTitle.length > 0 ? trackingData.itemLinkTitle : CONTEXT_DATA_PLACEHOLDER)
                                        .replace(/{{currentPage}}/, window.location.pathname);

            return dataString
        },
        generateWidgetData: function ($widget) {
            var widgetView = $widget.data('view')
            var widgetTrackableTitle = $widget.find('.' + TRACKABLE_TITLE)[0];
            var data = {
                widgetName: $widget.data('widget') + (widgetView ? '-' + widgetView : ''),
                widgetPosition: $widget.data('position'),
                widgetTitle: $widget.data('trackableTitle') ? $widget.data('trackableTitle') : typeof (widgetTrackableTitle) !== 'undefined' ? jQuery(widgetTrackableTitle).text().replace(/^\s+/, '').replace(/\s+$/, '').replace(/\s+/, ' ') : '',
                itemLinkTitle: this.getLinkTitles($widget.find('.' + TRACKABLE_LINK_TITLE), 1)
            };

            return data;
        },
        getLinkTitles: function ($linkTitles, count) {
            var linkTitles = [];
            var titlesAdded = 0;

            $linkTitles.each(function () {
                if (typeof (count) === 'undefined' || titlesAdded < count) {
                    linkTitles.push(jQuery(this).text());
                    titlesAdded++;
                } else {
                    return false;
                }
            })

            return linkTitles.join('::');
        },
    };
    // End private util functions *****************************************************************
    
    var dataSetter = {
        /** 
         * Populate the dataSetter with the provided data.
         * For each key/value pair, if the key is a dataSetter function name, call it with the provided data.
         * Otherwise, set the context data with its value.
         * @param {any[]} data - An array of keys (functions or context data) and corresponding values.
         * @param {boolean} [isLinkTracking] - if true, will set linkTrackVars for each contextData item (leaves a leading comma).
         */
        populate: function( data, isLinkTracking ) {
            if ( isLinkTracking ) {
                s.linkTrackVars = '';
                s.linkTrackEvents = '';
            }

            if ( data ) {
                for ( var k in data ) {

                    if ( this[k] ) {
                        this[k].call( this, data[k] );
                    } else {
                        this.contextData( k, data[k], isLinkTracking );
                    }
                }
            }

            // Remove leading comma.
            if ( isLinkTracking && s.linkTrackVars ) 
                s.linkTrackVars = s.linkTrackVars.substr(1);
        },
        contextData: function( name, value, isLinkTracking, namespace ) {
            var label = name;

            // Check if namespace is already defined.
            if ( typeof( namespace ) == 'undefined' && name.indexOf('.') < 0 ) {
                label = 'content.' + label;
            } else if ( typeof( namespace ) != 'undefined' ) {
                label = namespace + '.' + label;
            }
            
            if (s){
                s.contextData = s.contextData || [];
            }
            if (isLinkTracking && s) {
                // Set the Omniture variables
                s.linkTrackVars += ',contextData.' + label;
            }
            if (s){
                s.contextData[ label ] = typeof( value ) === 'string' ? value.toLowerCase() : value;
            }

        },
        eVarsAndProps: function( data ) {
            for (var ind in data) {
                s.linkTrackVars += ',' + ind;
                s[ind] = data[ind];
            }
        },
        eventsList: function( list ) {
            if (list.length > 0) {
                s.linkTrackVars += ',events';
                s.linkTrackEvents = list.join(',');
                s.events = s.linkTrackEvents;
            }
        },
        pageViewParameters: function () {
            // Default context data settings.
            this.contextData( 'contenttitle', document.title );
            this.contextData( 'hourofday', util.getHourOfDay() );
            this.contextData( 'dayofweek', util.getDayOfWeek() );
            this.contextData( 'weekdayweekend', util.isWeekend() ? 'Weekend' : 'Weekday' );        
            
            this.campaignVariables();
            this.pageViewPercent();
    
            if ( settings.requiresAuthentication )
                this.authenticationParameters();
        },
        campaignVariables: function() {
            //External Campaign Tracking
            var cid = s.Util.getQueryParam( 'cid' );
            s.campaign = cid ? cid : s.Util.getQueryParam( 'utm_campaign' );

            // The getValOnce plug-in prevents a given variable from being set to the previously defined value. 
            // It uses a cookie to determine a variable's last seen value. If the current value matches the cookie value, 
            // the variable is overwritten with a blank string before it is sent to Adobe's processing servers.
            // s.campaign = s.getValOnce(s.campaign, 's_campaign', 0);  
            
            // need to comment the line 166 to retain the campaign value from url GSM-4459
    
            if ( s.campaign ) {
                // Google UTM.
                s.eVar18 = s.Util.getQueryParam( 'utm_medium' );
                s.eVar19 = s.Util.getQueryParam( 'utm_source' );
                s.eVar20 = s.Util.getQueryParam( 'utm_campaign' );
                s.eVar21 = s.Util.getQueryParam( 'utm_term' );
                s.eVar22 = s.Util.getQueryParam( 'utm_content' );

                this.contextData( 'referrer', util.getReferringDomain() );

                // campaign
                s.tempmedium = s.eVar18;
                if ( s.eVar19 === 'ie9' && utm_medium_override !== false ) {
                    s.tempmedium = utm_medium_override;
                }
                if ( s.eVar19 || s.eVar20 || s.eVar21 || s.eVar22 )
                    s.campaign = s.tempmedium + '|' + s.eVar19 + '|' + s.eVar20 + '|' + s.eVar21 + '|' + s.eVar22;
            }
        },
        pageViewPercent: function() {
            // Populate page view parameters and cookie page name for next page view.
            s.getPercentPageViewed(this.pagename);

            if (s._ppvPreviousPage) {
                this.contextData( 'previousPage', s._ppvPreviousPage );
                this.contextData( 'initialPercentViewed', this.formatPercent(s._ppvInitialPercentViewed) );
                this.contextData( 'highestPercentViewed', this.formatPercent(s._ppvHighestPercentViewed) );
            }
        },
        formatPercent: function( value ) {
            value = value.toString();

            // Prepend a zero if <10% for analytics to be able to bucket by first character.
            if (value.length < 2) {
                value = '0' + value;
            }
            return value + '%';
        },
        contentHierarchy: function( hierarchy ) {
            var sliceFrom = 0;

            if ( !hierarchy.length ) {
                hierarchy = [ 'homepage' ];
            }

            // Keep page name for getPercentPageViewed plugin.
            this.pagename = hierarchy.join(PAGE_TRACKING_DELIMETER);
            this.contextData( 'pagename', 'watch' + this.pagename );
            this.contextData( 'sitesection', 'watch' + hierarchy[0] );

            for (var i = 1; i <= MAX_HIERARCHY_LEVELS; i++) {
                var subSectionName = 'sitesubsection';

                if (i > 1)
                    subSectionName += i;

                var sectionLength = i + 1;
                this.contextData( subSectionName, 'watch' + hierarchy.slice(0, sectionLength).join(PAGE_TRACKING_DELIMETER) );
            }
        },
        authenticationParameters: function () {
            if (!s.Util.cookieRead('authN') || s.Util.cookieRead('authN') === '0') {
                this.contextData( 'authentication', 'Non-authenticated User' );

            } else {
                this.contextData( 'authentication', 'Authenticated User' );

                var authName = s.Util.cookieRead('authName');
                if ( authName ) {
                    this.contextData( 'authentication.bdu', authName );
                }

                var authTrackingId = s.Util.cookieRead('authTrackingId');
                if ( authTrackingId ) {
                    this.contextData( 'adobe.pass-id', authTrackingId );
                }

                var authAvailableChannels = s.Util.cookieRead('authAvailableChannels');
                if ( authAvailableChannels ) {
                    this.contextData( 'adobe.availablechannels', authAvailableChannels );
                }
            }
        },
    };

    var adobeAnalytics = {
        contextDataFormat: '{{widgetName}}|{{widgetPosition}}|{{widgetTitle}}|{{itemPosition}}|{{itemLinkTitle}}|{{currentPage}}',

        registerTrackableWidgets: function () {
            var that = this;
            var $trackableWidgets = jQuery('.' + TRACKABLE);
            $trackableWidgets.each(function () {
                var $widget = jQuery(this);
                var registrationTrigger = $widget.data('trigger');
                if (typeof (registrationTrigger) === 'undefined') {
                    that.registerTrackableWidget($widget);
                } else {
                    $widget.on(registrationTrigger, $.proxy(this.registerTrackableWidget, this, $widget));
                }
            });
        },
        registerTrackableWidget: function ($widget) {
            var that = this;

            var trackingData = util.generateWidgetData($widget);
            var action = $widget.data('track-action');

            if (typeof (action) === 'undefined') {
                var $widgetItems = $widget.find('.' + TRACKABLE_ITEM);
                if ($widgetItems.length > 0) {
                    var positionsSkipped = 0;
                    $widgetItems.each(function (idx) {
                        var itemPosition = jQuery(this).data('position');
                        if (typeof (itemPosition) === 'undefined') {
                            jQuery(this).data('position', idx + 1 - positionsSkipped);
                        } else {
                            positionsSkipped++;
                        }
                        that.trackWidgetItem(jQuery(this), trackingData);
                    });
                } else {
                    that.trackWidgetItem($widget, trackingData);
                }

            } else {
                $widget.find('a').bind('click', { trackingData: trackingData }, function (event) {
                    var itemAction = jQuery(this).data('track-action');
                    event.data.trackingData.itemLinkTitle = typeof (itemAction) !== 'undefined' ? itemAction : action;
                    if (typeof (event.data.trackingData.itemLinkTitle) !== 'undefined') {
                        that.sendUserActionData(util.generateContextDataString(that.contextDataFormat, event.data.trackingData));
                    }
                });
            }
        },
        trackWidgetItem: function ($item, trackingData) {
            var itemPosition, itemLinkTitle = '', myData = {};
            var trackMode = $item.data('track-mode');
            var that = this;

            // clone data
            for (var key in trackingData) {
                myData[key] = trackingData[key];
            }

            itemLinkTitle = $item.hasClass('.' + TRACKABLE_LINK_TITLE) ? $item.text() : (trackMode !== 'itemLevel' ? util.getLinkTitles($item.find('.' + TRACKABLE_LINK_TITLE), 1) : util.getLinkTitles($item.find('.' + TRACKABLE_LINK_TITLE)));
            myData.itemLinkTitle = itemLinkTitle ? itemLinkTitle : trackingData.itemLinkTitle;
            myData.itemPosition = $item.data('position');

            var $anchorLink = ($item.prop('tagName').toLowerCase() === 'a') ? $item : $item.find('a');
            $anchorLink.bind('click', { trackingData: myData, item: $item }, function (event) {
                var $target = jQuery(event.currentTarget);
                if ($target.hasClass('trackable-skipped')) return;

                var contextDataVar = $target.data('cd-var');
                if (!contextDataVar) {
                    var aTagText = $target.hasClass(TRACKABLE_LINK_TITLE) ? $target.text() : $target.find('.' + TRACKABLE_LINK_TITLE).text();
                    if (trackMode !== 'itemLevel') {
                        if ( event.data.item.data('trackableLinkTitle') ) {
                            event.data.trackingData.itemLinkTitle = event.data.item.data('trackableLinkTitle');
                        } else {
                            event.data.trackingData.itemLinkTitle = ((aTagText ? aTagText : event.data.trackingData.itemLinkTitle)).replace(/^\s+/, '').replace(/\s+$/, '');
                        }
                    }

                    that.sendLinkTrackingData(event.currentTarget, util.generateContextDataString(that.contextDataFormat, event.data.trackingData));
                } else {
                    var contextDataVal = $target.data('cd-val');
                    that.sendData(event.currentTarget, contextDataVal, contextDataVar, contextDataVar);
                }
            });
        },
        sendLinkTrackingData: function (dataOwner, dataString) {
            this.sendData(dataOwner, dataString, 'content.LinkTracking', 'Link Tracking');
            return false;
        },
        sendUserActionData: function (dataString) {
            this.sendData(true, dataString, 'content.UserAction', 'User Action');
            return false;
        },
        sendUserActionForWidget: function ($widget, action) {
            var trackingData = util.generateWidgetData($widget);
            trackingData.itemLinkTitle = action;
            this.sendUserActionData(util.generateContextDataString(this.contextDataFormat, trackingData));
        },
        sendData: function (dataOwner, dataString, name, description) {
            // Make sure the analytics object exists
            s.contextData = (s.contextData && s.contextData ? s.contextData : []);

            // Set the Omniture variables
            var data = {};
            data[name] = dataString;

            this.trackLink(dataOwner, data, description);
            return false;
        },
        trackLink: function (dataOwner, data, description) {
            if (typeof (dataOwner) !== 'boolean') {
                var target = jQuery(dataOwner).attr('target');
                if (target === '_blank') {
                     // set to true to bypass 500ms delay for sending tracking call, as we are opening new window
                    dataOwner = true;
                }
            }
            //Evidon Blocking initialization
            if (s){
                dataSetter.populate(data, true);
            } 

            // if dataOwner is not an <a href>, pass "true" to bypass 500s delay
            s.tl(dataOwner, 'o', description, null, 'navigate'); // Documentation: http://microsite.omniture.com/t2/help/en_US/sc/implement/function_tl.html
    
            s.clearVars();
        },
        trackPage: function ( data ) {
            dataSetter.populate(data);
            dataSetter.pageViewParameters();
     
            var s_code = s.t();
            if (s_code)
                document.write( s_code );
    
        },
        init: function (options) {
            settings = options;
            
            // SiteCatalyst settings.
            s = new AppMeasurement(options.account);
            s.trackingServer = options.trackingServer;
            s.trackingServerSecure = options.trackingServerSecure;
            s.trackInlineStats = true;
            s.trackDownloadLinks = true;
            s.trackExternalLinks = false;
            s.linkDownloadFileTypes = "exe,zip,wav,mp3,mov,mpg,avi,wmv,pdf,doc,docx,xls,xlsx,ppt,pptx";
            s.linkLeaveQueryString = false;
            s.linkTrackVars = '';
            s.linkTrackEvents = '';
            s.cookieDomain = window.location.hostname.replace('www', '*');
    
            /* Adding VisitorAPI to AppMeasurement */
            var corus_visitor = new Visitor("5F34123F5245B4A70A490D45@AdobeOrg");
            corus_visitor.trackingServer = options.trackingServer;
            corus_visitor.trackingServerSecure = options.trackingServerSecure;
            corus_visitor.marketingCloudServer = options.marketingCloudServer;
            corus_visitor.marketingCloudServerSecure = options.marketingCloudServerSecure;
    
            s.visitor = corus_visitor;
            dataSetter.contextData( 'visitorapi', (typeof (Visitor) != 'undefined' ? 'visitorapi present' : 'visitorapi missing'), false, 'omn' );
            dataSetter.contextData( 'mid', corus_visitor.getMarketingCloudVisitorID(), false, 'adobe' );
            dataSetter.contextData( 'site', options.site );
            
            if (options.html5PlayerEnabled) {
                s.loadModule('Media');
                s.Media.autoTrack = false;
                s.Media.trackWhilePlaying = true;
                s.Media.trackVars = '';
                s.Media.trackEvents = '';
                s.Media.playerName = 'CorusVideoPlayer-html5';
            }

            /* Adobe Consulting Plugin: getPercentPageViewed v3.0 w/handlePPVevents helper function (Requires AppMeasurement and p_fo plugin) */
            s.getPercentPageViewed = function(pid,ch){var s=this,a=s.c_r("s_ppv");a=-1<a.indexOf(",")?a.split(","):[];a[0]=s.unescape(a[0]); pid=pid?pid:s.pageName?s.pageName:document.location.href;s.ppvChange=ch?ch:!0;if("undefined"===typeof s.linkType||"o"!==s.linkType)s.ppvID&&s.ppvID===pid||(s.ppvID=pid,s.c_w("s_ppv",""),s.handlePPVevents()),s.p_fo("s_gppvLoad")&&window.addEventListener&&(window.addEventListener("load",s.handlePPVevents,!1),window.addEventListener("click",s.handlePPVevents, !1),window.addEventListener("scroll",s.handlePPVevents,!1),window.addEventListener("resize",s.handlePPVevents,!1)),s._ppvPreviousPage=a[0]?a[0]:"",s._ppvHighestPercentViewed=a[1]?a[1]:"",s._ppvInitialPercentViewed=a[2]?a[2]:"",s._ppvHighestPixelsSeen=a[3]?a[3]:""};

            /* Adobe Consulting Plugin: handlePPVevents helper function (for getPercentPageViewed v3.0 Plugin) */
            s.handlePPVevents = function(){if(s_c_il){for(var b=0,d=s_c_il.length;b<d;b++)if("undefined"!=typeof s_c_il[b]&&s_c_il[b]._c&&"s_c"==s_c_il[b]._c){var a=s_c_il[b];break}if(a&&a.ppvID){var f=Math.max(Math.max(document.body.scrollHeight,document.documentElement.scrollHeight),Math.max(document.body.offsetHeight,document.documentElement.offsetHeight),Math.max(document.body.clientHeight,document.documentElement.clientHeight));b=(window.pageYOffset||window.document.documentElement.scrollTop||window.document.body.scrollTop)+(window.innerHeight||document.documentElement.clientHeight||document.body.clientHeight);d=Math.min(Math.round(b/f*100),100);var e="";!a.c_r("s_tp")||a.unescape(a.c_r("s_ppv").split(",")[0]) !==a.ppvID||1==a.ppvChange&&a.c_r("s_tp")&&f!=a.c_r("s_tp")?(a.c_w("s_tp",f),a.c_w("s_ppv","")):e=a.c_r("s_ppv");var c=e&&-1<e.indexOf(",")?e.split(",",4):[];f=0<c.length?c[0]:escape(a.ppvID);var g=1<c.length?parseInt(c[1]):d,h=2<c.length?parseInt(c[2]) :d;c=3<c.length?parseInt(c[3]):b;0<d&&(e=f+","+(d>g?d:g)+","+h+","+(b>c?b:c));a.c_w("s_ppv",e)}}};

            /* Adobe Consulting Plugin: p_fo (pageFirstOnly) v2.0 (Requires AppMeasurement) */
            s.p_fo = function(on){var s=this;s.__fo||(s.__fo={});if(s.__fo[on])return!1;s.__fo[on]={};return!0};
    
            /*
            * Plugin: manageVars v1.5 (requires s.pt utility if using AppMeasurement)
            */
            s.manageVars = new Function("c", "l", "f", "" + "var s=this,vl,la,vla;l=l?l:'';f=f?f:1;if(!s[c])return false;vl='pag" + "eName,purchaseID,sitesection,server,pageType,campaign,state,zip,events," + "products,transactionID';for(var n=1;n<76;n++)vl+=',prop'+n+',eVar'+" + "n;for(n=1;n<6;n++)vl+=',hier'+n;for(n=1;n<4;n++)vl+=',list'+n;for(n" + " in s.contextData)vl+=',contextData.'+n;if(l&&(f==1||f==2)){if(f==1" + ")vl=l.replace('[\\'','.').replace('\\']','');if(f==2){la=l.split('," + "');vla=vl.split(',');vl='';for(x in la){if(la[x].indexOf('contextDa" + "ta')>-1){lax=la[x].split('\\'');la[x]='contextData.'+lax[1];}for(y " + "in vla){if(la[x]==vla[y]){vla[y]='';}}}for(y in vla){vl+=vla[y]?','" + "+vla[y]:'';}}s.pt(vl,',',c,0);return true;}else if(l==''&&f==1){s.p" + "t(vl,',',c,0);return true;}else return false;");        
            s.lowercaseVars = new Function("t", "" + "var s=this;if(t!='events'&&t.indexOf('contextData')==-1&&s[t]){s[t]" + "=s[t].toString();if(s[t].indexOf('D=')!=0){s[t]=s[t].toLowerCase();" + "}}else if(t.indexOf('contextData')>-1){var c=t.substring(t.indexOf(" + "'.')+1);if(s.contextData[c]){s.contextData[c]=s.contextData[c].toSt" + "ring();s.contextData[c]=s.contextData[c].toLowerCase();}}");
    
            /* 
            * s.pt utility (runs function f against variables in x)
            */
            s.pt = new Function("x", "d", "f", "a", "" + "var s=this,t=x,z=0,y,r;while(t){y=t.indexOf(d);y=y<0?t.length:y;t=t" + ".substring(0,y);r=s[f](t,a);if(r)return r;z+=y+d.length;t=x.substri" + "ng(z,x.length);t=z<x.length?t:''}return'';");
            s.getValOnce = new Function("v", "c", "e", "" + "var s=this,k=s.c_r(c),a=new Date;e=e?e:0;if(v){a.setTime(a.getTime(" + ")+e*86400000);s.c_w(c,v,e?a:0);}return v==k?'':v");
        },
    };    

    window.CorusAdobeAnalytics = adobeAnalytics;
}());

jQuery(function(){

  var initProperties = {
    account: ce_adobe_analytics_web_js_context.settings.report_suite,
    trackingServer: 'metrics.corus.ca',
    trackingServerSecure: 'smetrics.corus.ca',
    site: ce_adobe_analytics_web_js_context.settings.brand,
  };

  if ( ce_adobe_analytics_web_js_context.settings.auth_tracking_enabled ) {
      initProperties.requiresAuthentication = true;
  } else {
    initProperties.requiresAuthentication = false;
  }

  if ( ce_adobe_analytics_web_js_context.settings.video_tracking_enabled ) {
    initProperties.video_tracking_enabled = true;
  } else {
    initProperties.video_tracking_enabled = false;
  }
    let consentFlag = getCookie('consentCheck')
    if (consentFlag !== 'declined'){
        CorusAdobeAnalytics.init( initProperties );
    }

  if ( ce_adobe_analytics_web_js_context.settings.adblock_tracking_enabled ) {
    window?.adblockDetector?.init({
        debug: false,
        complete: function(testResult) {
            //Ad-block detected?
            if (testResult) {
                s.contextData['adblock.event'] = 1;
                s.contextData['adblock.type']  = 'IAB';
            } else {
                s.contextData['adblock.event'] = 0;
                s.contextData['adblock.type']  = '';
            }
            CorusAdobeAnalytics.trackPage( ce_adobe_analytics_web_js_context.data );
        }
    });
  } else {
    CorusAdobeAnalytics.trackPage( ce_adobe_analytics_web_js_context.data );
  }

  CorusAdobeAnalytics.registerTrackableWidgets();
});