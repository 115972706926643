import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import './Video.scss';

import VideoPlayer from '../../widgets/video/VideoPlayer/VideoPlayer';
import fromVms from '../../utility/fromVms';
import HelpModal from '../../partials/HelpModal/HelpModal';
import AgeGateModal from '../../partials/AgeGateModal/AgeGateModal';
import UserContext from '../../contexts/UserContext';

import { getValueForKey } from '../../../utils/helpers';

class Video extends Component {
	static contextType = UserContext;

	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				movieId: PropTypes.string,
				videoId: PropTypes.string,
				parentId: PropTypes.string,
				ordering: PropTypes.string, // Collection ordering
				order: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.number,
				]),
			}).isRequired,
		}).isRequired,
		items: PropTypes.array,
		loading: PropTypes.bool,
		history: PropTypes.object.isRequired,
		location: PropTypes.shape({
			playlistItems: PropTypes.array, // Playlist for the collection comes from VideoTile
			parentId: PropTypes.string, // Collection GUID
			ordering: PropTypes.string, // Collection ordering
			order: PropTypes.number, // Video item order
			search: PropTypes.string, // The query string
			state: PropTypes.string,
			behaviour: PropTypes.string,
		}),
	}

	state = {
		displayHelp: false,
	}

	constructor(props) {
		super(props);
		this.playerRef = React.createRef();
	}

	componentDidMount() {
		document.body.classList.add('isFullscreen');
	}

	componentWillUnmount() {
		document.body.classList.remove('isFullscreen');
	}

	helpButtonClick = () => {
		this.playerRef.current.pause();
		this.setState({
			displayHelp: true,
		});
	}

	closeButtonClick = () => {
		this.context.update('pageRefresh', true)
	}

	closeHelpModal = () => {
		const modal = document.getElementsByClassName('HelpModal')[0];
		modal.classList.add('Closing-transition');

		setTimeout(() => {
			this.setState({
				displayHelp: false,
			});
		}, 200)
	}

	render() {
		if (this.props.loading) {
			// TODO: proper loading state
			return null;
		}

		let video;

		if (this.props.items.length < 1) {
			// Placeholder properties to allow video player to continue construction.
			video = {
				data: {},
				resources: [],
			}
		} else {
			video = this.props.items[0];
		}

		let requireAuth = false;

		if (video.data.public_window_end_date && Date.now() > Date.parse(video.data.public_window_end_date)) {
			requireAuth = true;
		}

		let ageGate = false;
		if (
			getValueForKey(document.cookie, AgeGateModal.ageGateCookieName) !== '1' &&
			(
				(video.data.ad_target_value && video.data.ad_target_value.toLowerCase() === 'cannabis') ||
				(video.data.keywords && video.data.keywords.toLowerCase().includes('cannabis'))
			)
		) {
			ageGate = true;
		}

		const showOrigin = video.origin;
		const showId = video.type === 'movie' ? video.guid : video.data.primary_parent_container_id;
		const showName = video.data.show_title;
		const parentId = this.props.match.params.parentId ? this.props.match.params.parentId : this.props.location.parentId;
		const ordering = this.props.match.params.ordering ? this.props.match.params.ordering : this.props.location.ordering;
		const order = this.props.match.params.order ? this.props.match.params.order : this.props.location.order;
		let videoPlayerAdditionProps = {
			playlistItems: this.props.location.playlistItems,
			parentId,
			ordering,
			order,
		};

		let closeLocation = showOrigin && showId && showName ? `/series/${showId}` : this.props.location.state || '/';
		closeLocation = video.type === 'movie' ? `/movie/${showId}` : closeLocation
		closeLocation = this.props.match.params.movieId ? `/movie/${this.props.match.params.movieId}/` : closeLocation

		// Get the last visited view from content set in viewFinder
		if (this.context.lastView && this.context.lastView.viewId === 'news-shared') {
			closeLocation = `/view/${this.context.lastView.guid}/`;

			if (this.context.lastView.origin) {
				closeLocation = `${closeLocation}?origin=${this.context.lastView.origin}`;
			}
		}


		const queryParams = queryString.parse(this.props.location.search);
		return (
			<div className="Video">
				{ageGate ? <AgeGateModal /> : null}
				{this.state.displayHelp ? <HelpModal closeHelpModal={this.closeHelpModal} /> : null}
				<VideoPlayer
					videoId={this.props.match.params.videoId}
					fullscreenMode={true}
					ageGate={ageGate}
					requireAuth={requireAuth}
					{...video}
					{...videoPlayerAdditionProps} // this should come after {...video}
					ref={this.playerRef}
					action={queryParams.action || (queryParams.shuffle === 'true' ? 'true' : null)}
					resumeTime={queryParams.resumeTime}
					isShuffle={queryParams.shuffle}
					behaviour={this.props.location.behaviour}
				>
					<div className="Video-overlay">
						<div className="Video-overlay-helpButton" onClick={this.helpButtonClick}>?</div>
						<Link to={closeLocation} className="Video-overlay-backButton" onClick={this.closeButtonClick} >&times;</Link>
					</div>
				</VideoPlayer>
			</div>
		)
	}
}

const propMapping = (props) => {
	return {
		guid: props.match.params.videoId,
	}
}

export default fromVms(Video, propMapping);
