import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ErrorBoundary.scss';

class ErrorBoundary extends Component {
	static propTypes = {
		children: PropTypes.any,
		errorComponent: PropTypes.elementType,
	}

	state = {
		hasError: false,
	}

	componentDidCatch(/* error, info */) {
		// Display fallback UI
		this.setState({ hasError: true });
		// TODO: log to error reporting service
	}

	render() {
		if (this.state.hasError) {
			if (this.props.errorComponent) {
				const ErrorComponent = this.props.errorComponent;
				return <ErrorComponent />;
			} else {
				return (
					<div className="ErrorBoundary">
						<span className="ErrorBoundary-text">⚠ Something went wrong.</span>
					</div>
				);
			}
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
