import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import withView from '../../utility/withView';
import { addingPermutiveValues } from '../../../utils/permutiveHelper';
import ChannelMetaData from './ChannelMetaData';
import moment from 'moment'
import PropTypes from 'prop-types'
import { queryVms } from '../../../utils/vmsQuery';
import fromVms from '../../utility/fromVms';
import UserContext from '../../contexts/UserContext';
import { configbyAdobeID as brands } from '../../../utils/helpers';
import _ from 'lodash';
import DownArrow from './lib/DownArrow.svg'
import styled from 'styled-components';
import './ScheduleTv.scss';
import ScheduleFavorites from './ScheduleFavourites';
import '@rmwc/select/styles';
import ChannelLogo from './ChannelLogo';
import { addHours, getColSpan, numberToDate, progressSetter } from './helper'
import { LinearProgress } from '@rmwc/linear-progress';
import '@rmwc/linear-progress/styles';
const StyledLinearProgress = styled(LinearProgress)`

& .mdc-linear-progress__bar-inner{
	border-color:red;
}

& .mdc-linear-progress__buffer{
	background-color: black;
}
`
const StyledSelect = styled.select`
    font-family: inherit;
	cursor: pointer !important;
	width: 90%;
	min-height:51px;        // for safari browser compatability
	color:#FFF !Important;
    font-size: 16px;
	line-height: 16px;
	background: url(${DownArrow}) no-repeat right !important;
	padding-left: 16px;
	-webkit-appearance: none;
	border: 1px solid black;

	&:focus {
		outline: none;
	}
`
const StyledSection = styled.section`
	font-size: 16px;
	padding: 0 var(--body-margin);

	& .mdc-data-table__header-cell{
		font-family: inherit;
		line-height: 16px;
		font-size: 16px;
	}

	& .mdc-data-table__cell{
		font-family: proxima-nova, sans-serif !important;
		height: 66px;
		font-size: 16px;
	}

	& .VideoTileArea  {
		padding: 2px !important;
		overflow:visible
	}

	& .mdc-select__anchor {
		font-weight: bold;

	}

	& .slider-icon-left{
		position: absolute;
		top: 18px;
		left: 160px;
		width: 38px;
		height: 20px;
		z-index: 5;
		cursor:pointer;
		padding:0;
		background:black;

		& i {
			transform: rotate(90deg);	
			right:3px;	
			cursor:pointer;	
			bottom:0;
			padding-left:16px;
		}
	}
	& .slider-icon-right{
		position: absolute;
		top: 18px;
		right: 0px;
		z-index: 5;
		cursor:pointer;
		padding:0;
		width:40px;
		height:20px;
		background:black;

		& i {
			transform: rotate(-90deg);
			left:0;
			cursor:pointer;	
			padding:0px;
			bottom:0px	
		}
	}

	& .mdc-select__dropdown-icon{
		background: url(${DownArrow}) no-repeat center !important;
		width: 30px;
		padding-left: 16px;
	}
`

const ScheduleTv = (props) => {

	const { authenticated_origins, signedIn, selectedBDU } = React.useContext(UserContext);
	const [showChannel, setShowChannel] = useState(false)
	const [scheduleData, setScheduleData] = useState([]);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [startTime, setStartTime] = useState(addHours(moment(), 24)?.start);
	const [endTime, setEndTime] = useState(addHours(moment(), 23)?.end);
	const [timeNow, setTimenow] = useState(moment());
	const [isFav, setIsFav] = useState(true);
	const [isSelected, setSelected] = useState('');
	let [checkPermitive, setCheckPermitive] = React.useState(false);  // Stop multiple rendering event
	const tableRef = useRef(null);
	const datatableRef = useRef(null);
	const staticHeader = useRef(null);
	const dataTableRow = useRef(null);
	const dataTableHead = useRef(null);
	const { items } = props;
	const channels = items.filter(item => item.type === 'channel');
	const TIME_INTERVAL = 30;
	const DAYS_COUNT = 6;
	const minutes = [0, TIME_INTERVAL]
	let topSchedule;
	const formatcolumnHeadTime = (date) => [
		date.getHours().toString().padStart(2, '0'),
		date.getMinutes().toString().padStart(2, '0')
	].join(':')

	let currentPage = localStorage.getItem('currentPage');

	if (!currentPage) {
		localStorage.setItem('currentPage', 'Live TV');
		currentPage = localStorage.getItem('currentPage');
	}

	const loginState = signedIn && authenticated_origins?.length > 0;

	if (!checkPermitive) {
		setCheckPermitive(!checkPermitive)
		addingPermutiveValues({
			content: {
				type: currentPage
			},
			user: {
				auth: {
					status: loginState ? 'authenticated' : 'non-authenticated',
					...(loginState && { bdu: selectedBDU })
				}
			}
		})
	}
	useEffect(() => {
		const interval = setInterval(() => {
			const dif = timeNow.diff(startTime)
			const remaining = moment.duration(dif)
			if (remaining.minutes() >= 29) {		// resets the time elapsed progress bar after every 30 mins
				setStartTime(addHours(moment(), 24)?.start)
			}
			setTimenow(moment())
		}, 60000);
		const getSchedule = async () => {
			if (channels.length > 0) {
				const scheduleValues = await makeScheduleAPIRequest(channels.map(v => v.guid))

				let schedule = {};
				Promise.all(scheduleValues).then((results) => {
					if (results.length > 0) {
						results.forEach((channelItem) => {
							if (channelItem.length > 0) {
								if (!schedule[channelItem[0].parent]) {
									schedule[channelItem[0].parent] = channelItem;
								}
							}
						})
						setScheduleData(schedule)
					}
				});
			}
		}
		getSchedule()
		return () => {
			clearInterval(interval);
			document.removeEventListener('scroll', scrollFunction)
		}
	}, [items, startTime, timeNow, showChannel])

	const makeScheduleAPIRequest = async (channelIds) => {
		return channelIds.map(async (id) => {
			const { results } = await queryVms({
				parent: id,
				end_time_code__gt: startTime.unix(),
				ordering: 'start_time_code',
				start_time_code__lt: endTime.unix(),
				status: 'published',
				limit: 100,
				type: 'schedule'
			});
			return results
		})
	}
	function scrollFunction() {
		topSchedule = tableRef?.current?.getBoundingClientRect()?.top;
		let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
		const selectHead = document.querySelector('.datatable_select_head');
		const tableHead = document.querySelector('.datatable_head');
		if (!selectHead || !tableHead) return false;

		else if (topSchedule <= 0) {
			if (isSafari) {
				document.querySelector('.datatable_head').style.overflow = 'hidden';
			} else document.querySelector('.datatable_head').style.overflow = 'auto';

			dataTableHead.current.scrollTo(scrollPosition, 0)
			document.querySelector('.datatable_select_head').style.position = 'fixed'
			document.querySelector('.datatable_select_head').style.top = '0';

			document.querySelector('.datatable_head').style.position = 'fixed'
			document.querySelector('.datatable_head').style.top = '0';
			document.querySelector('.datatable_head').style.width = `${tableRef.current?.getBoundingClientRect()?.width - 160}px`;


			if (document.querySelector('.slider-icon-right')) {
				document.querySelector('.slider-icon-right').style.position = 'fixed';
				document.querySelector('.slider-icon-right').style.right = 'var(--body-margin)';
			}
		} else {
			dataTableHead?.current?.scrollTo(-scrollPosition, 0)
			document.querySelector('.datatable_select_head').style.position = 'sticky'

			document.querySelector('.datatable_head').style.position = 'sticky'
			document.querySelector('.datatable_head').style.overflow = 'hidden';
			document.querySelector('.datatable_head').style.width = '10080px';

			if (document.querySelector('.slider-icon-right')) {
				document.querySelector('.slider-icon-right').style.position = 'absolute';
				document.querySelector('.slider-icon-right').style.right = '0px';
			}

			if (document.querySelector('.slider-icon-left')) {
				document.querySelector('.slider-icon-left').style.marginLeft = '0px';
				document.querySelector('.slider-icon-left').style.left = '160px';
				document.querySelector('.slider-icon-left').style.position = 'absolute';
			}
		}
	}
	document.addEventListener('scroll', scrollFunction);        // scroll function when time indicator bar to fix to top

	const dateOptions = (daysCount) => {
		var options = [];
		let i = 0;
		var start = moment().startOf('d');
		var end = moment().add(daysCount, 'd').endOf('d');
		while ((start.diff(end, 'd', true) <= 0) && i < daysCount) {
			var data = moment().format('DD') === start.format('DD') ? `Today  ${start.format('MMM DD')} ` : start.format('ddd  MMM DD')
			options.push(data)
			start.add(1, 'd')
			i++;
		}
		return options
	}

	const onChangeDate = (evt) => {
		const timeValue = moment(evt.currentTarget.value, 'ddd MMM DD').valueOf() || moment().valueOf();
		const { start, end } = addHours(parseInt(timeValue), 24)
		setShowChannel(null);
		setStartTime(start)
		setEndTime(end)
		setScrollPosition(0)
		datatableRef.current.scrollTo(0, 0)
	}

	const timeIndicator = (time, minutes) => {
		const date = numberToDate(time);
		if (minutes.includes(date.getMinutes())) {
			return formatcolumnHeadTime(date)
		}
	};

	const authCheck = (tile) => {
		return (authenticated_origins?.find(auth => { return (tile.origin === brands(auth).origin) }))
	}


	const channelMetadata = (event, tile) => {
		if (showChannel) {
			setShowChannel(false)
		}
		event.preventDefault()
		const currentBottom = window.innerHeight - event.currentTarget.getBoundingClientRect()?.bottom;
		if (!showChannel && currentBottom < 400) {
			window.scrollTo(0, window.scrollY + 420)
		}
		if (!authCheck(tile) && (tile.origin !== 'globalnewsott')) return null;
		event.stopPropagation()
		setShowChannel(tile);
	}

	const variable = endTime.valueOf() - startTime.valueOf();
	const columnDivider = useCallback((startTime, endTime) => {
		const cells = [];
		const colSpan = getColSpan(startTime.valueOf(), endTime.valueOf());  // get the total number of minutes in the given time interval
		for (let i = 0; i < colSpan; i++) {
			const time = ((endTime - startTime) * (i / colSpan)) + startTime;
			if (timeIndicator(time, minutes)) {
				cells.push(timeIndicator(time, minutes))
			}
		}
		return cells
	}, [variable])
	const columnValues = useMemo(() => columnDivider(startTime, endTime), [variable])
	const columnNewsCount = columnValues.slice(0, 24)

	const favCheck = (value) => {
		setIsFav(value);
	}

	const prevScroll = () => {
		const previousSet = scrollPosition - 726;
		if (scrollPosition > 0) {
			setScrollPosition(previousSet)
			datatableRef.current.scrollTo(previousSet, 0)
		}
	}

	const nextScroll = () => {
		const exactRow = dataTableRow?.current?.scrollWidth - staticHeader.current.clientWidth;
		const nextSet = scrollPosition + 726;
		if (exactRow > nextSet) {
			setScrollPosition(nextSet)
			datatableRef.current.scrollTo(nextSet, 0)
		}
	}

	const handleOnClickClose = () => {
		setShowChannel(false);
	}

	const scrollTablePosition = (event) => {
		event.preventDefault()
		topSchedule = tableRef?.current?.getBoundingClientRect()?.top;
		if (topSchedule <= 0) {
			dataTableHead.current.scrollTo(event.currentTarget.scrollLeft, 0)
			if (document.querySelector('.slider-icon-left')) {
				document.querySelector('.slider-icon-left').style.position = 'fixed';
				document.querySelector('.slider-icon-left').style.left = 'var(--body-margin)';
				document.querySelector('.slider-icon-left').style.marginLeft = '160px';
			}
			if (document.querySelector('.slider-icon-right')) {
				document.querySelector('.slider-icon-right').style.position = 'fixed';
				document.querySelector('.slider-icon-right').style.right = 'var(--body-margin)';
			}
		}
		setScrollPosition(event.currentTarget.scrollLeft)
	}
	const dateSelectOptions = dateOptions(DAYS_COUNT);
	const setDuration = (tile, i) => {
		if (i === 0)      // to check if starttime of a show is expired from the current time period.
			return (tile.end_time_code - startTime.unix()) / 60
		if (tile.end_time_code > endTime.unix()) {      // to check if endtime of a show is past the desire time period.
			let ROUNDING = 30 * 60 * 1000;
			let tile_endtime = moment(Math.floor((moment(endTime)) / ROUNDING) * ROUNDING).unix()
			return (tile_endtime - tile.start_time_code) / 60
		}
		return (tile.end_time_code - tile.start_time_code) / 60
	}
	let selected;
	return (
		<StyledSection>
			<div style={{ minHeight: `${(isFav || !_.isEmpty(showChannel)) ? 36 : 0}px` }} >
				{isFav ? (<ScheduleFavorites key='channelfavorites' channels={channels} schedule={scheduleData} />) : null}
			</div>
			<section className='datatable-container' ref={tableRef} style={{ position: 'relative', width: '100%', height: '100%' }}>
				{scrollPosition ? (<div className='slider-icon-left' onClick={(event) => prevScroll(event)}><i className="mdc-select__dropdown-icon"></i></div>) : null}
				{(scheduleData) &&
					<container
						className='datatable'
						style={{ display: 'flex', background: 'black' }}
					>
						<section>
							<div ref={staticHeader} className='datatable_select_head' style={{ position: 'sticky', width: '160px', background: 'black', zIndex: '3' }}>
								<StyledSelect defaultValue={`${dateSelectOptions[0]}`} onChange={(evt) => onChangeDate(evt)}>
									{dateSelectOptions.map((date, i) => { return <option style={{ background: 'black', color: '#FFF' }} key={i}>{date}</option> })}
								</StyledSelect></div>
							{channels.map((v, i) => (
								<>
									<div className='channel_column' style={{ position: 'sticky', left: '0', height: '66px' }} key={i}>
										<ChannelLogo origin={v.origin} guid={v.guid} type={v.type} channel={v} label={v.label} favCheck={favCheck} />
									</div>
									{showChannel && (isSelected.channel === v.guid) && (
										<div style={{ display: 'flex', height: '400px' }}></div>
									)}
								</>
							))}
						</section>
						<section className='shows_body' onScroll={(event) => scrollTablePosition(event)} ref={datatableRef} >
							<div className='datatable_head' ref={dataTableHead} style={{ width: `${(dataTableRow?.current?.scrollWidth)}px` }}>
								<div style={{ position: 'relative', display: 'flex', background: 'black', overflow: 'initial' }}>
									{columnValues?.map((v, i) => (
										<>
											<div style={{ minWidth: '210px' }} className={i < 30 ? 'datatable_time_indicator' : 'datatable_time_indicator'} key={i}  >
												{/* for Time indicator */}
												{v ? moment(v, 'HH:mm').format('h:mm A') : null}
												{/* for Time progess bar indicator */}
												{(moment().format('DD') === startTime?.format('DD')) && i === 0 &&
													<div className='time_indicator_bar'>
														<StyledLinearProgress progress={progressSetter(startTime, timeNow)} buffer={1} />
													</div>}
											</div >

										</>
									))}
								</div>
							</div>
							<div style={{ width: `${(dataTableRow?.current?.scrollWidth)}px` }}>
								{channels.map((v, i) => (
									<>
										<div ref={dataTableRow} style={{ position: 'relative', display: 'flex', background: 'black', height: '66px', overflow: 'hidden' }} key={i}>
											{scheduleData[v.guid] ? scheduleData[v.guid].map((tile, index) => {
												selected = isSelected.tile === tile.guid;
												let duration;
												index === 0 ? duration = setDuration(tile, 0) : duration = setDuration(tile, 1);
												return (
													<div className={(authCheck(tile) ? (selected ? 'schedule__data-selected' : 'schedule__data') : 'schedule__data__inactive')}
														onClick={(event) => { channelMetadata(event, tile, 'channel'); setSelected({ tile: tile.guid, channel: v.guid }) }}
														key={index} style={{ minWidth: `${duration * 7}px` }} >{tile.label === 'Movie' ? tile.data.episode_title : tile.label}
													</div>)
											}) : (columnNewsCount.map((data, i) => {
												selected = isSelected.tile === `${v.guid}${i}`;
												return (
													<div key={i} className={selected ? 'schedule__data-selected' : 'schedule__data'}
														onClick={(event) => { channelMetadata(event, v, 'news'); setSelected({ tile: `${v.guid}${i}`, channel: `${v.guid}` }) }}
														style={{ minWidth: '420px' }}>{`Global News - ${v.label}`}
													</div>)
											}))}
										</div>
										{showChannel && (isSelected.channel === v.guid) && (<><div style={{ display: 'flex', position: 'absolute', overflow: 'hidden', width: '100%', height: '400px', left: 0, right: 0, zIndex: '2', background: 'black' }} className='show_metadata_row'>
											<div className='show_metadata_cell' >
												<div className='show__metadata'>
													<ChannelMetaData tile={showChannel} videoId={showChannel.parent} closing={!!showChannel} closeShowMetadata={() => handleOnClickClose()} />
												</div>
											</div>
										</div><div style={{ display: 'flex', height: '400px' }}></div></>)}
									</>
								))}

							</div>
						</section>
					</container>
				}
				{((scrollPosition >= 0) && !(dataTableRow?.current?.scrollWidth - scrollPosition < 1290)) ? <div className='slider-icon-right' onClick={(event) => nextScroll(event)}><i className=" mdc-select__dropdown-icon"></i></div> : null}
			</section>
		</StyledSection>
	);
}
const propMapping = ({ view }) => {
	return {
		parent: view.guid,
		limit: 100,
		ordering: 'order',
		type: 'channel'
	}
}
ScheduleTv.propTypes = {
	items: PropTypes.array
}


export default withView(fromVms(ScheduleTv, propMapping), 'schedule-shared');