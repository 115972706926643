import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { optimizeImage } from '../../../../utils/helpers';

class HeroImage extends Component {
	static propTypes = {
		altTag: PropTypes.string,
		heroImageURL: PropTypes.string,
	};

	render() {
		const heroImage = optimizeImage(this.props.heroImageURL, '2048');
		return (
			<div className="Hero-image-wrapper">
				<img alt={this.props.altTag} className="Hero-image" src={heroImage} />
			</div>
		)
	}
}

export default HeroImage;
