import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ShowsView from '../Shows/ShowsView';
import withSearch from '../../utility/withSearch';
import { byOrigin } from '../../../brands/brands';
import NotFound from '../NotFound/NotFound';
import UserContext from '../../contexts/UserContext';
import queryString from 'query-string';


import '../Shows/Shows.scss';

const Segmented = (props) => {
	const { match } = props;
	let currentPage =  'Freeplay'   // assuming the current page to be Freeplay for segmented view
	let currentBrand = match.params.brand || 'Global';
	let userContext = useContext(UserContext)
	const query = queryString.parse(window.location.search);

	if (query.origin) {
		currentBrand = query.origin;
		if (userContext.lastView) {
			userContext.lastView.origin = query.origin;
		}
	}
	const availableBrands = Object.keys(byOrigin);
	if (!availableBrands.includes(currentBrand) && !window.location.pathname === '/segmentedview/') {
		return <NotFound />;
	}

	return (
		<div className="Shows segemented">
			<div className="Shows-title">{currentPage}</div>
			<ShowsView viewId={match.params.guid} collectionId={match.params.collectionId} selectedBrand={currentBrand} page={currentPage} />
		</div>
	)
}


Segmented.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			brand: PropTypes.string,
			collectionId: PropTypes.string,
			guid: PropTypes.string,
		}).isRequired,
	}).isRequired,
	viewId: PropTypes.string
}

export default withSearch(Segmented);
