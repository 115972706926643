import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';

class ScrollToTop extends Component {
	static propTypes = {
		component: PropTypes.any
	}

	componentDidMount() {
		smoothscroll.polyfill();
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	}

	render() {
		const { component: Component, ...rest } = this.props;
		return <Route {...rest} render={props => (<Component {...props} />)} />;
	}
}

export default withRouter(ScrollToTop);