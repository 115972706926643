import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import fromVms from '../../utility/fromVms';
import UserContext from '../../contexts/UserContext';

class NavMenu extends Component {
	static contextType = UserContext;

	static propTypes = {
		items: PropTypes.array,
		pathname: PropTypes.string,
		handleResetSearchBox: PropTypes.func,
	}
	constructor(props) {
		super(props);
	}
	state = {
		newSectionIndicator: localStorage.getItem('newSection') ?? true
	}

	/**
	 * Get the URL for the menu item
	 * @param {object} linkItem
	 */
	getTheViewURL = (linkItem) => {
		if (linkItem.data.name === 'Homepage') {
			return '/';
		}

		return `/view/${linkItem.guid}`;
	}

	/**
	 * Get the name for the Menu item
	 * @todo Try to use the name from VMS as possible
	 */
	getTheViewName = (linkItem) => {
		if (linkItem.data.name === 'Homepage') {
			return 'Home';
		}
		if (linkItem.data.name === 'Schedule View') {
			return 'Live TV';
		}
		if (linkItem.data.name === 'Shows Page') {
			return 'Shows';
		}
		if (linkItem.data.view_id === 'free-shared') {
			return 'Freeplay';
		}
		return linkItem.data.name;
	}

	getNavLink = (linkItem) => {
		return <NavLink
			to={this.getTheViewURL(linkItem)}
			exact
			key={linkItem.guid}
			className={`Header-nav-item Header-nav-item--${linkItem.data.view_id} ${linkItem.data.view_id}-${this.state.newSectionIndicator}`}
			onClick={() => {
				this.props.handleResetSearchBox(this.getTheViewName(linkItem), linkItem);
				if (linkItem.data.view_id === 'free-shared' && this.state.newSectionIndicator) {
					localStorage.setItem('newSection', false)
					this.setState({ newSectionIndicator: false })
				}
			}
			}
			isActive={
				() => {
					// loadedView userContext defined in withView and it will be known loaded view
					if (this.context.loadedView === linkItem.guid
						|| this.props.pathname === this.getTheViewURL(linkItem)
						|| this.props.pathname.indexOf('shows') > -1 && linkItem.data.name === 'Shows Page'
						|| this.props.pathname.indexOf('segmentedview') > -1 && linkItem.data.view_id === 'free-shared'
					) {
						return true;
					}
				}
			}
		>
			{this.getTheViewName(linkItem)}
		</NavLink>
	}

	render() {
		this.props.items.sort((a, b) => {
			return a.order - b.order;
		})
		if (this.props.items) {
			let navLinksHTML = [];
			let filteredItem;
			this.props.items.forEach(item => {
				// Hide the BDU view from the list
				filteredItem = (this.context.filteredView.filter(bdu => bdu === item.data.view_id))
				if (filteredItem.length > 0) {
					if (item.data['view_id'] === 'bdu') {
						localStorage.setItem('BDU_VIEW_ID', item.guid);
					} else {
						navLinksHTML.push(this.getNavLink(item));
					}
				}
			});
			return <>
				{navLinksHTML}
			</>
		}

		return <>
			<NavLink exact to="/" className="Header-nav-item Header-nav-item--home" onClick={() => this.handleResetSearchBox()}>Home</NavLink>
			<NavLink to="/shows/global/" className={'Header-nav-item Header-nav-item--shows' + (this.props.pathname.startsWith('/shows') ? ' active' : ' ')} onClick={() => this.handleResetSearchBox()}>Shows</NavLink>
			<NavLink to="/live-tv/global/" className={'Header-nav-item Header-nav-item--web_livetv_view' + (this.props.pathname.startsWith('/live-tv') ? ' active' : ' ')} onClick={() => this.handleResetSearchBox()}>Live TV</NavLink>
		</>;
	}
}

const propMapping = () => {
	return {
		type: 'view',
		limit: 10,
		parent: process.env.REACT_APP_VMS_DISTRIBUTION_ID,
		order: 'curated',
	}
}

export default withRouter(fromVms(NavMenu, propMapping));