import PropTypes from 'prop-types';
import React from 'react';

import './Dropdown.scss';

const Dropdown = (props) => {
	const { value, options, onChange, allOptionLabel } = props
	return (
		<div className="Dropdown">
			<select className="Dropdown-select" value={value} onChange={onChange}>
				{allOptionLabel && <option value="">{allOptionLabel}</option>}
				{options.map(option => (
					<option key={option.value} value={option.value}>{option.label}</option>
				))}
			</select>
		</div>
	)
}

Dropdown.propTypes = {
	allOptionLabel: PropTypes.string,
	options: PropTypes.array,
	onChange: PropTypes.func,
	value: PropTypes.string,
}
export default Dropdown;
