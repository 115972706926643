import React, { useState } from 'react'
import PropTypes from 'prop-types'
import globalLogo from '../../../assets/icons/globallogo.svg';
import './PromoPage.scss'

const DeviceSpecificQuestion = ({ data }) => {

	const [showAnswer, setShowAnswer] = useState(false)
	return (
		<div className={`Question-dialog Question-dialog--${showAnswer}`} onClick={() => { setShowAnswer(!showAnswer) }}>
			<div className='Question-section' >{data.q}</div>
			{showAnswer && <div className={`Answer-section ${data.downloadLink ? 'downloadLinkWrapper' : ''}`}>
				{data.downloadLink &&
					<div className='downloadLink'>
						<img src={globalLogo} />
						<div style={{ display: 'flex', flexDirection: 'column', gap:'10px' }}>
							<div style={{ alignSelf: 'center' }}>{`${data.badgeText}:`}</div>
							<a href={data.downloadLink} target='_blank' rel='noopener noreferrer'>
								<img src={data.imageSrc} alt={data.imageTag} className="storeLink storeLink--playStore" />
							</a>
						</div>
					</div>}
				<div className='installStepsWrapper'>
					<ol >{
						data.a.map(value => {
							return (<li key={value.key}>
								<div>{value.key}</div>
								<div>{value.text}</div>
							</li>)
						})}
					</ol>
					{data.footerText && <p>{data.footerText}</p>}
					{data.asteriskText && <p style={{ marginTop: '16px' }}>{data.asteriskText}</p>}
				</div>
			</div>}
		</div>
	)
}

DeviceSpecificQuestion.propTypes = {
	data: PropTypes.array,
}

export default DeviceSpecificQuestion;