import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LiveTVChannel from './LiveTVChannel';
import BrandBar from '../../partials/BrandBar/BrandBar';
import fromVms from '../../utility/fromVms';

import './LiveTV.scss';

class LiveTVView extends Component {
	static propTypes = {
		selectedBrand: PropTypes.string,
		items: PropTypes.array,
		channel: PropTypes.object,
		viewId: PropTypes.string,
		channelId: PropTypes.string, // Queried channel id
		collectionId: PropTypes.string,
	}

	render() {

		const { selectedBrand, channel, channelId, viewId, collectionId, items }  = this.props;
		// Using origin query parameters iwth view
		const urlPrefix = viewId ? `/view/${viewId}/collection/` : '/live-tv/';
		const brandPickerCollection = this.props.items.find(item => item.data.collection_id === 'brand_picker_live' || item.data.title === 'Brand Picker');

		let guid = collectionId || null;

		if ( !guid ) {
			const brandData = items.find(item => item.data.deeplink_id === selectedBrand);
			if (brandData) {
				guid = brandData.guid;
			}
		}

		const brandBar = (brandPickerCollection) ?
			<BrandBar key={brandPickerCollection.guid} viewCollectionItems={items} selectedBrandCollectionId={collectionId} selectedBrand={selectedBrand} parentId={brandPickerCollection.guid} urlPrefix={urlPrefix}/> :
			null;

		return (
			<div className="LiveTV">
				<div className="LiveTV-title">Live TV</div>
				{brandBar}
				<LiveTVChannel channelId={channelId} parentId={guid} channel={channel}/>
			</div>
		)
	}
}

const propMapping = (props) => {

	// Get the views
	return {
		parent: props.viewId,
		limit: 15,
	}
}

export default fromVms(LiveTVView, propMapping);
