import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import UserContext from '../../../contexts/UserContext';
import { byOrigin as brands } from '../../../../brands/brands';

import {
	formatDate,
	formatDuration,
	formatSeasonEpisode,
	getRemainingDays, makingTrackingCall,
	optimizeImage,
	setCookie,
	truncateWithEllipsis,
	validateAuthorization
} from '../../../../utils/helpers';
import './VideoTile.scss';
import { queryVms } from '../../../../utils/vmsQuery';

class VideoTile extends Component {
	static contextType = UserContext;

	videoTileLoaderTimeout = null;

	constructor(props) {
		super(props);
		this.tileRef = React.createRef();
	}

	static propTypes = {
		data: PropTypes.object,
		delay: PropTypes.number,
		descriptionCharactersLimit: PropTypes.number,
		expanded: PropTypes.bool,
		guid: PropTypes.string,
		label: PropTypes.string,
		loading: PropTypes.string,
		origin: PropTypes.string,
		reference_date: PropTypes.string,
		expiration_date: PropTypes.string,
		resources: PropTypes.array,
		thumbnail: PropTypes.string,
		type: PropTypes.string,
		items: PropTypes.array, // All items from the collection
		parentId: PropTypes.string,
		ordering: PropTypes.string,
		order: PropTypes.number,
		tilePosition: PropTypes.number,
		title: PropTypes.string,
		showIsNew: PropTypes.bool,
		onNow: PropTypes.object,
		behaviour: PropTypes.string,
	};

	state = {
		descriptionCharactersLimit: window.innerWidth <= 1550 ? 70 : 92,
		height: '40',
		hideLoader: false,
		errorShowThumbnail: false,
		expandedDescription: false,
		showImage: false,
		episode_key_image: ''
	};

	static defaultProps = {
		expanded: true
	}

	async componentDidMount() {

		//  look for the 'episode_key' resource for these two titles in homepage

		if (this.props.title === 'Continue Watching' || this.props.title === 'Latest Global TV Episodes') {
			const data = await queryVms({
				guid: this.props?.data?.primary_parent_container_id
			})
			let episode_key_resource = data?.results[0]?.resources.filter(resource => resource.tag === 'episode_key')
			this.setState({ episode_key_image: episode_key_resource })
		}

		let delay = !this.props.delay ? 2000 : this.props.delay;

		this.videoTileLoaderTimeout = setTimeout(() => {
			this.setState({ hideLoader: true });
		}, delay);

		window.addEventListener('resize', this.computeDescLength, true);
	}

	componentDidUpdate(_prevProps, prevState) {
		if (prevState.expandedDescription !== this.state.expandedDescription && this.tileRef.current) {
			this.setState({ height: this.tileRef.current.clientHeight })
		}
	}

	componentWillUnmount() {
		// Clear pending subscriptions/tasks
		clearTimeout(this.videoTileLoaderTimeout)
		window.removeEventListener('resize', this.computeDescLength);
	}

	toggleExpandDescription = (event) => {
		this.setState({
			expandedDescription: !this.state.expandedDescription
		})
		event.stopPropagation();
	}

	handleShowImage = () => {
		this.setState({ showImage: true })
	}

	storeStreamInfo = streamGuid => {
		setCookie('lastNewsStream', streamGuid, 30);
	}

	computeDescLength = () => {
		this.setState({ descriptionCharactersLimit: window.innerWidth <= 1550 ? 70 : 92 })
	}

	checkAnalytics = (label) => {
		if (this.props.title) {
			const { signedIn, authenticated_origins, selectedBDU, adobeUid, isHome } = this.context;
			const loginState = signedIn && authenticated_origins?.length > 0;
			let order = localStorage.getItem('CW') && isHome && this.props.title !== 'Continue Watching' ? this.props.order + 1 : this.props.order;
			return makingTrackingCall(
				`${order}|${this.props.title}|${this.props.tilePosition}|${label}`,
				'VideoTile',
				loginState,
				selectedBDU,
				adobeUid
			);
		}
	}

	channelVideo = (label) => {
		localStorage.setItem('currentPage', 'Live TV')
		this.storeStreamInfo(this.props.guid)
		this.checkAnalytics(label);
	}

	displaySiginAuth = (label) => {
		this.context.update('displayAuth', true);
		this.checkAnalytics(label);
	}

	contentFormat = (props) => {
		return `${props.data.show_title || 'unavailable'};${props.data.season_number || 'unavailable'};${props.label || 'unavailable'};${props.data.episode_number || 'unavailable'}`;
	}

	render() {
		const itemType = this.props.type;
		const { guid, onNow, title } = this.props;

		const brand = brands(this.props.origin);
		const placeholderImage = brand.placeholderImages?.video;
		const applicableImages = this.state.episode_key_image?.length > 0 ? this.state.episode_key_image :
			this.props.resources.filter((resource) => (itemType === 'channel') ? resource.tag === 'episode_key' : resource.type === 'thumbnail_small'); // For Global news collection , check episode_key tag.
		const videoImage = (applicableImages.length && this.state.showImage && !this.state.errorShowThumbnail) ? optimizeImage(applicableImages[0].uri, '350') : placeholderImage;
		const formattedDate = formatDate(this.props.reference_date);
		const windowEndDate = this.props.data.public_window_end_date;
		const remainingDays = getRemainingDays(windowEndDate);
		const expirationDays = getRemainingDays(this.props.expiration_date, 7); // get the expiration days for 7 days
		const authorized = validateAuthorization(this.props.origin, this.context.authenticated_origins);
		const description = this.props.data.description ? this.props.data.description : '';
		const currentlyWatching = window.CorusJwplayer.LocalStorageService.Instance.items.find(item => item.mediaId === this.props.guid);
		const truncateDesc = !this.state.expandedDescription && description.length > this.state.descriptionCharactersLimit;
		let resumePosition = 0;
		let label, info, progressBar, seasonEpisodePrefix, analyticsValue;
		if (currentlyWatching && this.props.data.duration > 0) {
			const percentWatched = currentlyWatching.isCompleted ? 100 : currentlyWatching.percentage;
			resumePosition = currentlyWatching.isCompleted ? 0 : this.props.data.duration * (percentWatched / 100);
			progressBar = <div className="VideoTile-progress-container">
				<div className="VideoTile-progress-bar" style={{ width: `${percentWatched}%` }}></div>
			</div>
		}

		if (itemType === 'episode') {
			seasonEpisodePrefix = formatSeasonEpisode(this.props.data.season_number, this.props.data.episode_number);
			label = this.props.data.show_title;
			info = [`${seasonEpisodePrefix ? seasonEpisodePrefix + ': ' : ''}${this.props.label}`];
		} else {
			label = this.props.label;
			info = [
				formattedDate
			];
		}

		/*For videos page_name | collection_position | collection_title | position_in_collection | showTitle + ";" + seasonNumber + ";" + video_title + ";" + episodeNumber*/
		analyticsValue = this.contentFormat(this.props);

		let wrapperType = 'link';
		if (windowEndDate) {
			if (remainingDays === undefined) {
				if (this.context.signedIn && !this.context.displayAuth) {
					if (!authorized) {
						wrapperType = 'anchor';
					}
				} else {
					wrapperType = 'auth';
				}
			}
		}

		const conditionalCTA = <div className="VideoTile-overlay">
			<span className="VideoTile-sign-in-cta">{remainingDays !== undefined ? `Available for ${remainingDays}` : 'Sign In To Watch'}</span>
		</div>

		/**
		 * Set the expiration days HTML
		 */
		let expirationDaysHTML;
		if (!remainingDays && expirationDays) {
			expirationDaysHTML = <div className="VideoTile-overlay">
				<span className="VideoTile-sign-in-cta">{`Expires in ${expirationDays}`}</span>
			</div>
		}

		const playableVideoTile = <div className="VideoTile-imageOverlay">
			<span className="VideoTile-playIcon" />
			{/* Hide video duration for live tv channels */}
			{itemType !== 'channel' && <span className="VideoTile-duration">{formatDuration(this.props.data.duration)}</span>}
		</div>

		const subscribeCTA = <div className="VideoTile-overlay">
			<span className="VideoTile-sign-in-cta">Subscribe To Watch</span>
		</div>

		const videoTileImageMarkUp =
			<div className={'VideoTile-imageArea' + ((this.state.hideLoader === false || this.props.loading) ? ' VideoTile-imageArea-loader' : ' ')}>
				<img className="VideoTile-image" src={videoImage} alt={this.props.label} onLoad={() => this.handleShowImage()} onError={() => { this.setState({ errorShowThumbnail: true }) }} />

				{
					windowEndDate ? (
						this.context.signedIn ? (
							authorized ? (
								expirationDaysHTML ? expirationDaysHTML : playableVideoTile
							) : (
								remainingDays ? (
									conditionalCTA
								) : (
									subscribeCTA
								)
							)
						) : (
							conditionalCTA
						)
					) : (
						expirationDaysHTML ? expirationDaysHTML : playableVideoTile
					)
				}
			</div>

		const videoTileLabelandInfoMarkup =
			<>
				{progressBar}
				<div className={title === 'Favourite Channels' ? 'VideoTile-schedule-container' : 'VideoTile-info-container'}>
					{this.props.showIsNew ? <span className={'VideoTile-newIndicator'}>NEW</span> : null}
					<span className={'VideoTile-label' + ((this.state.hideLoader === false || this.props.loading) ? ' VideoTile-label-loader' : ' ')}>{label}</span>
					{onNow && <span className={'VideoTile-description' + ((this.state.hideLoader === false || this.props.loading) ? ' VideoTile-label-loader' : ' ')}>{`On Now: ${onNow[guid]?.show}`}</span>}

				</div>
				{/* {title === 'Favourite Channels' && onNow[guid].episode &&
					<div className={'VideoTile-info-loader--' + ((this.state.hideLoader === false || this.props.loading) ? true : false)} >
						<span className="VideoTile-info">{onNow[guid]?.episode}</span>
					</div>
				} */}

				{itemType !== 'channel' && (
					info.map((item, index) => (
						<div className={'VideoTile-info-loader--' + ((this.state.hideLoader === false || this.props.loading) ? true : false)} key={index}>
							<span className="VideoTile-info">{item}</span>
						</div>
					))
				)}
			</>

		const videoTileDetails = (
			<>
				{videoTileImageMarkUp}
				{videoTileLabelandInfoMarkup}
			</>
		)

		// We don't need to add unnecessary zero in the url
		resumePosition = (resumePosition) ? `&&resumeTime=${resumePosition}` : '';
		const pathPrefix = this.props.type === 'episode' && this.props.data.primary_parent_container_id ?
			`/series/${this.props.data.primary_parent_container_id}/episode/${this.props.guid}/?action=play${resumePosition}`
			: (this.props.type === 'channel' ? `/channel/${this.props.guid}/?action=play` : `/video/${this.props.guid}/${this.props.parentId}/?action=play${resumePosition}`);
		const videoTileMarkup =
			<div className={'VideoTile' + (this.props.expanded ? ' VideoTile--expanded' : '')} >
				{wrapperType === 'link' ? (
					itemType === 'channel' ?
						<Link
							draggable="false"
							onClick={() => this.channelVideo(analyticsValue)}
							// trigger the videoplayer directly from the schedule page
							to={{
								pathname: pathPrefix,
								playlistItems: this.props.items, // All queried items from the collection
								parentId: this.props.parentId,
								order: this.props.order,
								ordering: this.props.ordering,
								state: window.location.pathname
							}}
						>
							{videoTileDetails}
						</Link> :
						<Link
							onClick={() => this.checkAnalytics(analyticsValue)}
							to={{
								pathname: pathPrefix,
								playlistItems: this.props.items, // All queried items from the collection
								parentId: this.props.parentId,
								order: this.props.order,
								ordering: this.props.ordering,
								behaviour: this.props.behaviour
							}}
							draggable="false"
						>
							{videoTileDetails}
						</Link>
				) : wrapperType === 'anchor' ? (
					<a
						href="https://www.globaltv.com/channel-finder/"
						className="Video-tile-wrap"
						target="_blank"
						rel="noopener noreferrer"
						draggable="false"
					>
						{videoTileImageMarkUp}
						{videoTileLabelandInfoMarkup}
					</a>
				) : wrapperType === 'auth' ? (
					<div onClick={() => this.displaySiginAuth(analyticsValue)} className="Video-tile-wrap">
						{videoTileImageMarkUp}
						{videoTileLabelandInfoMarkup}
					</div>
				) : (
					<>
						{videoTileImageMarkUp}
						{videoTileLabelandInfoMarkup}
					</>
				)}
				{this.props.expanded && (
					<>
						<div className={'descType--' + (truncateDesc ? 'short' : 'long')} style={{ height: `${this.state.height}px` }}>
							<p className={'VideoTile-description isLoader--' + ((this.state.hideLoader === false || this.props.loading) ? true : false)} aria-label={this.props.data.description} ref={this.tileRef}>
								{truncateDesc ? truncateWithEllipsis(description, this.state.descriptionCharactersLimit) : this.props.data.description}
							</p>
						</div>
						{
							description.length > this.state.descriptionCharactersLimit ? (
								<p className={'VideoTile-ReadMore--' + (this.state.expandedDescription ? 'less' : 'more') + ' isLoader--' + ((this.state.hideLoader === false || this.props.loading) ? true : false)} onClick={this.toggleExpandDescription}>
									{this.state.expandedDescription ? 'Less' : 'More'}
								</p>
							) : null
						}
						{itemType === 'episode' && (
							<span className={'VideoTile-date isLoader--' + ((this.state.hideLoader === false || this.props.loading) ? true : false)}>{formattedDate}</span>
						)}
					</>
				)}
			</div>

		return videoTileMarkup
	}
}

export default VideoTile;
