import React, { Component } from 'react';
import Search from '../partials/Search/Search';
import UserContext from '../contexts/UserContext';

const withSearch = OriginalComponent => {
	return class EnhancedComponent extends Component {
		static contextType = UserContext;

		render() {
			if (this.context.searchQuery) {
				return <Search />
			}

			return <OriginalComponent {...this.props} />
		}
	}
}

export default withSearch;