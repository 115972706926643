import React, { useState, useEffect, useContext } from 'react';
import { queryVms } from '../../../utils/vmsQuery';
import UserContext from '../../contexts/UserContext';
import './AllBrands.scss';

const AllBrands = () => {
	const [brands, setBrands] = useState([])
	const { configData } = useContext(UserContext);
	useEffect(() => {
		async function fetchData() {
			const { results: data } = await queryVms({
				parent: '08adfc58-dfc5-11e9-8a94-1694138bc103',
				limit: 20,
				type: ['origin', 'collection','document'],
				ordering: 'order'
			})
			if (configData) {
				const guid = configData.origins?.globalnewsott?.guid
				const { results } = await queryVms({
					guid: guid,
				})
				const globalnews = results[0]
				data.splice(2, 0, globalnews);  // hardcode the global news logo after global logo 
			}
			setBrands(data)
		}
		fetchData();
	}, [configData])
	return (
		<div className='Brand-wrapper'>
			{(brands.length && configData) ?
				brands.map((brand) => {
					const imageSrc = brand.resources.find(item => (item.tag === 'brand_row' || item.type === 'thumbnail'))?.uri;
					const brandOrigin = brand.type === 'collection' ? brand.data.deeplink_id : brand.data.origin_id;
					return (<img className={`Brand-image BrandBar-${brandOrigin}`} src={imageSrc} alt={brand.label} key={brand.label} />)
				})
				: null}
		</div>
	);
}

export default AllBrands;