import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { queryVms } from '../../utils/vmsQuery';
import UserContext from '../contexts/UserContext';
let cachedViews = null;

/**
 * withView HOC Component to fetch a VMS view using Stream Web's distribution ID
 * @param {React Component} OriginalComponent
 * @param {String} viewId
 */

const withView = (OriginalComponent, viewId) => {
	return class EnhancedComponent extends Component {
		static contextType = UserContext;

		static propTypes = {
			viewId: PropTypes.string,
		}
		constructor(props) {
			super(props);

			this.state = {
				errorState: false,
				viewsLoaded: cachedViews ? true : false
			}
		}

		componentDidMount() {
			if (!this.state.viewsLoaded) {
				this.fetchFeaturedViews();
			}
		}

		/**
		 *  Fetches all VMS views and caches them in cachedViews.
		 */
		fetchFeaturedViews = async () => {
			try {
				const viewData = await queryVms({
					type: 'view',
					limit: 10,
					parent: process.env.REACT_APP_VMS_DISTRIBUTION_ID
				});
				// Map and cache view GUIDs.
				cachedViews = {};
				viewData.results.forEach(view => {
					cachedViews[view.data.view_id] = view.guid;
				});
				this.setState({ viewsLoaded: true })
			} catch (e) {

				this.setState({ errorState: 'Failed to fetch views' });
			}
		}

		render() {
			if (this.state.errorState) {
				throw this.state.errorState;
			}

			const cachedViewGuid = this.state.viewsLoaded ? cachedViews[viewId] : null;
			// Store loaded view Id
			this.context.loadedView = this.props.viewId;

			// return original component with brand new local state & additional props (if any)
			return <OriginalComponent {...this.props} viewId={cachedViewGuid} />
		}
	}
}

export default withView;