import React from 'react';
import './AvailableDevices.scss';

const AvailableDevices = () => {
	const AllDevices = ['Amazon Fire TV', 'Android TV', 'Android phones & tablets', 'Apple TV', 'Chromecast', 'iPhone & iPad', 'LG Smart TV', 'Roku', 'Samsung TV']
	return (
		<div className='devices-wrapper'>
			<div className='device-heading'>Available on </div>
			{AllDevices.map((device) => {
				return (<div className='device-info' key={device}>{device}</div>)
			})}
		</div>
	);
}

export default AvailableDevices;