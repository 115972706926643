import React, { useContext } from 'react';
import UserContext from '../../contexts/UserContext';

const FaqGeneralQuestionaires = () => {
	const { signedIn, authenticated_origins, selectedBDU, adobeUid } = useContext(UserContext);
	const loginState = signedIn && authenticated_origins?.length > 0;
	const handleExitLink = (link) => {
		let params = loginState ? {
			action: 'Exit Link Action',
			'auth.bdu': selectedBDU,
			'auth.status': 'authenticated',
			'auth.account': adobeUid,
			exitlink: link

		} : {
			action: 'Exit Link Action',
			'auth.status': 'non-authenticated',
			exitlink: link
		}
		window?.CorusAdobeAnalytics?.trackLink(true, params, 'Exit Link Action')
	}

	return [
		{
			q: 'Can I watch on my computer/laptop?',
			a: (<p>Yes! You can stream with a desktop browser on a PC or Mac by visiting: <a href='https://watch.globaltv.com' target='_blank' rel='noopener noreferrer'>https://watch.globaltv.com</a></p>)
		},
		{
			q: 'Is the Global TV App free?',
			a: 'The Global TV App is free to download and offers select content at no cost to the user. Users can unlock additional networks and shows by signing in with their tv subscription. In addition, the Global TV App recently launched Freeplay – a section available across all Global TV App platforms where users can enjoy hit series – absolutely FREE.'
		},
		{
			q: 'Will the Global TV App use data from my cellular plan?',
			a: 'You have the option on mobile device settings to restrict content playback to Wi-Fi only. If you allow cellular playback, you may incur mobile data plan charges.'
		},
		{
			q: 'Are all the videos on the Global TV App the same as on your website?',
			a: (<p>Now and then, there are shows whose licensing agreements say we can only stream them on the web. But you will find all of Global&apos;s video content on the <a href='https://watch.globaltv.com' target='_blank' rel='noopener noreferrer'>Global TV App</a>.</p>)
		},
		{
			q: 'Do I have to sign in with my TV Provider whenever I open the Global TV App?',
			a: 'No. The sign-in is valid for 90 days (about three months) and will reset for another 90 days every time you open the app. So, if you sign in once and return every week to catch up on your favourite shows, you never need to sign in again.'
		},
		{
			q: 'Where else can I access the latest full seasons of Global shows without a cable subscription?',
			a: (<p>STACKTV, available through Amazon Prime Video Channels, Rogers Ignite TV or Ignite Streaming, and FuboTV. Stream hundreds of great shows from Global, live, and on-demand. For more information, <a href='https://www.stacktv.ca' target='_blank' rel='noopener noreferrer'>visit stacktv.ca</a>.</p>)

		},
		{
			q: 'What other networks and brands are included, and how do I access them?',
			a: (<p>The Global TV App currently features content from Global&nbsp;TV, Global&nbsp;News, W&nbsp;Network, The&nbsp;HISTORY® Channel, Showcase, Slice, Adult&nbsp;Swim, National&nbsp;Geographic and Lifetime. Access to each network&apos;s library is completely free with a cable subscription to the channel(s).</p>)
		},
		{
			q: 'Where can I find the latest news and video for Canada?',
			a: (<p>You can find news and video on <a  onClick={() => handleExitLink('https://globalnews.ca/')} href='https://globalnews.ca/' target='_blank' rel='noopener noreferrer'>globalnews.ca</a>, and also live stream channels on the Global TV app, under the “News” tab.</p>)
		}
	]
}

export default FaqGeneralQuestionaires;
