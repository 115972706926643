import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserContext from '../../contexts/UserContext';

import AuthInstance from '../../../utils/Auth';
import { guid } from '../../../utils/helpers';
import Spinner from '../Spinner/Spinner';

import './ActivateForm.scss';

class ActivateForm extends Component {

	constructor(props) {
		super(props);
		this.formRef = React.createRef();
		this.inputRef = React.createRef();
		this.buttonRef = React.createRef();
	}

	static propTypes = {
		signedIn: PropTypes.bool,
	}

	state = {
		activationCode: null,
		activationCodeSent: false,
		isAuthorized: false,
		isActivated: false,
		errorMessage: '',
		isActivating: false,
	}

	static contextType = UserContext;

	// These aren't used, but here for reference purposes
	statusCodes = {
		200: 'OK',
		308: 'Valid, not authenticated, not activated',
		307: 'Code invalid or not found'
	};

	componentDidMount() {
		AuthInstance.on('setToken', this.setTokenCallback);
		AuthInstance.on('tokenRequestFailed', this.tokenRequestFailedCallback);
	}

	componentWillUnmount() {
		if (AuthInstance.setTokenCallback) {
			AuthInstance.off('setToken', this.setTokenCallback);
			AuthInstance.off('tokenRequestFailed', this.tokenRequestFailedCallback);
		}
	}

	onSubmit = (event) => {
		event.preventDefault();

		// Validate form
		if ( this.formRef.current.checkValidity() === false ) {
			this.setState({
				errorMessage: 'The activation code provided is not valid. Please generate a new code from your console and try again.',
			});
			return;
		}

		// Activate code
		this.sendActivationCode(this.inputRef.current.value.toUpperCase());
	}

	onChange = () => {
		// Validate form
		if ( this.formRef.current.checkValidity() === false ) {
			this.buttonRef.current.disabled = true;
		} else {
			this.buttonRef.current.disabled = false;
		}
	}

	// Send the activation code to get authorized
	sendActivationCode(activationCode) {
		this.setState({
			isActivating: true,
			activationCode: activationCode,
		});
		const requestData = {
			activation_code: activationCode,
		};

		const rtsUrl = new URL(process.env.REACT_APP_VMS_RTS_DOMAIN  + '/authentication/activationcode/check/web');

		const request = new Request(rtsUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(requestData),
		});

		window.fetch(request).then(
			(response) => { response.json().then(
				(responseBody) => {
					if ( responseBody.status === '200' || responseBody.status === '308') {
						this.setState({ activationCodeSent: true });
						if ( this.props.signedIn === false) {
							this.context.update('displayAuth', true);  // Open auth modal
						} else {
							AuthInstance.getAuthorization();
						}
					} else {
						// Show activation error
						this.setState({
							errorMessage: 'The activation code provided is not valid. Please generate a new code from your console and try again.',
							isActivating: false,
						});
					}
				},
				(reason) => {
					this.setState({
						errorMessage: 'There was a JSON error. Please generate a new code from your console and try again.',
						isActivating: false,
					});
					console.error(reason);
				}
			) },
			(reason) => {
				this.setState({
					errorMessage: 'The activation request failed. Please generate a new code from your console and try again.',
					isActivating: false,
				});
				console.error(reason);
			}
		);
	}

	setTokenCallback = (token) => {
		// Check if this is part of an activation.  If not then don't sent auth info to vms.
		if (this.state.activationCodeSent) {
			this.setState({
				isAuthorized: true,
				isActivating: true,
			});
			this.sendActivationLogin(token);
			this.setState({ activationCodeSent: false });
		}
	}

	// Auth token failed resource authorization
	tokenRequestFailedCallback = () => {
		this.setState({
			errorMessage: 'Sorry you\'re not subscribed to this channel.',
			isActivating: false,
		})
	}

	// Send authentication token data to vms
	async sendActivationLogin(token) {

		// Split out the signature from the auth token
		const tokenArray = token.split('<signatureInfo>');

		const signatureInfo = tokenArray[1];
		const authToken = tokenArray[2];

		// Parse the auth token
		const tokenXml = new DOMParser().parseFromString(authToken, 'application/xml');

		const sessionGuid = tokenXml.getElementsByTagName('sessionGUID')[0].childNodes[0].nodeValue;
		const requesterId = tokenXml.getElementsByTagName('requestorID')[0].childNodes[0].nodeValue;
		const mvpdId = tokenXml.getElementsByTagName('mvpdId')[0].childNodes[0].nodeValue;

		await this.ensureAuthContextVarsAreSet();

		const requestData = {
			transaction_type: 'authenticate',
			authenticator_id: 'devicecode',
			application_id: process.env.REACT_APP_VMS_APP_ID,
			data: {
				activation_code: this.state.activationCode,
				provider_id: mvpdId || this.context.selectedBDU,
				provider_name: mvpdId || this.context.selectedBDU,
				session_GUID: sessionGuid || guid(),
				signature_info: signatureInfo || 'A Fabulous Signature',
				requester_id: requesterId || 'globaltv',
				issue_time: new Date().toISOString(),
				user_id: this.context.adobeUid,
				adobe_resource_ids: this.context.authorizedResources,
				ttl: '420000'
			}
		};

		const rtsUrl = new URL(process.env.REACT_APP_VMS_RTS_DOMAIN  + '/authentication/activationcode/authenticate');

		const request = new Request(rtsUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(requestData),
		});

		const authenticationError = 'There was an authentication error, please try again';

		// Send activation login
		window.fetch(request).then(
			(response) => { response.json().then(
				(responseBody) => {
					if ( responseBody.status === '200') {
						this.setState({
							isActivated: true,
							isActivating: false,
						});
					} else {
						this.setState({
							isActivating: false,
							errorMessage: authenticationError,
						});
					}
				},
				(reason) => {
					this.setState({
						isActivating: false,
						errorMessage: authenticationError,
					});
					console.error(reason);
				}
			) },
			(reason) => {
				console.log('connection error');
				this.setState({
					isActivating: false,
					errorMessage: authenticationError,
				});
				console.error(reason);
			}
		);
	}

	// Ensures that the auth token and authorized resources array are ready
	ensureAuthContextVarsAreSet = () => {
		var timeout = 30000; // 30000ms = 30s
		var start = Date.now();

		var promise = new Promise((resolve, reject) => {
			var waitForAuthContextVars = ()  => {
				if (AuthInstance && this.context.authTrackingId && this.context.authorizedResourcesChecked ) {
					resolve(this.context.authTrackingId);
				} else if (timeout && (Date.now() - start) >= timeout) {
					reject();
				} else {
					setTimeout(waitForAuthContextVars, 30); // poll at 30ms interval
				}
			}
			waitForAuthContextVars();
		});
		return promise;
	}

	render() {
		const errorMessage = this.state.errorMessage || '';

		return (
			<div className="Activate-wrapper">
				{ (this.state.isActivating || this.context.isAuthInProgress) ?
					<Spinner color="white" />
					:
					(this.state.isActivated === false) ?
						( <form className="Activate-form"
							ref={this.formRef}
							onSubmit={this.onSubmit}
							onChange={this.onChange}
							noValidate
						>
							<div className="Activate-message">
								To access more shows, movies and channels plus Live TV, enter the activation code from your device below:
							</div>
							<div className="Activate-field">
								<input
									className="form-control"
									name="name"
									ref={this.inputRef}
									type="text"
									placeholder="#########"
									pattern="^[a-zA-Z0-9]{8}$"
									maxLength="8"
									required/>
							</div>
							{errorMessage && <div className="Activate-error">{errorMessage}</div>}
							<button
								type="submit"
								className="Activate-button"
								ref={this.buttonRef}
								disabled="disabled"
							>Continue</button>
						</form> )
						:
						( <div className='Activate-success'>
							Thank you for activating.
						</div> )
				}
			</div>
		)
	}
}

export default ActivateForm;