import React from 'react';
import BrandBar from '../../partials/BrandBar/BrandBar';
import globalTV from '../../../brands/brands';
import AppStoreBadge from '../../../assets/icons/AppStoreBadges/global-appstore-badge.svg';
import PlayStoreBadge from '../../../assets/icons/AppStoreBadges/global-playstore-badge.svg';

import './AppPromo.scss';

const AppPromo = () => {
	const { globalTV: { images: { logo } } } = globalTV;

	return (
		<div className="AppPromo-wrapper">
			<img className="AppPromo-brand-logo" src={logo} alt="globaltv logo" />
			<div className="AppPromo-meta">
				<div className="AppPromo-hero-title">
					<div className="AppPromo-title">Watch your favourite shows on your phone or tablet.</div>
					<div className="AppPromo-title">Download the GlobalTV app.</div>
				</div>
				<BrandBar order={'liveTvOrder'} />
			</div>
			<div className="AppPromo-store-links-wrapper">
				<div className="Store-links-heading">Download the free app:</div>
				<div className="Store-links-wrapper">
					<a href="https://itunes.apple.com/ca/app/global-go/id404050595?mt=8">
						<img src={AppStoreBadge} alt="Global TV iOS App" className="storeLink storeLink--appStore"/>
					</a>
					<a href="https://play.google.com/store/apps/details?id=com.shawmedia.smglobal&hl=en">
						<img src={PlayStoreBadge} alt="Global TV Android App" className="storeLink storeLink--playStore"/>
					</a>
				</div>
			</div>
		</div>
	)
}

export default AppPromo;