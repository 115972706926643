import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './TileSliderDots.scss';

class TileSliderDots extends Component {
	static propTypes = {
		activeSliderClass: PropTypes.string,
		setSlide: PropTypes.func,
	}

	render() {
		return <li className={ 'TileSlider-dot ' + ( this.props.activeSliderClass ) } onClick={() => this.props.setSlide()}></li>
	}
}

export default TileSliderDots;