import React from 'react'
import AppStoreBadge from '../../../assets/icons/AppStoreBadges/global-appstore-badge.svg';
import PlayStoreBadge from '../../../assets/icons/AppStoreBadges/GooglePlay.svg';
import AmazonStoreBadge from '../../../assets/icons/AppStoreBadges/amazon-appstore-logo.png';
import LGStoreBadge from '../../../assets/icons/AppStoreBadges/lg-smart-tv-logo.png';
import RokuStoreBadge from '../../../assets/icons/AppStoreBadges/roku-tv-logo.png';
import SamsungStoreBadge from '../../../assets/icons/AppStoreBadges/samsung-smart-tv-logo.png';

export const computerQuestions = [

	{
		q: 'How do I stream Global TV on my computer? ',
		a: [
			{
				key: '1. ',
				text: 'Open a web browser on your computer.',
			},
			{
				key: '2. ',
				text: (<span>Use the web browser to visit the Global TV website  <a href='https://watch.globaltv.com' target='_blank' rel='noopener noreferrer'>https://watch.globaltv.com</a></span>)
			},
			{
				key: '3. ',
				text: (<span>Navigate to <em>Shows, Freeplay, News</em> or <em>Live TV</em> section and select the show or movie you want to stream.</span>),
			}
		],
	},
	{
		q: 'How do I use my TV subscription to access more Shows, Movies and Live TV on Global TV on my computer?',
		a: [
			{
				key: '1. ',
				text: (<span>Click <em>Sign In</em> in the top right corner of the Global TV navigation to open the Sign In screen.</span>),
			},
			{
				key: '2. ',
				text: 'Select your TV Provider from the list and click on the logo.',
			},
			{
				key: '3. ',
				text: 'Sign in with your login information.',
			}
		],
		footerText: 'When signed in, you get access to previous episodes, including full seasons of some of our most popular shows on-demand, plus live TV.*',
		asteriskText: '*Available content may vary through participating TV providers. Contact your TV provider for more details.',
	}
]

export const AppleMobileQuestions = [

	{
		q: 'How do I install the Global TV App on my iPhone/iPad?',
		a: [
			{
				key: '1. ',
				text: (<span>Open the <em>App Store</em> on your iPhone/iPad.</span>),
			},
			{
				key: '2. ',
				text: 'Search for "Global TV" and tap on the app from the search results.',
			},
			{
				key: '3. ',
				text: (<span>Install the Global TV app by tapping the <em>Install</em> button. </span>),
			},
			{
				key: '4. ',
				text: 'Launch the Global TV app from your home screen.',
			},
		],
		footerText: 'Supports all iPhones and iPads running iOS 15.0 or higher.',
		downloadLink: 'https://itunes.apple.com/ca/app/global-go/id404050595?mt=8',
		imageSrc: AppStoreBadge,
		imageTag: 'Apple Store',
		badgeText: 'View on app store'
	},
	{
		q: 'How do I use my TV subscription to access more Shows, Movies and Live TV on Global TV on my iPhone/iPad?',
		a: [
			{
				key: '1. ',
				text: 'Tap the profile icon in the top right corner of the Global TV app to access the "Profile & Settings" screen.',
			},
			{
				key: '2. ',
				text: (<span>Tap <em>Sign In</em> in the middle of the screen. </span>),
			},
			{
				key: '3. ',
				text: 'Search for your TV Provider and tap on the logo.',
			},
			{
				key: '4. ',
				text: 'Sign in with your login information.',
			}
		],
		footerText: 'When signed in, you get access to previous episodes, including full seasons of some of our most popular shows on-demand, plus live TV.* ',
		asteriskText: '*Available content may vary through participating TV providers. Contact your TV provider for more details.',
	}

]

export const AppleTVQuestions = [

	{
		q: 'How do I install the Global TV App on my Apple TV?',
		a: [
			{
				key: '1. ',
				text: (<span>Open the <em>App Store</em> on your Apple TV.</span>),
			},
			{
				key: '2. ',
				text: 'Search for "Global TV" and select the app from the search results.',
			},
			{
				key: '3. ',
				text: (<span>Install the Global TV app by selecting the <em>Get</em> or <em>Download</em> button.</span>),
			},
			{
				key: '4. ',
				text: 'Launch the Global TV app from your Apple TV\'s home screen.',
			},
		],
		footerText: 'Supports all Apple TV devices running tvOS 17.0 or higher',
		downloadLink: 'https://apps.apple.com/ca/app/global-tv/id404050595?platform=appleTV',
		imageSrc: AppStoreBadge,
		imageTag: 'Apple TV Store',
		badgeText: 'View on app store'

	},
	{
		q: ' How do I use my TV subscription to access more Shows, Movies and Live TV on Global TV on my Apple TV?',
		a: [
			{
				key: '1. ',
				text: (<span>Select <em>Settings</em> in the top right corner of the Global TV navigation to access the &quot;Sign In&quot; menu.</span>),
			},
			{
				key: '2. ',
				text: (<span>Go to a computer or mobile device browser and go to <a href='https://watch.globaltv.com/activate' target='_blank' rel='noopener noreferrer'>https://watch.globaltv.com/activate</a></span>)

			},
			{
				key: '3. ',
				text: 'Input the code shown on your Apple TV into this webpage on your computer or mobile device browser.',
			},
			{
				key: '4. ',
				text: 'Search for your TV Provider and select the logo.',
			},
			{
				key: '5. ',
				text: 'Sign in with your login information.',
			},
			{
				key: '6. ',
				text: 'Return to your Apple TV device to begin watching.',
			}
		],
		footerText: 'When signed in, you get access to previous episodes, including full seasons of some of our most popular shows on-demand, plus live TV.*',
		asteriskText: '*Available content may vary through participating TV providers. Contact your TV provider for more details.',
	}

]

export const AndroidMobileQuestions = [

	{
		q: 'How do I install the Global TV App on my Android Phone/Tablet?',
		a: [
			{
				key: '1. ',
				text: (<span>Open the <em>Google Play Store</em> on your Android Phone/Tablet</span>),
			},
			{
				key: '2. ',
				text: 'Search for "Global TV" and tap on the app from the search results.',
			},
			{
				key: '3. ',
				text: (<span>Tap <em>Install</em> to download and install the app. </span>),
			},
			{
				key: '4. ',
				text: 'Find the Global TV app on your device\'s home screen or in the app drawer, and tap on it to launch the app.',
			},
		],
		footerText: 'Supports all Android phones and tablets running Android 7.0 or higher.',
		downloadLink: 'https://play.google.com/store/apps/details?id=com.shawmedia.smglobal&hl=en',
		imageSrc: PlayStoreBadge,
		imageTag: 'Andriod Store',
		badgeText: 'View on Google Play'

	},
	{
		q: 'How do I use my TV subscription to access more Shows, Movies and Live TV on Global TV on my Android Phone/Tablet?',
		a: [
			{
				key: '1. ',
				text: 'Tap the profile icon in the top right corner of the Global TV app to access the "Profile & Settings" screen.',
			},
			{
				key: '2. ',
				text: (<span>Tap <em>Sign In</em> in the middle of the screen. </span>),
			},
			{
				key: '3. ',
				text: 'Search for your TV Provider and tap on the logo.',
			},
			{
				key: '4. ',
				text: 'Sign in with your login information.',
			}
		],
		footerText: 'When signed in, you get access to previous episodes, including full seasons of some of our most popular shows on-demand, plus live TV.* ',
		asteriskText: '*Available content may vary through participating TV providers. Contact your TV provider for more details.',
	}
]

export const AndroidTVQuestions = [

	{
		q: 'How do I install the Global TV App on my Android TV?',
		a: [
			{
				key: '1. ',
				text: (<span>Open the <em>Google Play Store</em> on your Android TV.</span>),
			},
			{
				key: '2. ',
				text: 'Search for "Global TV" and select the app from the search results.',
			},
			{
				key: '3. ',
				text: (<span>Select <em>Install</em> to install the app.</span>),
			},
			{
				key: '4. ',
				text: 'Launch the Global TV app from your Android TV\'s app list or home screen, sign into your account if prompted, and start enjoying a variety of shows and movies.',
			},
		],
		footerText: 'Supports most Android TV devices running Android 5.1 or higher',
		downloadLink: ' https://play.google.com/store/apps/details?id=com.shawmedia.smglobal&hl=en ',
		imageSrc: PlayStoreBadge,
		imageTag: 'Andriod TV Store',
		badgeText: 'View on Google Play'
	},
	{
		q: 'How do I use my TV subscription to access more Shows, Movies and Live TV on Global TV on my Android TV?',
		a: [
			{
				key: '1. ',
				text: (<span>Navigate to <em>Settings</em>  in the top right corner of the Global TV app navigation to access the activation code.</span>),
			},
			{
				key: '2. ',
				text: (<span>Visit <a href='https://watch.globaltv.com/activate' target='_blank' rel='noopener noreferrer'>https://watch.globaltv.com/activate</a> on your computer or mobile device.</span>)
			},
			{
				key: '3. ',
				text: 'Input the activation code shown on your TV.',
			},
			{
				key: '4. ',
				text: 'Select your TV Provider from the TV provider list.',
			},
			{
				key: '5. ',
				text: 'Sign in with your login information.',
			},
			{
				key: '6. ',
				text: 'Return to your Android TV device to begin watching.',
			}
		],
		footerText: 'When signed in, you get access to previous episodes, including full seasons of some of our most popular shows on-demand, plus live TV.*',
		asteriskText: '*Available content may vary through participating TV providers. Contact your TV provider for more details.',
	}
]

export const AmazonTVQuestions = [

	{
		q: 'How do I install the Global TV App on my Amazon Fire TV device?',
		a: [
			{
				key: '1. ',
				text: 'Search for "Global TV" using the main search menu on your Fire TV. You can also use the voice button on your Alexa Voice remote.',
			},
			{
				key: '2. ',
				text: 'Select the Global TV app from the search results.',
			},
			{
				key: '3. ',
				text: (<span>Select <em>Get</em> to download and install the app.</span>),
			},
			{
				key: '4. ',
				text: (<span>Select <em>Open</em> to launch the app</span>),
			},
		],
		footerText: 'Supports all Fire TV devices running FireOS 5.0 or higher',
		downloadLink: 'https://www.amazon.ca/Corus-Media-Sales-Inc-Global/dp/B07L5W3866/',
		imageSrc: AmazonStoreBadge,
		imageTag: 'Amazon Fire TV',
		badgeText: 'View on Amazon'

	},
	{
		q: 'How do I use my TV subscription to access more Shows, Movies and Live TV on Global TV on my Amazon Fire TV device?',
		a: [
			{
				key: '1. ',
				text: (<span>Navigate to <em>Settings</em>  in the top right corner of the Global TV app navigation to access the activation code.</span>),
			},
			{
				key: '2. ',
				text: (<span>Visit <a href='https://watch.globaltv.com/activate' target='_blank' rel='noopener noreferrer'>https://watch.globaltv.com/activate</a> on your computer or mobile device.</span>)

			},
			{
				key: '3. ',
				text: 'Input the activation code shown on your TV.',
			},
			{
				key: '4. ',
				text: 'Select your TV Provider from the TV provider list.',
			},
			{
				key: '5. ',
				text: 'Sign in with your login information.',
			},
			{
				key: '6. ',
				text: 'Return to your Amazon Fire TV device to begin watching',
			}
		],
		footerText: 'When signed in, you get access to previous episodes, including full seasons of some of our most popular shows on-demand, plus live TV.*',
		asteriskText: '*Available content may vary through participating TV providers. Contact your TV provider for more details.',
	}
]

export const Chromecast = [

	{
		q: 'How do I stream the Global TV App on Chromecast?',
		a: [
			{
				key: '1. ',
				text: 'Connect your Chromecast device to your TV and ensure it is set up and connected to the same Wi-Fi network as your mobile device.',
			},
			{
				key: '2. ',
				text: 'Open the Google Home app on your mobile device.',
			},
			{
				key: '3. ',
				text: (<span>Tap on the <b>+</b> icon or <em>Add</em> button in the Google Home app and select <em>Set up device</em> or <em>Set up new devices</em> to start the setup process. </span>),
			},
			{
				key: '4. ',
				text: 'Follow the on-screen instructions in the Google Home app to set up and connect your Chromecast device. Once connected, you can cast the Global TV app from your mobile device to your TV by selecting the cast icon within the app.',
			},
		],
		footerText: 'Supports all Chromecast 2nd Gen Devices or higher.',
	},
	{
		q: 'How do I use my TV subscription to access more Shows, Movies and Live TV on Global TV on  Chromecast?',
		a: [
			{
				key: '1. ',
				text: 'Connect your Chromecast device to your TV and ensure it is set up and connected to the same Wi-Fi network as your mobile device.',
			},
			{
				key: '2. ',
				text: 'Tap on the casting icon in your mobile device\'s upper-right corner.',
			},
			{
				key: '3. ',
				text: 'Follow Apple iPhone/Tablet or Android Phone/Tablet instructions typically.',
			},

		],
		footerText: 'When signed in, you get access to previous episodes, including full seasons of some of our most popular shows on-demand, plus live TV.*',
		asteriskText: '*Available content may vary through participating TV providers. Contact your TV provider for more details.',
	}
]

export const LGTVQuestions = [

	{
		q: 'How do I install the Global TV App on my LG Smart TV?',
		a: [
			{
				key: '1. ',
				text: 'Turn on your LG Smart TV and ensure it is connected to the internet.',
			},
			{
				key: '2. ',
				text: (<span>Press the <em>Home</em> button on your LG Smart TV remote to access the main menu.</span>),
			},
			{
				key: '3. ',
				text: (<span>Navigate to the LG Content Store by selecting the <em>Apps</em> or <em>Store</em> option.</span>),
			},
			{
				key: '4. ',
				text: 'Search for "Global TV" in the LG Content Store, and select the Global TV app from the search results.',
			},
			{
				key: '5. ',
				text: (<span>Select <em>Install</em> to install the app. </span>),
			},
			{
				key: '6. ',
				text: (<span>Select <em>Launch</em> to open the app or launch the app from the Apps section on your LG Smart TV&apos;s home screen.</span>),
			},
		],
		footerText: 'Supports all LG Smart TVs running WebOS 3.5 or higher.',
		downloadLink: 'https://ca.lgappstv.com/main/tvapp/detail?appId=1144424&catCode1=003&moreYn=N&cateYn=Y&orderType=2&headerName=Entertainment&appRankCode=&sellrUsrNo=&curPage=1',
		imageSrc: LGStoreBadge,
		imageTag: 'LG Smart TV',
		badgeText: 'View Content Store'


	},
	{
		q: 'How do I use my TV subscription to access more Shows, Movies and Live TV on Global TV on my LG Smart TV?',
		a: [
			{
				key: '1. ',
				text: (<span>Navigate to <em>Settings</em>  in the top right corner of the Global TV app navigation to access the activation code.</span>),
			},
			{
				key: '2. ',
				text: (<span>Visit <a href='https://watch.globaltv.com/activate' target='_blank' rel='noopener noreferrer'>https://watch.globaltv.com/activate</a> on your computer or mobile device.</span>)
			},
			{
				key: '3. ',
				text: 'Input the activation code shown on your TV.',
			},
			{
				key: '4. ',
				text: 'Select your TV Provider from the TV provider list.',
			},
			{
				key: '5. ',
				text: 'Sign in with your login information.',
			},
			{
				key: '6. ',
				text: 'Return to your LG Smart TV device to begin watching.',
			}
		],
		footerText: 'When signed in, you get access to previous episodes, including full seasons of some of our most popular shows on-demand, plus live TV.*',
		asteriskText: '*Available content may vary through participating TV providers. Contact your TV provider for more details.',
	}
]

export const RokuQuestions = [

	{
		q: 'How do I install the Global TV App on my Roku device?',
		a: [
			{
				key: '1. ',
				text: 'Turn on your Roku device and ensure it is connected to the internet.',
			},
			{
				key: '2. ',
				text: (<span>Press the <em>Home</em> button on your Roku remote.</span>),
			},
			{
				key: '3. ',
				text: (<span>Scroll up or down and select <em>Streaming Store</em> (or <em>Streaming Channels</em>). Press the <em>OK</em> button to open the Channel Store. </span>),
			},
			{
				key: '4. ',
				text: (<span>Select <em>Search</em> and Enter &quot;Global TV&quot; in the Search field.</span>),
			},
			{
				key: '5. ',
				text: (<span>Select the Global TV app from the search results and press the <em>OK</em> button.</span>),
			},
			{
				key: '6. ',
				text: (<span>Select <em>Add Channel</em> to install the channel on your Roku device.</span>),
			},
			{
				key: '7. ',
				text: 'Locate and launch the app from your Roku home screen. ',
			}
		],
		footerText: 'Supports all Roku Devices running RokuOS 5.6 or higher.',
		downloadLink: 'https://channelstore.roku.com/en-ca/details/259870/global-tv',
		imageSrc: RokuStoreBadge,
		imageTag: 'Roku TV',
		badgeText: 'View on Roku'

	},
	{
		q: 'How do I use my TV subscription to access more Shows, Movies and Live TV on Global TV on my Roku device?',
		a: [
			{
				key: '1. ',
				text: (<span>Navigate to <em>Settings</em>  in the top right corner of the Global TV app navigation to access the activation code.</span>),
			},
			{
				key: '2. ',
				text: (<span>Visit <a href='https://watch.globaltv.com/activate' target='_blank' rel='noopener noreferrer'>https://watch.globaltv.com/activate</a> on your computer or mobile device.</span>)
			},
			{
				key: '3. ',
				text: 'Input the activation code shown on your TV.',
			},
			{
				key: '4. ',
				text: 'Select your TV Provider from the TV provider list.',
			},
			{
				key: '5. ',
				text: 'Sign in with your login information.',
			},
			{
				key: '6. ',
				text: 'Return to your Roku device to begin watching.',
			}
		],
		footerText: 'When signed in, you get access to previous episodes, including full seasons of some of our most popular shows on-demand, plus live TV.*',
		asteriskText: '*Available content may vary through participating TV providers. Contact your TV provider for more details.',
	}
]

export const SamsungQuestions = [

	{
		q: 'How do I install the Global TV App on my Samsung TV? ',
		a: [
			{
				key: '1. ',
				text: 'Turn on your Samsung TV and ensure it is connected to the internet.',
			},
			{
				key: '2. ',
				text: (<span>Press the <em>Home</em> or <em>Smart Hub</em> button on your Samsung TV remote to access the main menu. </span>),
			},
			{
				key: '3. ',
				text: 'Navigate to the Samsung App Store or "Apps" section in the Smart Hub.',
			},
			{
				key: '4. ',
				text: 'Search for "Global TV" in the App Store, select the official app from the search results, and choose to install it',
			},
			{
				key: '5. ',
				text: 'Launch the app from your TV\'s app library or home screen.',
			},
		],
		footerText: 'Supports all Samsung Smart TVs running Tizen 4.0 or higher.',
		downloadLink: 'https://www.globaltv.com/articles/global-tv-app-now-available-on-samsung-smart-tvs/',
		imageSrc: SamsungStoreBadge,
		imageTag: 'Samsung Smart TV',
		badgeText: 'Learn more here'
	},
	{
		q: ' How do I use my TV subscription to access more Shows, Movies and Live TV on Global TV on my Samsung TV? ',
		a: [
			{
				key: '1. ',
				text: (<span>Navigate to <em>Settings</em>  in the top right corner of the Global TV app navigation to access the activation code.</span>),
			},
			{
				key: '2. ',
				text: (<span>Visit <a href='https://watch.globaltv.com/activate' target='_blank' rel='noopener noreferrer'>https://watch.globaltv.com/activate</a> on your computer or mobile device.</span>)

			},
			{
				key: '3. ',
				text: 'Input the activation code shown on your TV.',
			},
			{
				key: '4. ',
				text: 'Select your TV Provider from the TV provider list.',
			},
			{
				key: '5. ',
				text: 'Sign in with your login information.',
			},
			{
				key: '6. ',
				text: 'Return to your Samsung Smart TV device to begin watching.',
			}
		],
		footerText: 'When signed in, you get access to previous episodes, including full seasons of some of our most popular shows on-demand, plus live TV.*',
		asteriskText: '*Available content may vary through participating TV providers. Contact your TV provider for more details.',
	}
]