import React, { Component } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import withSearch from '../../utility/withSearch';

import Header from '../../partials/Header/Header';
import Footer from '../../partials/Footer/Footer';

import * as Views from '../';

/**
 * Loading video or show component according to the request
 * if url starts with /movie/ and without action=play query string, it will load with show component
 * if url starts with action=play query string, load video component (without header and footer)
 */
class VideoViewFinder extends Component {

	render() {
		const query = queryString.parse(window.location.search);
		if ( query && query.action ) {
			return <Views.Video {...this.props} />
		} else {
			return <>
				<Header />
				<div className="App-contentWell">
					<Views.Show {...this.props} />
				</div>
				<Footer />
			</>
		}

	}
}

export default withSearch(withRouter(VideoViewFinder));
