import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { setCookie } from '../../../utils/helpers';

import './AgeGateModal.scss';

class AgeGateModal extends Component {

	static propTypes = {
		history: PropTypes.object.isRequired,
	}

	static ageGateCookieName = 'ageGate'

	state = {
		day: '',
		month: '',
		year: '',
		province: '',
		errorMessage: '',
		remember: false,
		display: true,
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleChangeCheckbox = (event) => {
		this.setState({ remember: event.target.checked });
	}

	handleSubmit = (event) => {

		const { day, month, year } = this.state;

		const birthDate = Date.parse(year + '/' + month + '/' + day);

		if (parseInt(day) && parseInt(month) && parseInt(year) && birthDate) {
			const timeDelta = Date.now() - birthDate;
			const age = Math.abs(new Date(timeDelta).getUTCFullYear() - 1970);

			if (age < 19) {
				this.setState({ errorMessage: 'Sorry - you didn\'t meet the minimum age criteria.' });
			} else {

				if (this.state.remember) {
					setCookie(AgeGateModal.ageGateCookieName, '1', 30);
				}

				this.setState({ display: false })
			}
		} else {
			this.setState({ errorMessage: 'Date format invalid' });
		}
		event.preventDefault();
	}

	closeAgeGateModal = () => {
		const a = document.createElement('a')
		a.href = document.referrer

		if (document.referrer && a.hostname === document.domain) {
			this.props.history.goBack();
		} else {
			document.location = '/';
		}
	}

	render() {

		return  (this.state.display ?
			ReactDOM.createPortal(
				<div className="AgeGateModal-wrapper">
					<div className="AgeGateModal">
						<button onClick={() => this.closeAgeGateModal()} className="AgeGateModal-close">
							&times;
						</button>
						<div className="AgeGateModal-container">
							<div className="AgeGateModal-desc">
								<div className="AgeGateModal-desc-paragraph">This video is restricted to adults of legal age.</div>
								<div className="AgeGateModal-desc-paragraph">Please enter your birthdate to confirm.</div>
							</div>
							<form onSubmit={this.handleSubmit} className="AgeGateModal-form">
								<div className="AgeGateModal-date">
									<input name="day" id="day" className="AgeGateModal-day AgeGateModal-input AgeGateModal-date-input" type="text" min="1" max="31" maxLength="2" value={this.state.day} placeholder="DD" tabIndex="1" onChange={this.handleChange} required/>
									<input name="month" id="month" className="AgeGateModal-month AgeGateModal-input AgeGateModal-date-input" type="text" min="1" max="12" maxLength="2" value={this.state.month} placeholder="MM" tabIndex="2" onChange={this.handleChange} required/>
									<input name="year" id="year" className="AgeGateModal-year AgeGateModal-input AgeGateModal-date-input" type="text" min="1900" max="9999" maxLength="4" value={this.state.year} placeholder="YYYY" tabIndex="3" onChange={this.handleChange} required/>
								</div>
								<select name="province" id="province" className="AgeGateModal-province" tabIndex="4" onChange={this.handleChange} value={this.state.province} required>
									<option value="" disabled>Where do you live?</option>
									<option value="AB">Alberta</option>
									<option value="BC">British Columbia</option>
									<option value="MB">Manitoba</option>
									<option value="NB">New Brunswick</option>
									<option value="NL">Newfoundland and Labrador</option>
									<option value="NS">Nova Scotia</option>
									<option value="NT">Northwest Territories</option>
									<option value="NU">Nunavut</option>
									<option value="ON">Ontario</option>
									<option value="PE">Prince Edward Island</option>
									<option value="QC">Quebec</option>
									<option value="SK">Saskatchewan</option>
									<option value="YT">Yukon</option>
								</select>
								<p className="AgeGateModal-error">{ this.state.errorMessage }</p>
								<div className="AgeGateModal-remember">
									<input type="checkbox" id="remember" name="remember" className="AgeGateModal-rememberCheckbox AgeGateModal-input" checked={this.state.remember} tabIndex="5" onChange={this.handleChangeCheckbox}/>
									<label htmlFor="remember" className="AgeGateModal-rememberDesc">
										<p className="AgeGateModal-rememberDesc-paragraph">Remember me for 30 days.</p>
										<p className="AgeGateModal-rememberDesc-paragraph">This device is not shared with anyone under the age&nbsp;of&nbsp;19.</p>
									</label>
								</div>
								<input type="submit" value="Send" className="AgeGateModal-send AgeGateModal-input"/>
							</form>
						</div>
					</div>
				</div>
				, document.getElementById('root')
			)	: null)
	}
}

export default withRouter(AgeGateModal);