import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SignInButton from '../SignInButton/SignInButton';
import SearchInput from '../SearchInput/SearchInput';
import UserContext from '../../contexts/UserContext';
import globalTV from '../../../brands/brands';
import AppStoreBadge from '../../../assets/icons/AppStoreBadges/global-appstore-badge.svg';
import PlayStoreBadge from '../../../assets/icons/AppStoreBadges/global-playstore-badge.svg';
import { getMobileOperatingSystem } from '../../../utils/helpers';

import NavMenu from '../NavMenu/NavMenu';

import './Header.scss';

class Header extends Component {
	constructor(props) {
		super(props);
		this.searchInputRef = React.createRef();
	}

	static contextType = UserContext;

	static propTypes = {
		location: PropTypes.object,
		signInOnly: PropTypes.bool,
	}

	handleResetSearchBox = (page, item) => {
		document.title = this.getTheTitleName(item);
		localStorage.setItem('currentPage', page);
		this.searchInputRef.current.resetSearchBox();
	}

	getTheTitleName = (linkItem) => {
		if (linkItem.data.name === 'Homepage') {
			return 'Live TV Streaming, On Demand, and 24/7 Global News. Now More Channels on Global TV';
		}

		else if (linkItem.data.name === 'Shows Page') {
			return 'Shows'
		}
		else if (linkItem.data.name === 'Schedule View') {
			return 'Live TV'
		}
		else
			return linkItem.data.name;
	}

	isHome = () => {
		if (this.context.isHome && !this.context.searchQuery) {
			return 'isHome--true';
		}
		return 'isHome--false';
	}

	withRotator = () => {
		if (this.context.withRotator && !this.context.searchQuery) {
			return ' withRotator';
		}
		return ' withoutRotator';
	}

	render() {
		const { pathname } = this.props.location;
		const { globalTV: { images: { logo } } } = globalTV;
		const devicePlatform = getMobileOperatingSystem();
		const isActivatePage = window.location.pathname.indexOf('/activate') > -1;
		if (this.props.signInOnly === true) {
			// Prevents user from linking to other pages
			return (
				<div className={'Header ' + this.isHome() + ' ' + this.withRotator()}>
					<div className="Header-logo-link" style={{ marginInline : `${isActivatePage && devicePlatform ? 'auto' : 'unset'}` }}>
						<img className="Header-logo" src={logo} alt="globaltv logo" />
					</div>
					<div className="Header-nav Header-nav--right">
						<SignInButton buttonClass={'Header-nav-item'} buttonText={'Sign in'} enableChannelList={false} />
					</div>
					{devicePlatform && !isActivatePage && <div className="AppStore-nav" style={{ display : `${devicePlatform ? 'block' : 'none'}` }}>
						{(devicePlatform === 'iOS' || devicePlatform === 'iPadOS') ?
							<div><a href="https://itunes.apple.com/ca/app/global-go/id404050595?mt=8">
								<img src={AppStoreBadge} alt="Global TV iOS App" className="storeLink storeLink--appStore" />
							</a></div>
							:
							<div><a href="https://play.google.com/store/apps/details?id=com.shawmedia.smglobal&hl=en">
								<img src={PlayStoreBadge} alt="Global TV Android App" className="storeLink storeLink--playStore" />
							</a></div>}
					</div>}
				</div>
			)
		}

		return (
			<>
				<div className={'Header ' + this.isHome() + ' ' + this.withRotator()}>
					<Link to="/" className="Header-logo-link" onClick={() => this.handleResetSearchBox('Home', { data: { name: 'Homepage' } })}>
						<img className="Header-logo" src={logo} alt="globaltv logo" />
					</Link>
					<div className="Header-nav Header-nav--left">
						<NavMenu pathname={pathname} handleResetSearchBox={this.handleResetSearchBox} />
					</div>
					<div className="Header-nav Header-nav--right">
						<SearchInput ref={this.searchInputRef} />
						<SignInButton buttonClass={'Header-nav-item'} buttonText={'Sign in'} enableChannelList={true} />
					</div>
				</div>
			</>
		)
	}
}

export default withRouter(Header);
