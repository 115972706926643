export const themeBreakpoints = Object.freeze({
	xs: 0,
	sm: 576,
	md: 992,
	lg: 1200,
	xl: 1640
})

export const SHOW_SHORT_DESC = 150;

export const SHOW_LONG_DESC = 240;

