import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getCookie } from '../../../utils/helpers';

// Note that bundle.js from the analytics plugin - required for this component - is loaded at the global level in index.html.

// Helper function for computing URL parts.
function splitPathname(path) {
	// Remove leading/trailing slashes
	if (path && path[0] == '/') {
		path = path.slice(1);
	}
	if (path && path[path.length - 1] == '/') {
		path = path.slice(0, -1);
	}
	return path ? path.split('/') : [];
}

// Initialization: prepare data and load scripts from WordPress plugin script.
// Required context data.
window.ce_adobe_analytics_web_js_context = {
	settings: {
		brand: 'watch',
		report_suite: process.env.REACT_APP_ADOBE_REPORT_SUITE,
		video_tracking_enabled: 'on',
		auth_tracking_enabled: 'on',
		adblock_tracking_enabled: 'on',
	},
	data: {
		contentHierarchy: splitPathname(window.location.pathname),
	}
}

// Import the plugin script.
// Since it requires environment information, it's hard to load this elsewhere, but it could be refactored.
import './corus-wpp-analytics-omniture/adobe-analytics'; // eslint-disable-line

export function trackPage(path) {
	let pathParts = splitPathname(path);

	window.CorusAdobeAnalytics.trackPage({
		contentHierarchy: pathParts,
		'nav.rotator_pick': null,
		'nav.carouselpick': null,
	})
}

let isInitialLoad = true;

// Main component function.
function AdobeAnalytics() {
	let location = useLocation();
	let consentFlag = getCookie('consentCheck')
	useEffect(
		() => {
			if (isInitialLoad) {
				// adobe-analytics takes care of first page tracking call
				isInitialLoad = false;
			} else if (consentFlag !== 'declined') {
				trackPage(location.pathname);
			}
		},
		[location]
	);

	return null;
}

export default AdobeAnalytics;