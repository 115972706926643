import React, { useState } from 'react';
import './ClearWatchingModel.scss';
import PropTypes from 'prop-types';
import Spinner from '../Spinner/Spinner';
import UserContext from '../../contexts/UserContext';

const ClearWatchingModel = (props) => {
	const [ spinner, setSpinner ] = useState(false);
	const Storage = window.CorusJwplayer.LocalStorageService.Instance;
	const { update } = React.useContext(UserContext);

	const closeModel = () => {
		props.closeModel();
	}

	const clearHistory = async () => {
		setSpinner(true);
		const data = await window.CorusJwplayer.VmsRealtimeService.deleteHistory(localStorage.getItem('puid'));
		if (data) {
			clearStorage ();
			update('pageRefresh', true); // this would clear the progress bar oin other places of the collection
			closeModel ();
		}
	}

	const clearStorage = () => {
		Storage.removeAll();
		Storage.save();
		localStorage.removeItem('CW');
	}

	const model = () => (
		<div className="ClearWatchingModelWrapper">
			<div className="ClearWatchingModel">
				{ spinner && <div className="spinner-box"><Spinner color="black"/></div> }
				<div className='heading'>
					Clear Continue Watching
				</div>
				<p className="subHeading">
					Are you sure you want to clear Continue Watching?
				</p>
				{ (props.state) &&
					<p className="subHeading">
						This will clear viewing progress across all signed in devices.
					</p>
				}
				<div className="button-box">
					<button className="cancelBtn button-primary" onClick={closeModel}>cancel</button>
					<button className="clearBtn button-primary" onClick={clearHistory}>clear</button>
				</div>
			</div>
		</div>
	)

	return (
		<div>
			{ model() }
		</div>
	)
};


ClearWatchingModel.propTypes = {
	state: PropTypes.bool,
	closeModel: PropTypes.func
}

export default ClearWatchingModel;