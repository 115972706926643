import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Spinner from '../Spinner/Spinner';
import geolocation, { geolocationCookieName } from '../../../utils/geolocation';
import { getCookie } from '../../../utils/helpers';

import './HelpModal.scss';

class HelpModal extends Component {
	static propTypes = {
		closeHelpModal: PropTypes.func,
	};

	formRef = React.createRef();
	nameRef = React.createRef();
	emailRef = React.createRef();
	messageRef = React.createRef();
	recaptchaRef = React.createRef();
	categoryRef = React.createRef();
	widgetId = null;

	isReady = () => {
		return typeof window !== 'undefined' && typeof window.grecaptcha !== 'undefined' && typeof window.grecaptcha.render === 'function';
	}

	readyIntervalId = setInterval(() => this.updateReadyState(), 1000);

	state = {
		step: 1,
		responseText: null,
		wasValidated: false,
		ready: this.isReady(),
	}

	updateReadyState = () => {
		if (this.isReady()) {
			this.setState({
				ready: true,
			});
			clearInterval(this.readyIntervalId);
		}
	};

	componentWillUnmount() {
		clearInterval(this.readyIntervalId);
	}

	componentDidMount() {
		if (this.state.ready) {
			this.widgetId = window.grecaptcha.render(
				'g-recaptcha',
				{
					sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
					theme: 'dark',
				},
			);
		}
	}

	closeHelpModal = () => {
		this.props.closeHelpModal();
	}

	submitForm = (event) => {
		event.preventDefault();

		// Validate form
		if ( this.formRef.current.checkValidity() === false ) {
			this.setState({
				wasValidated: true,
			});
			return;
		}

		geolocation.acquireGeolocation();

		// The keys for the values are the entry ids of the Gravity Form
		const entry = {
			form_id: process.env.REACT_APP_CONTACT_US_FORM_ID,                  // Contact Us form id
			4: this.nameRef.current.value.split(' ').slice(0, -1).join(' '),    // First name
			5: this.nameRef.current.value.split(' ').slice(-1).join(' '),       // Last name
			6: this.emailRef.current.value,                                     // Email address
			7: 'Video Report',                                                  // Main category
			8: this.messageRef.current.value,                                   // Problem description
			11: getCookie(geolocationCookieName),                               // Geo location cookie
			12: window.location.href,                                           // Referer url
			13: navigator.userAgent,                                            // User agent
			14: this.categoryRef.current.value,                                 // Sub-category
			15: 'Global Stream Web',                                            // Source
			'g-recaptcha-response': this.state.captcha,                         // ReCaptcha token
		}

		window.grecaptcha.execute(this.widgetId, { action: 'globalstream' }).then( (token) => {

			// Show spinner
			this.setState({
				step: 2,
			})

			entry['g-recaptcha-response'] = token;

			const formUrl = new URL(process.env.REACT_APP_GFORMS_V2_ENDPOINT);

			const request = new Request(formUrl, {
				method: 'POST',
				body: JSON.stringify(entry),
			});

			window.fetch(request).then(
				(response) => { response.json().then(
					(responseBody) => this.finishSubmitting(responseBody),
					(reason) => this.errorState(reason)
				) },
				(reason) => this.errorState(reason)
			);
		});
	}

	finishSubmitting = (responseBody) => {
		if ( typeof responseBody.id !== 'undefined' ) {
			this.finishLoading(responseBody.id);
		} else {
			this.setState({
				step: 3,
				responseText: responseBody.data.status + ' Error: ' + responseBody.message,
			});
		}
	}

	errorState = (reason) => {
		this.setState({
			step: 3,
			responseText: 'It looks like something went wrong.  Please try again later',
		})
		console.log(reason);
	}

	finishLoading = (responseBody) => {
		let text = (responseBody !== null) ?
			'Thank you. Your comments have been submitted.' :
			'Sorry, your entry was invalid. Please try again.';
		this.setState({
			step: 3,
			responseText: text,
		});
	}

	render() {

		const step = this.state.step;
		const responseText = this.state.responseText;

		return (
			ReactDOM.createPortal(
				<div className="HelpModal-wrapper">
					<div className={'HelpModal helpStep-' + step}>
						<div className="HelpModal-body">
							<button onClick={() => this.closeHelpModal()} className="HelpModal-close">
								&times;
							</button>
							{ step === 1 ? (
								<div className="HelpModal-formWrapper">
									<h1 className="HelpModal-title">Report a video issue</h1>
									<div className="HelpModal-description">Let us know your issue and we will investigate the problem as soon as we can.</div>
									<form className={'HelpModal-form' + (this.state.wasValidated ? ' was-validated' : '')} ref={this.formRef} onSubmit={this.submitForm} noValidate>
										<div className="HelpModal-row">
											<div className="HelpModal-field">
												<input className="form-control" name="name" ref={this.nameRef} type="text" placeholder="Full Name*" pattern="(\w.+\s).+" required/>
												<div className="invalid-feedback">Please enter your first and last name</div>
											</div>
											<div className="HelpModal-field">
												<input className="form-control" name="email" ref={this.emailRef} type="email" placeholder="Email*" required/>
												<div className="invalid-feedback">Please enter your email address</div>
											</div>
										</div>
										<div className="HelpModal-row">
											<div className="HelpModal-field">
												<textarea className="form-control" name="message" ref={this.messageRef} placeholder="Message (up to 500 characters)*" required maxLength="500"/>
												<div className="invalid-feedback">Please enter your message</div>
											</div>
										</div>
										<div className="HelpModal-row">
											<div className="HelpModal-field">
												<select className="form-control" name="category" ref={this.categoryRef} type="select" placeholder="Category*" required>
													<option value='' defaultValue='selected' className='gf_placeholder'>Please select an Issue</option>
													<option value='Video Won&#039;t Play' >Video won&#039;t play</option>
													<option value='Video Loading Issue' >Video loading issue</option>
													<option value='Issue with the video sound' >Issue with the video sound</option>
													<option value='Advertising Issue' >Advertising issue</option>
													<option value='I am getting a Location Error' >I am getting a location error</option>
													<option value='I am getting a DRM Error' >I am getting a DRM error</option>
													<option value='Unable to sign in to my cable provider' >Unable to sign in to my cable provider</option>
													<option value='Other' >Other</option>
												</select>
												<div className="invalid-feedback">Please choose a category</div>
											</div>
										</div>
										<button type="submit" className="HelpModal-button">Submit</button>
									</form>
								</div>
							) : step === 2 ? (
								<Spinner color="white"/>
							) : step === 3 ? (
								<div className="HelpModal-thankYou">
									<div className="HelpModal-thankYou-text">
										{responseText}
									</div>
									<button className="HelpModal-button" onClick={this.closeHelpModal}>OK</button>
								</div>
							) : null
							}
						</div>
					</div>
					<div ref={this.recaptchaRef} id='g-recaptcha' data-size='invisible' />
				</div>,
				document.getElementById('root'),
			)
		);
	}
}

export default HelpModal;
