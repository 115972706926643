import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import './Spinner.scss';

Spinner.propTypes = {
	color: PropTypes.string.isRequired,
};

function Spinner(props) {
	const numOfBlades = 12;

	const Spin = keyframes`
		0% {
			background-color: ${props.color};
		}
		100% {
			background-color: transparent;
		}
	`;

	const SpinnerDiv = styled.div`
		animation: ${Spin} 1s infinite linear
	`;

	return (
		<div className="Spinner">
			{[...Array(numOfBlades).keys()].map((number) => (
				<SpinnerDiv className="Spinner-blade" key={number}/>
			))}
		</div>
	)
}

export default Spinner;