import React from 'react';
import PropTypes from 'prop-types';
import fromVms from '../../utility/fromVms';
import { ShowTileArea } from '../../widgets/tile-areas';
import GenreBar from '../../partials/GenreBar/GenreBar';
import BrandBar from '../../partials/BrandBar/BrandBar';

// Finds GUID of a selected brand off of the VMS response and fetches all shows from that show collection
const ShowsView = (props) => {

	// Setting collection guid on brand bar url path
	const { viewId, items, selectedBrand, collectionId, page } = props;

	let selectedGenre, brandBar;
	// get the genre collection title for the freeplay section
	collectionId && items.map(item => {
		if (item.guid === collectionId) {
			selectedGenre = item.label
		}
	})
	if (page !== 'shows') {
		brandBar = <GenreBar key={viewId} viewCollectionItems={items} selectedBrandCollectionId={collectionId} selectedBrand={selectedBrand} parentId={viewId} urlPrefix={`/segmentedview/${viewId}`} />
	}
	else {
		brandBar = <BrandBar key={viewId} viewCollectionItems={items} selectedBrandCollectionId={collectionId} selectedBrand={selectedBrand} parentId={viewId} urlPrefix={`/view/${viewId}/collection/` } />

	}

	let guid = collectionId || null;

	if (!guid) {
		if (page === 'shows') {
			const brandData = items.find(item => item.data.deeplink_id === selectedBrand);
			if (brandData) {
				guid = brandData.guid;
			}
		} else {
			const brandData = items.find(item => item.label === selectedBrand);
			if (brandData) {
				guid = brandData.guid;
			}
		}
	}
	return guid && (
		<>
			<div className="Shows-brandBar">
				{brandBar}
			</div>
			{<ShowTileArea isFree={page === 'Freeplay'} key={selectedBrand} count={25} isGrid={true} ordering="label" parentId={guid} brands={selectedBrand} selectedGenre={selectedGenre} />}
		</>
	)
}

const propMapping = ({ viewId }) => {
	return {
		parent: viewId,
		limit: 15,
	}
}

ShowsView.propTypes = {
	items: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]).isRequired,
	collectionId: PropTypes.string,
	selectedBrand: PropTypes.string,
	viewId: PropTypes.any,
	page: PropTypes.string,
	showBar: PropTypes.any

}

export default fromVms(ShowsView, propMapping);