import React, { useState, useEffect } from 'react';
import Favourite from '../../../assets/icons/Favourite';
import Unfavourite from '../../../assets/icons/Unfavourite';
import UserContext from '../../contexts/UserContext';
import PropTypes from 'prop-types';


import './Like.scss';


const Like = (props) => {
	const [like, setLike] = useState(props?.state);
	const [likeHover, setLikeHover] = useState(false);
	const { isHome, update, pageRefresh } = React.useContext(UserContext);


	useEffect(() => {
		setLike(props.state);
	}, [props.state]);


	const handleFavorites = async (event) => {
		event.preventDefault();
		like ? await window.CorusJwplayer.VmsRealtimeService.deleteFavourite(localStorage.getItem('puid'), props?.state?.guid) : await window.CorusJwplayer.VmsRealtimeService.addFavourite(localStorage.getItem('puid'), props?.guid, props?.type);
		setLike(!like);
		await window.CorusJwplayer.VmsRealtimeService.userFavorites(localStorage.getItem('puid'), 0);
		if (isHome) {
			setTimeout(function () {
				update('pageRefresh', !pageRefresh);
			}, 300);
		}

	}

	return (
		<span className={'like'} onClick={(event) => handleFavorites(event)} onMouseEnter={() => setLikeHover(!likeHover)} onMouseLeave={() => setLikeHover(!likeHover)} >
			{like || likeHover ? <Favourite /> : <Unfavourite />}
		</span>);
}

Like.propTypes = {
	state: PropTypes.bool,
	guid: PropTypes.string,
	type: PropTypes.string,


}
export default Like;
