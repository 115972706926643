import React, { useState, useEffect, useCallback } from 'react';
import { queryVms } from '../../../utils/vmsQuery';
import UserContext from '../../contexts/UserContext';
import { VideoTileArea } from '../../widgets/tile-areas';
import PropTypes from 'prop-types'
import moment from 'moment'

const ScheduleFavorites = (props) => {
	const { pageRefresh } = React.useContext(UserContext);
	const { schedule } = props;
	let data;
	const [tiles, setTiles] = useState([]);
	const [onNow, setOnNow] = useState({});

	let params = {};
	let isCancelled = false;
	useEffect(() => {
		isCancelled = true;
		const getFavs = async () => {
			let responseFavs = [];
			const favPromises = await makeApicall();
			if (favPromises && schedule) {
				Promise.all(favPromises).then((values) => {
					if (values.length > 0) {
						values.map((value) => {
							if (value?.count > 0) {
								data = schedule[value.results[0].guid] ? schedule[value.results[0].guid][0] : null;
								onNowSetter(data, value.results[0].guid)
								responseFavs.push(value?.results[0] || {})
							}
						})
						setOnNow(onNow)
						setTiles(responseFavs)
					}
				});
			}
		}
		getFavs()
		return () => {
			isCancelled = false;
		}
	}, [pageRefresh, schedule])

	const makeApicall = async () => {
		try {
			const items = await (JSON.parse(window.localStorage.getItem('favorites'))?.filter(item => item.type === 'channel'));
			if (!items?.length) {
				setTiles([])
				return null;
			}
			return items.map(async (item) => {
				params = item.group_id;
				if (item.type === 'channel' && isCancelled) {
					const response = await queryVms({
						guid: params,
					});
					return response;
				}
			})
		} catch (e) {
			console.log(e);
		}
	}
	const onNowSetter = useCallback(async(tile, guid) => {
		let now = moment().unix();
		let show, episode;
		if (tile) {
			if (now >= tile.start_time_code && now <= tile.end_time_code) {
				show = tile?.label === 'Movie' ? tile?.data?.episode_title : tile?.label;
				episode = `S${tile.data?.season_number} E${tile.data.episode_number} |  ${tile?.data?.episode_title}`
			} else {
				const { results } = await queryVms({
					parent: guid,
					ordering: 'start_time_code',
					end_time_code__gt: now,
					status: 'published',
					limit: 1,
					type: 'schedule'
				});
				tile = results[0] ?? [];
				show = tile?.label === 'Movie' ? tile?.data?.episode_title : tile?.label;
				episode = (tile?.label !== 'Movie' && tile?.data.episode_number !== 'Generic') ? `S${tile?.data?.season_number} E${tile?.data.episode_number} |  ${tile?.data?.episode_title}` : ''
			}
			onNow[guid] = { now, show, episode };
		} else { onNow[guid] = { show: 'Live News 24/7' } }
	}, [])

	return (
		<div style={{ padding: 0 }} >
			{tiles.length > 0 ? <VideoTileArea
				items={tiles}
				onNow={onNow}
				itemsProps = {true}
				title={'Favourite Channels'}
				type={'channel'}
			/> : null}
		</div>
	)
}
ScheduleFavorites.propTypes = {
	favCheck: PropTypes.func,
	channels: PropTypes.array,
	schedule: PropTypes.any
}
export default ScheduleFavorites;