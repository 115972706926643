import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { shallowArrayEquals } from '../../utils/helpers';
import UserContext from '../contexts/UserContext';
import { queryVms, resolveQueryContainer } from '../../utils/vmsQuery';

/**
 * Wraps a component to have it receive data from the VMS.
 *
 * Reads the component's `count` property to determine how many items to
 * initially load. Defaults to 1.
 *
 * The wrapped component will receive all state props. Refer to the state props
 * for more details. Additionally, it will receive the following props:
 * `loadMore(count)` - function - loads `count` more items if available
 *
 * The `propMapping` function facilitates mapping keys that make sense for your
 * component to keys that the VMS understands. Commonly used params include:
 * `guid` - VMS unique identifier
 * `origin` - brand source of content
 * `ordering` - 'reference_date' (date), '-updated_date' (for PrimeTime Shows) or undefined/null (collection's order).
 * `type` - type of container ('season', 'series', 'media', 'episode', ...)
 * `primary_parent` - associated series GUID (usually - see VMS docs)
 * See the VMS docs for more options:
 * https://github.com/ShawONEX/vms-content-service
 *
 * The component can pass in, via props, a function `resultsFilter()` to filter
 * the results from VMS.
 *
 * @param {Component|function} WrappedComponent The component needing VMS data.
 * @param {function} propMapping A function accepting `props` and returning an
 *   object of the form { [vmsKey]: value }.
 * @param {boolean} propagateErrors Whether to throw an error up the tree when
 *   the VMS call fails for some reason. Default false (sets errorState: true instead).
 */
const fromVms = (WrappedComponent, propMapping, propagateErrors = false) => {
	const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

	return class extends Component {
		static displayName = `FromVms(${wrappedComponentName})`;

		static propTypes = {
			count: PropTypes.number,
			searchKey: PropTypes.string,
			resultsFilter: PropTypes.func,
			isGrid: PropTypes.bool,
			items:PropTypes.array
		}

		static contextType = UserContext;

		constructor(props) {
			super(props);

			this.state = {
				/** Whether more items are available for the query. */
				canLoadMore: false,
				/** Whether an error occurred during loading. */
				errorState: !!this.props.items || false,
				/** The resulting items from the VMS query. */
				items: [],
				/** Whether the VMS data is still loading. */
				loading: true,
				/** The total/maximum number of items attempted to be loaded. */
				targetCount: props.count || propMapping(props).count || 1,
				/** The number of query containers still needing resolution. */
				outstandingQueryContainers: 0,
				/** This below variable is to set pagination flag from VMS. */
				next: null
			}
		}

		/**
		 * React life cycle function. Called by React when the component is mounted (rendered/created). Can be used to
		 * perform non-pure actions instead of render(). A single call to render() happens before this, and render() is NOT
		 * called automatically after this function ends.
		 * @returns {void}
		 */
		componentDidMount() {
			let mappedProps = propMapping(this.props);
			this.loadData(mappedProps);
		}

		/**
		 * React life cycle function. Called by React when the component is updated.
		 * Use this as an opportunity to operate on the DOM when the component has been updated.
		 * @returns {void}
		 */
		componentDidUpdate(oldProps) {
			let requiresUpdate = false;

			let prevMappedProps = propMapping(oldProps);
			let mappedProps = propMapping(this.props);

			for (let [key, value] of Object.entries(mappedProps)) {
				const oldValue = prevMappedProps[key];

				if (Array.isArray(value) && Array.isArray(oldValue)) {
					if (!shallowArrayEquals(value, oldValue)) {
						requiresUpdate = true;
					}
				} else if (value !== oldValue) {
					requiresUpdate = true;
				}
			}

			if (requiresUpdate) {
				this.setState(
					{
						items: [],
						loading: true,
					},
					() => this.loadData(mappedProps)
				);
			}
		}

		/**
		 * Load data from the VMS API. Called on component mount, and again when props change.
		 */
		loadData(props) {
			if (!props.limit) {
				props.limit = this.state.targetCount - this.state.items.length;
			}

			// Query container support: always add 'query' to specified types, if any
			if (props.type) {
				props.type = ['query'].concat(props.type);
			}

			queryVms(props).then(
				(data) => this.finishLoading(data, props),
				(reason) => this.errorState(reason)
			);
		}

		/** Pagination using next value using VMS response  **/
		async pagination(paginationUrl) {
			const { results, next } = await fetch(paginationUrl).then(response => response.json());
			this.setState({
				items: this.state.items.concat(results),
			});

			if (next) {
				this.pagination(next)
			}
		}
		/**
		 * Updates the state once data has been retrieved.
		 * @param {Object} data The JSON API response from the VMS.
		 */
		finishLoading(data, mappedProps) {
			if (!data) return;
			// let adItems = [];
			let newItems = [];
			let outstandingQueryContainers = 0;
			// If an array of GUIDs is requested, ensure that items are returned in the order they were requested.
			// Ideally, this could be done in the VMS.
			if (!mappedProps.ordering && Array.isArray(mappedProps.guid) && mappedProps.guid.length > 1) {
				mappedProps.guid.forEach(guid => {
					data.results.forEach(result => {
						if (result.guid === guid) {
							newItems.push(result);
						}
					})
				})
			} else {
				newItems = data.results;
			}

			// Check for presence of query containers in resulting data.
			if (newItems && newItems.length > 0) {
				for (let result of newItems) {
					if (result.type === 'query') {
						outstandingQueryContainers++;
						resolveQueryContainer(result.data).then(
							innerData => this.populateQueryContainer(result.guid, innerData.results),
							reason => this.errorState(reason)
						)
					}
				}
			}

			// Apply an extra filter to results, if specified
			if (this.props.resultsFilter) {
				newItems = this.props.resultsFilter(newItems);
			}

			this.setState({
				canLoadMore: !!data.next,
				next: data.next,
				items: this.state.items.concat(newItems),
				loading: outstandingQueryContainers !== 0,
				outstandingQueryContainers
			});

			/* Set total count of results only for search API calls */
			if (this.props.searchKey) {
				this.context.update('searchResultsCount', this.context.searchResultsCount + data.count)
			}

			/* Check for more data to load for Shows page  */
			if (this.props?.isGrid && data?.next) {
				this.pagination(data.next)

			}
		}
		/**
		 * Callback to populate a finished-executing query container.
		 * @param {string} queryGuid The GUID of the finished query contiainer, to be replaced.
		 * @param {Array} data The resulting items to swap in.
		 */
		populateQueryContainer(queryGuid, data) {
			let newItems = this.state.items;

			for (let index in this.state.items) {
				// Find the query container and replace it with the resulting data.
				if (this.state.items[index].guid === queryGuid) {
					newItems = [...this.state.items.slice(0, index - 1), ...data, ...this.state.items.slice(index + 1)];
				}
			}

			const outstandingQueryContainers = this.state.outstandingQueryContainers - 1;

			this.setState({
				items: newItems,
				loading: outstandingQueryContainers !== 0,
				outstandingQueryContainers
			})
		}

		/**
		 * Sets the component to an error state.
		 * @param {any} reason The cause of the error.
		 */
		errorState(reason) {
			this.setState({
				loading: false,
				errorState: reason,
			});
		}

		/**
		 * Load the next N items from the VMS.
		 * @param {number} count How many more items to load.
		 */
		loadMore(count) {
			this.setState({ targetCount: this.state.targetCount + count }, () => this.loadData());
		}

		render() {
			if (this.state.errorState && propagateErrors) {
				throw this.state.errorState;
			}
			return <WrappedComponent loadMore={this.loadMore} {...this.state} {...this.props} />
		}
	}
}

export default fromVms;
