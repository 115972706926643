import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './GridTileArea.scss';

class GridTileArea extends Component {
	static propTypes = {
		items: PropTypes.array,
		loading: PropTypes.bool
	}

	render() {
		return (
			<div className={'GridTileArea isLoading--' + this.props.loading}>
				{this.props.items}
			</div>
		);
	}
}

export default GridTileArea;
