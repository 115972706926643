import React from 'react';
import PropTypes from 'prop-types';
import withSearch from '../../utility/withSearch';
import FeaturedView from '../../partials/FeaturedView/FeaturedView';
import withView from '../../utility/withView';
import { addingPermutiveValues } from '../../../utils/permutiveHelper';
import UserContext from '../../contexts/UserContext';

const FreeSection = (props) => {
	let [checkPermitive, setCheckPermitive] = React.useState(false);  // Stop multiple rendering event
	const { login, selectedBDU } = React.useContext(UserContext);
	let currentPage = 'Freeplay'
	localStorage.setItem('currentPage','Freeplay')
	if (!checkPermitive) {
		setCheckPermitive(!checkPermitive);
		addingPermutiveValues({
			content: {
				type: currentPage
			},
			user: {
				auth: {
					status: login,
					...(login && { bdu: selectedBDU })
				}
			}
		});
	}

	let viewId = props.view ? props.view.guid : props.viewId;
	return (
		<div className="FreeSection">
			<div className="FreeSection_Loader" />
			<FeaturedView showHero={true} viewId={viewId} isFree={true} section ={currentPage} />
		</div>
	)
}
FreeSection.propTypes = {
	viewId: PropTypes.string,
	view: PropTypes.object,
}

export default withSearch(withView(FreeSection, 'free-shared'));