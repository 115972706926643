/**
 * Load data from the VMS API using a list of parameters.
 *
 * Returns a promise for data or an error.
 */
export async function queryVms(params) {
	let queryParams = compileVmsParams(params);

	/**
	 * Don't make VMS call if limit is the only query param.
	 * Need this check to synchronously load VMS views.
	 */
	let queryKeys = queryParams.keys();
	if (queryKeys.next().value === 'limit' && queryKeys.next().done) {
		// No params provided. Return an empty result set.
		return null;
	}
	let vmsUrl = ''
	if (params.type !== 'schedule') {
		vmsUrl = '{base_vms_url}/api/container/v1/?' + queryParams.toString();
	} else {
		vmsUrl = '{base_vms_url}/api/segment/v1/?' + queryParams.toString();
	}

	return await queryVmsUrl(vmsUrl);
}

/**
 * Load data from the VMS API from a given URL.
 * Expected template: {base_vms_url}/api/container/v1/?key=value&...
 *
 * Returns a promise for data or an error.
 */
export function queryVmsUrl(vmsUrl) {
	const baseVmsUrl = process.env.REACT_APP_VMS_CONTENT_DOMAIN;
	let vmsUrlObject = new URL(vmsUrl.replace('{base_vms_url}', baseVmsUrl));

	return fetch(vmsUrlObject)
		.then(response => {
			if (!response.ok) {
				throw new Error(`VMS request failed: ${response.statusText}`);
			}
			return response.json();
		})
		.catch(error => {
			throw new Error(`VMS request failed: ${error.message}`);
		});
}

/**
 * Load data from the VMS API from a query container.
 * Expects a query container item (i.e. a child of another collection).
 *
 * Returns a promise for data or an error.
 */
export function resolveQueryContainer(query) {
	if (query.query_type === 'compound') {
		const promise = new Promise((resolve, reject) => {
			let completedQueries = 0;

			let completeQuery = (innerQuery, result) => {
				completedQueries++;
				const stringResult = createQueryString(innerQuery, result.results);
				query.query_template = query.query_template.replace(`{${innerQuery.name}}`, stringResult);

				if (completedQueries >= query.queries.length) {
					query.query_type = 'simple';
					resolveQueryContainer(query).then(
						result => resolve(result),
						error => reject(error)
					);
				}
			}

			query.queries.forEach(innerQuery => {
				resolveQueryContainer(innerQuery).then(
					result => completeQuery(innerQuery, result)
				);
			})
		});
		return promise;
	} else {
		return queryVmsUrl(query.query_template);
	}
}

/// Helper functions. ///

function compileVmsParams(params) {
	const queryParams = new URLSearchParams();

	if (!params.limit) {
		queryParams.append('limit', 1);
	}

	for (let [propKey, propValue] of Object.entries(params)) {
		if (Array.isArray(propValue)) {
			// Multiple values provided - handle accordingly.
			if (propKey.endsWith('__in') || propKey === 'resource_types') {
				// Special case 'OR' handling: pass unioned by `__`.
				queryParams.append(propKey, propValue.join('__'));
			} else {
				// Standard 'OR' handling: pass each as a separate param.
				propValue.forEach(value => {
					queryParams.append(propKey, value);
				});
			}
		} else if (propValue !== undefined && propValue !== null && propValue !== '') {
			// Single value provided.
			queryParams.append(propKey, propValue);
		}
	}

	return queryParams;
}

function createQueryString(query, items) {
	let resultValues = items.map(item => {
		return item[query.key] || item.data[query.key];
	});
	return resultValues.join(query.join);
}
