import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';
import './ShowSection.scss';

import UserContext from '../../contexts/UserContext';
import VideoTileArea from '../../widgets/tile-areas/VideoTileArea/VideoTileArea';
import ChildCollections from './ChildCollections/ChildCollections';
import { byOrigin as brands } from '../../../brands/brands';
import SmartPlayButton from './smartPlayButton/smartPlayButton';
import {
	formatDuration,
	getRemainingDays,
	optimizeImage,
	truncateWithEllipsis,
	getShowMetadata,
} from '../../../utils/helpers';

import { queryVms } from '../../../utils/vmsQuery';
import { themeBreakpoints, SHOW_SHORT_DESC, SHOW_LONG_DESC } from '../../../constants';
import Like from '../UserFavorites/Like';
import shuffleIcon from '../../../assets/icons/icon-in-button_shuffle.png';
import { validateAuthorization } from '../../../utils/helpers';
import LogoImageFromVMS from '../../utility/logoImageFromVMS';
import LinkShare from './shareButton/LinkShare';

class ShowSection extends Component {
	static propTypes = {
		collectionId: PropTypes.string,
		closeShowSection: PropTypes.func,
		closing: PropTypes.bool,
		data: PropTypes.object,
		guid: PropTypes.string,
		label: PropTypes.string,
		origin: PropTypes.string,
		resources: PropTypes.array,
		scrollIntoView: PropTypes.bool,
		history: PropTypes.object.isRequired,
		type: PropTypes.string,
		tags: PropTypes.array,
		title: PropTypes.string,
		order: PropTypes.number
	}

	state = {
		collection: '',
		season: '',
		smartPlaySeason: '',
		seasonItems: [],
		trailerGuid: null,
		ordering: this.props.data.default_ordering || '-reference_date',
		descriptionCharactersLimit: window.innerWidth <= themeBreakpoints.md ? SHOW_SHORT_DESC : SHOW_LONG_DESC,
		expandedDescription: false,
		height: 0,
		seasons: 0,
		episodes: 0,
		collectionType: [] //Setting up the initial loading content type for tileSlider
	}

	static contextType = UserContext;
	LocalStorageService = window.CorusJwplayer.LocalStorageService.Instance;

	constructor(props) {
		super(props);
		this.tileRef = React.createRef();
	}

	async componentDidMount() {
		var bgImg = document.getElementsByClassName('ShowSection-details')[0];
		bgImg.classList.add('Image-loading');
		bgImg.addEventListener('load', this.removeLoadingClass(bgImg));

		if (this.props.scrollIntoView) {
			setTimeout(() => {
				smoothscroll.polyfill();
				const selectedTile = document.getElementsByClassName('ShowTile--selected')[0];
				if (selectedTile) {
					selectedTile.scrollIntoView({ behavior: 'smooth' });
				}
			}, 400)
		}

		if (this.props.tags.find(item => item.type === 'decorator' && item.value === 'decorator_trailer_play_button')) {
			this.fetchTrailer();
		}
		window.addEventListener('resize', this.computeDescLength, true);
		//Set the dynamic height of the description
		if (this.tileRef.current) {
			this.setState({ height: this.tileRef.current.clientHeight })
		}

		const seasons = await queryVms({
			parent: this.props.guid,
			type: ['season'],
			'tags.value': 'availability_application_globalstream',
			limit: 100,
		});
		this.setState({ seasons: seasons.count })

		const episodes = await queryVms({
			parent: this.props.guid,
			type: ['episode'],
			limit: 1,
		});
		this.setState({ episodes: episodes.count })
	}


	componentDidUpdate(prevProps, prevState) {
		if (prevState.expandedDescription !== this.state.expandedDescription && this.tileRef.current) {
			this.setState({ height: this.tileRef.current.clientHeight })
		}
	}

	componentWillUnmount() {
		// Clear pending tasks
		window.removeEventListener('resize', this.computeDescLength);
	}

	shouldComponentUpdate(nextProps, nextState) {
		// This component should only re-render when a different show or season or more description is selected,
		// or if the close button is pressed.
		// Note that updating the show ID will NOT reset the season dropdown. If the show will
		// change, set the show as the key of this component so that React recreates this.
		return this.state.trailerGuid !== nextState.trailerGuid || this.props.guid !== nextProps.guid
			|| this.props.closing !== nextProps.closing || this.state.season !== nextState.season
			|| nextState.expandedDescription !== this.state.expandedDescription || nextState.height !== this.state.height
			|| this.state.type !== nextState.type || this.state.collection !== nextState.collection;
	}

	/**
	 * Fetching trailer from the VMS API for show GUID
	 */
	fetchTrailer = async () => {
		const queryData = await queryVms({
			parent: this.props.guid,
			'tags.value': 'decorator_trailer_play_button',
			limit: 1,
			ordering: 'published_date',
		});

		if (queryData.count > 0) {
			this.setState({ trailerGuid: queryData.results[0].guid });
		}
	}

	checkDescription = (desc) => {
		if (desc) {
			return <>
				<div className={'ShowSection-description--text'} style={{ height: `${this.state.height + (desc.length <= this.state.descriptionCharactersLimit ? 25 : 0)}px` }}> <p ref={this.tileRef}>{(!this.state.expandedDescription && desc.length > this.state.descriptionCharactersLimit) ?
					truncateWithEllipsis(desc, this.state.descriptionCharactersLimit) : desc
				}</p>
				</div>
				{(desc.length > this.state.descriptionCharactersLimit) &&
					<p className={'ShowSection-description--' + (this.state.expandedDescription ? 'less' : 'more')}
						onClick={() => this.setState({ ...this.state, expandedDescription: !this.state.expandedDescription })}>
						{this.state.expandedDescription ? 'Less' : 'More'}
					</p>
				}
			</>
		}
	}

	checkLikeState = (guid) => {
		let foundData = [];
		const likeObj = JSON.parse(localStorage.getItem('favorites')) || [];
		if (likeObj.length > 0) {
			foundData = likeObj.filter((like) =>
				like.group_id === guid
			)
		}
		return foundData.length > 0 ? foundData[0] : false;
	}


	removeLoadingClass = (bgImg) => {
		bgImg.classList.remove('Image-loading');
	}

	async onCollectionChange(collection, ordering) {
		const seasons = await queryVms({
			parent: collection,
			type: 'season',
			'tags.value': 'availability_application_globalstream',
			limit: 100,
			ordering
		});
		if (this.state.season === '') {
			this.setState({
				season: seasons.count ? this.state.smartPlaySeason : '',
				collection,
				ordering: seasons.count ? seasons.results[0].data.default_ordering : ordering,
				collectionType: seasons.count ? 'episode' : ['episode','media'],
				seasonItems: seasons.results,

			})
		}
		else if (this.state.season && seasons.results.length > 0) {
			this.setState({
				season: this.state.smartPlaySeason,
				collectionType: ['episode'],
				collection,
				ordering: seasons.count ? seasons.results.find(season => season.guid === this.state.smartPlaySeason )?.data.default_ordering : ordering,
				seasonItems: seasons.results,
			})
		} else {
			this.setState({
				season: '',								// for non-seasoned collections, reset the season guid to null
				collection,
				ordering,
				collectionType: ['episode', 'media'],
				seasonItems: seasons.results,
			})
		}
	}

	onSeasonChange(season, ordering) {
		if (ordering === 'smartPlayCollection') {
			this.setState({
				season,
				smartPlaySeason: season
			})
		} else {
			this.setState({
				season,
				ordering,
				collectionType: ['episode']
			});
		}
	}



	computeDescLength = () => {
		this.setState({ descriptionCharactersLimit: window.innerWidth <= themeBreakpoints.md ? SHOW_SHORT_DESC : SHOW_LONG_DESC })
	}

	getRandomEpisode = async () => {
		const isAuth = validateAuthorization(this.props.origin, this.context.authenticated_origins)
		const queryData = await queryVms({
			parent: this.props.guid,
			type: ['episode'],
			limit: this.state.episodes,
		});

		let authData = queryData.results?.filter(value => {
			const windowEndDate = value.data.public_window_end_date;
			const remainingDays = getRemainingDays(windowEndDate);
			return ((!windowEndDate && !remainingDays) || windowEndDate !== undefined || (isAuth && this.context.signedIn))
		})
		const randomShuffle = (count) => {
			return Math.floor((Math.random() * count));
		}
		let random_index = randomShuffle(authData.length)

		if (random_index === authData.count) {
			random_index = randomShuffle(authData.length - 1)
		}

		localStorage.removeItem('randomShuffleArray')
		if (authData.length > 0) {
			localStorage.setItem('random number', authData[random_index].order)
			this.props.history.push(`/series/${this.props.guid}/episode/${authData[random_index].guid}/?action=play&shuffle=true`);
		} else this.props.history.push(`/series/${this.props.guid}/episode/${queryData.results[0].guid}/?action=play&shuffle=true`);

	}


	render() {

		const { closing, origin, data: { description, duration, genre, public_window_end_date, expiration_date, air_year, sub_label, tv_rating, subgenre }, guid, label, resources, tags, type } = this.props;
		const placeholderImage = brands(origin) ? brands(origin).placeholderImages.showHero : '';
		const applicableImage = resources.find(resource => resource.tag === 'show_hero');
		const showImage = applicableImage ? optimizeImage(applicableImage.uri, '1920') : placeholderImage;
		const remainingDays = getRemainingDays(public_window_end_date);
		const authorized = validateAuthorization(origin, this.context.authenticated_origins);
		const ex_date = expiration_date && new Date(expiration_date).getFullYear() !== 1969 ? new Date(expiration_date) : null;
		const expiration_date_text = ex_date ? `${ex_date.getMonth() + 1 < 10 ? '0' : ''}${ex_date.getMonth() + 1}-${ex_date.getDate() < 10 ? '0' : ''}${ex_date.getDate()}-${ex_date.getFullYear()}` : ''
		let MovieButtonCTA = null;

		const MoviePlayLink = <Link onClick={() => window.location.href = `/movie/${guid}?action=play`} draggable="false" className="Movie-play-button button-primary">Watch Movie</Link>
		if (this.context.signedIn && !this.context.displayAuth) {
			if (public_window_end_date && remainingDays !== undefined) {
				MovieButtonCTA = <Link to={`/video/${guid}`} draggable="false" className="Movie-play-button button-primary">
					{`Available for ${remainingDays}`}
				</Link>
			} else {
				if (authorized || public_window_end_date !== undefined) {       // allows free contents to play irrespective signin check
					MovieButtonCTA = MoviePlayLink;
				} else {
					MovieButtonCTA = <a
						href="https://www.globaltv.com/channel-finder/"
						className="Movie-play-button button-primary"
						target="_blank"
						rel="noopener noreferrer"
						draggable="false"
					>
						Subscribe To Watch
					</a>
				}
			}
		} else {
			if (public_window_end_date && remainingDays !== undefined) {
				MovieButtonCTA = MoviePlayLink;
			} else {
				if (!public_window_end_date) {                   // allows free contents to play irrespective signin check
					MovieButtonCTA = MoviePlayLink;
				} else {

					MovieButtonCTA = <div onClick={() => this.context.update('displayAuth', true)} className="Movie-play-button button-primary">
						Sign in to Watch
					</div>
				}
			}
		}

		/**
		 * Adding trailer to movie and series
		 */
		let trailerBtn = null;
		if (this.state.trailerGuid) {
			let trailerUrl = `/video/${this.state.trailerGuid}`;
			if (type === 'movie') {
				trailerUrl = `/movie/${guid}/media/${this.state.trailerGuid}?action=play`;
			}
			trailerBtn = <Link to={trailerUrl} draggable="false" className="Movie-play-button button-primary">Watch Trailer</Link>;
		}

		/**
		 * Random episode button
		 */
		let randomEpisodeButton = null;
		if (tags.findIndex((tag) => tag.value === 'behaviour_shuffle') > -1) {
			randomEpisodeButton = (
				<button className='ShowSection-button button-primary' onClick={this.getRandomEpisode}>
					<img alt={'shuffle play'} src={shuffleIcon}></img>
					{'SHUFFLE'}
				</button>
			)
		}
		return (
			<div className={'ShowSection ' + ((closing) ? 'closing' : '')}>
				<div className="ShowSection-details" style={{ backgroundImage: `url(${showImage})` }}>
					<LogoImageFromVMS classes={'ShowSection-logo ShowSection-logo-' + origin} origin={origin} />
					<span className="ShowSection-title">{label}</span>
					<span className="ShowSection-metadata">{sub_label}</span>
					<span className="ShowSection-description">
						{getShowMetadata(air_year, this.state.seasons, genre, subgenre, tv_rating, type === 'movie' ? formatDuration(duration, type) : null)}
					</span>
					<span className="ShowSection-description">
						{expiration_date_text ? `Available until ${expiration_date_text}` : ''}
					</span>
					<span className="ShowSection-description" >{this.checkDescription(description)}</span>
					{type === 'series' && <div style={{ display: 'flex' }}>
						<SmartPlayButton {...this.props} seasons={this.state.seasons} onCollectionClick={this.onSeasonChange.bind(this)} randomEpisodeButton={!!randomEpisodeButton} />
						{randomEpisodeButton}
						<Like guid={this.props.guid} state={this.checkLikeState(this.props.guid)} type={type} />
						{<LinkShare title={label}/>}
					</div>}
					{type === 'movie' && <div className="ShowSection-watch-buttons">
						<SmartPlayButton {...this.props} movieButton={MovieButtonCTA} />
						<Like guid={this.props.guid} state={this.checkLikeState(this.props.guid)} type={type} />
						{trailerBtn}
						<LinkShare title={label}/>
					</div>}

				</div>
				<ChildCollections guid={guid} type={'collection'} collectionId={this.state.collection} onCollectionClick={this.onCollectionChange.bind(this)} />
				{this.state.seasonItems.length > 0 ? <ChildCollections guid={guid} items={this.state.seasonItems} seasonId={this.state.season} onCollectionClick={this.onSeasonChange.bind(this)} /> : null}

				{(this.state.collectionType.length > 0) && <VideoTileArea expanded={true} maxNewItems={2} showIsNew={true} collectionId={this.state.collection} showId={guid} seasonId={this.state.season} brands={[origin]} type={this.state.collectionType} count={200} ordering={this.state.ordering} />}
				<div className="Close-button-wrapper">
					<button aria-label="Close Show Section" className="Close-show-section" onClick={() => this.props.closeShowSection()}><span className="Close">&times;</span></button>
				</div>
			</div>
		)
	}
}

export default withRouter(ShowSection);
