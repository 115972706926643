import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
// import FeaturedView from '../../partials/FeaturedView/FeaturedView';

import fromVms from '../../utility/fromVms';
import ShowSection from '../../partials/ShowSection/ShowSection';
import withSearch from '../../utility/withSearch';
import withView from '../../utility/withView';

import './Show.scss';
import { addingPermutiveValues } from '../../../utils/permutiveHelper';
import UserContext from '../../contexts/UserContext';
import CustomError from '../Error/CustomError';

class Show extends Component {
	static propTypes = {
		items: PropTypes.array,
		loading: PropTypes.bool,
		viewId: PropTypes.string,
		history: PropTypes.object,
		match: PropTypes.shape({
			params: PropTypes.shape({
				collectionId: PropTypes.string,
				seriesId: PropTypes.string,
			}).isRequired,
		}).isRequired,
	}

	static contextType = UserContext;

	permutiveForShowsDetailPage () {
		const { signedIn, authenticated_origins, selectedBDU } = this.context;
		const loginState = signedIn && authenticated_origins?.length > 0;
		let  currentPage = localStorage.getItem('currentPage') || 'Home';
		const sections = (currentPage == 'Shows') ? ['Shows', this.props.items[0].origin, this.props.items[0].label] : ['Home', 'Global Stream Rotator', this.props.items[0].label] ;
		addingPermutiveValues ({
			content: {
				...(sections.every((item) => typeof item === 'string') ? { sections } : ''),
				type: currentPage,
				name: this.props.items[0].label,
				keywords: this.props.items[0].data.keywords?.split(',')
			},
			user: {
				auth: {
					status: loginState ? 'authenticated' : 'non-authenticated',
					...(loginState && { bdu: selectedBDU })
				}
			}
		} );
	}

	render() {

		if (this.props.loading) {
			// TODO: proper loading state
			return null;
		} else {
			if (this.props.items.length > 0) {
				const show = this.props.items[0];
				this.permutiveForShowsDetailPage();
				return (
					<div className="Show">
						<TransitionGroup component="div" className="Show-wrapper">
							<CSSTransition
								classNames="Show-experience"
								appear={true}
								timeout={{ appear: 1000 }}
								enter={false}
								leave={false}
							>
								<ShowSection key={show.guid} {...show} />
							</CSSTransition>
						</TransitionGroup>
						{/* <FeaturedView showHero={false} viewId={this.props.viewId} /> */}
					</div>
				)
			} else {
				return (
					<CustomError message='This video is no longer available. Please try another video.'/>
				)
			}
		}
	}
}

const propMapping = (props) => {
	// Series & movies deeplink and comply with seriesId or videoId
	const showItemId = props.match.params.seriesId || props.match.params.videoId;
	if ( showItemId ) {
		return {
			guid: showItemId,
			type: ['movie', 'series'],
			collectionId: props.match.params.collectionId
		}
	}
	return {
		guid: props.match.params.showId,
		type: ['movie', 'series']
	}
}

export default withSearch(withView(fromVms(Show, propMapping), 'web_home_view'));