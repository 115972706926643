import moment from 'moment'

export const dateToNumber = (date) =>
	typeof date === 'number' ? date : date.getTime();

export const numberToDate = (number) =>
	typeof number === 'number' ? new Date(number) : number;

export const getColSpan = (start, end) => {
	return ((dateToNumber(end) - dateToNumber(start)) / (1000 * 60))
};

export const addHours = (date, hrs) => {
	let ROUNDING = 30 * 60 * 1000;
	return { start: moment(Math.floor((moment(date)) / ROUNDING) * ROUNDING), end: moment(date).add(hrs, 'h') }
}

export const progressSetter = (startTime, timeNow) => {
	const diff = timeNow.diff(startTime)
	const timeElpased = moment.duration(diff)
	const progress = (timeElpased.minutes() * 3.3333) / 100
	return (progress)
}