import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ShowsView from './ShowsView';
import withSearch from '../../utility/withSearch';
import withView from '../../utility/withView';
import { configbyOrigin } from '../../../utils/helpers';
import NotFound from '../NotFound/NotFound';
import './Shows.scss';

const Shows = (props) => {
	const { match, viewId } = props;


	let currentBrand = match.params.brand || 'global';
	let brands = configbyOrigin();
	// const query = queryString.parse(window.location.search);
	// if (query.origin) {
	// 	currentBrand = query.origin;
	// 	if (userContext.lastView) {
	// 		userContext.lastView.origin = query.origin;
	// 	}
	// }
	const availableBrands = [];
	for (let key in brands) {
		availableBrands.push(brands[key].origin)
	}
	if (!availableBrands.includes(currentBrand)) {
		return <NotFound />;
	}


	return (
		<div className="Shows">
			<div className="Shows-title">Shows</div>
			<ShowsView viewId={viewId} collectionId={match.params.collectionId} selectedBrand={currentBrand} page={'shows'} />
		</div>
	)

}

Shows.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			brand: PropTypes.string,
			collectionId: PropTypes.string,
		}).isRequired,
	}).isRequired,
	viewId: PropTypes.string,
}

export default withSearch(withView(Shows, 'shared_view_shows'));
