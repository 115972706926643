import ShawGoIcon from '../assets/icons/bdus/ShawGo.png';
import RogersIcon from '../assets/icons/bdus/Rogers.png';
import BellIcon from '../assets/icons/bdus/Bell.png';
import VideotronIcon from '../assets/icons/bdus/Videotron.png';
import telus_auth_gateway_netIcon from '../assets/icons/bdus/telus_auth-gateway_net.png';
import CogecoIcon from '../assets/icons/bdus/cogeco-web.png';
import ccsaacc010Icon from '../assets/icons/bdus/access_comm_prod.png';
import ccsabru010 from '../assets/icons/bdus/BruceTelecom.png';
import idp_ccn_idp_bf_prodIcon from '../assets/icons/bdus/beanfield-web.png';
import BellAliantIcon from '../assets/icons/bdus/BellAliant.png';
import ManitobaIcon from '../assets/icons/bdus/Manitoba.png';
import ccsabro010Icon from '../assets/icons/bdus/ccsabro010.png';
import CableCableIcon from '../assets/icons/bdus/CableCable.png';
import idp_ccn_idp_cp_prodIcon from '../assets/icons/bdus/idp_ccn_idp_cp_prod.png';
import ccsacit010Icon from '../assets/icons/bdus/ccsacit010.png';
import EastlinkIcon from '../assets/icons/bdus/Eastlink.png';
import ccsaexe010Icon from '../assets/icons/bdus/ccsaexe010.png';
import ccsagos010Icon from '../assets/icons/bdus/ccsagos010.png';
import ccsahay010Icon from '../assets/icons/bdus/ccsahay010.png';
import ccsactc010Icon from '../assets/icons/bdus/ccsactc010.png';
import ccsamit010Icon from '../assets/icons/bdus/ccsamit010.png';
import ccsamor010Icon from '../assets/icons/bdus/ccsamor010.png';
import ccsanft010Icon from '../assets/icons/bdus/ccsanft010.png';
import NorthwestelIcon from '../assets/icons/bdus/Northwestel.png';
import NovusIcon from '../assets/icons/bdus/Novus.png';
import ccsanrt010Icon from '../assets/icons/bdus/ccsanrt010.png';
import idp_ccn_idp_shon_prodIcon from '../assets/icons/bdus/idp_ccn_idp_shon_prod.png';
import SasktelIcon from '../assets/icons/bdus/Sasktel.png';
import ccsasea010Icon from '../assets/icons/bdus/ccsasea010.png';
import SourceIcon from '../assets/icons/bdus/Source.png';
import ccsatba010Icon from '../assets/icons/bdus/ccsatba010.png';
import ccsatuc010Icon from '../assets/icons/bdus/ccsatuc010.png';
import ccsavme010Icon from '../assets/icons/bdus/ccsavme010.png';
import ccsawes010Icon from '../assets/icons/bdus/ccsawes010.png';
import ccsawig010Icon from '../assets/icons/bdus/ccsawig010.png';
import ccsawtc010Icon from '../assets/icons/bdus/ccsawtc010.png';
import idp_ccn_idp_rw_prodIcon from '../assets/icons/bdus/idp_ccn_idp_rw_prod.png';
import idp_ccn_idp_ts_prodIcon from '../assets/icons/bdus/idp_ccn_idp_ts_prod.png';
import idp_ccn_idp_shon_prod_icon from '../assets/icons/bdus/StartCa.png';


const bdus = {

	//Shaw
	ShawGo: { logoURL: ShawGoIcon },

	//Rogers
	Rogers: { logoURL: RogersIcon },

	//Bell
	Bell: { logoURL: BellIcon },

	//Videotron
	Videotron: { logoURL: VideotronIcon },

	//TELUS Optik TV
	'telus_auth-gateway_net': { logoURL: telus_auth_gateway_netIcon },

	//Cogeco
	Cogeco: { logoURL: CogecoIcon },

	//Bell Aliant
	BellAliant: { logoURL: BellAliantIcon },

	//Bell MTS
	Manitoba: { logoURL: ManitobaIcon },

	//Brooke Telecom
	ccsabro010: { logoURL: ccsabro010Icon },

	//Bruce Telecom
	ccsabru010: { logoURL: ccsabru010 },

	//Cable Cable
	CableCable: { logoURL: CableCableIcon },

	//CCAP
	idp_ccn_idp_cp_prod: { logoURL: idp_ccn_idp_cp_prodIcon },

	//CityWest
	ccsacit010: { logoURL: ccsacit010Icon },

	//Eastlink
	Eastlink: { logoURL: EastlinkIcon },

	//Execulink
	ccsaexe010: { logoURL: ccsaexe010Icon },

	//Gosfield North Comm.
	ccsagos010: { logoURL: ccsagos010Icon },

	//Hay Communications
	ccsahay010: { logoURL: ccsahay010Icon },

	//Lynx Network
	ccsactc010: { logoURL: ccsactc010Icon },

	//Mitchell Seaforth Cable TV
	ccsamit010: { logoURL: ccsamit010Icon },

	//Mornington Comm.
	ccsamor010: { logoURL: ccsamor010Icon },

	//NFTC
	ccsanft010: { logoURL: ccsanft010Icon },

	//Northwestel Inc.
	Northwestel: { logoURL: NorthwestelIcon },

	//Novus
	Novus: { logoURL: NovusIcon },

	//NRTC Communications
	ccsanrt010: { logoURL: ccsanrt010Icon },

	//O-Net
	idp_ccn_idp_shon_prod: { logoURL: idp_ccn_idp_shon_prodIcon },


	//SaskTel
	Sasktel: { logoURL: SasktelIcon },

	//Seaside Communications
	ccsasea010: { logoURL: ccsasea010Icon },

	//Source Cable
	Source: { logoURL: SourceIcon },

	//Tbaytel
	ccsatba010: { logoURL: ccsatba010Icon },

	//Tuckersmith Comm
	ccsatuc010: { logoURL: ccsatuc010Icon },

	//start.ca
	idp_ccn_idp_st_prod: { logoURL: idp_ccn_idp_shon_prod_icon },

	//VMedia
	ccsavme010: { logoURL: ccsavme010Icon },

	//Westman Communications Group
	ccsawes010: { logoURL: ccsawes010Icon },

	//Wightman Communications
	ccsawig010: { logoURL: ccsawig010Icon },

	//WTC Communications
	ccsawtc010: { logoURL: ccsawtc010Icon },

	//Ruralwave
	idp_ccn_idp_rw_prod: { logoURL: idp_ccn_idp_rw_prodIcon },

	//Teksavvy
	idp_ccn_idp_ts_prod: { logoURL: idp_ccn_idp_ts_prodIcon },

	//Beanfield Metroconnect
	idp_ccn_idp_bf_prod: { logoURL: idp_ccn_idp_bf_prodIcon },

	//Access Communication
	ccsaacc010: { logoURL: ccsaacc010Icon },


}

export default bdus
