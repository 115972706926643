import React, { useState, useEffect, useRef } from 'react';
import FaqGeneralQuestionaires from './FaqGeneralQuestionaires';
import './Faq.scss';
import GeneralFAQSection from './generalFAQSection';
import DeviceSpecificQuestion from './deviceSpecficQuestion';
import { dropDownOptions } from './dropDownOptions';

const Faq = () => {
	const [showModal, setShowModal] = useState(false);
	const [deviceSpecificQuestion, setDeviceSpecificQuestion] = useState(dropDownOptions[0].value)
	const refModal = useRef(null);
	const refWrapper = useRef(null);
	const refQuestionSection = useRef(null);

	const [selectedDevice, setSelectedDevice] = useState('Choose your device')
	const generalQuestions = FaqGeneralQuestionaires();

	useEffect(() => {

		function handleClickOutside(event) {
			if (refModal.current && !refModal.current.contains(event.target) && !refWrapper.current.contains(event.target) && !refQuestionSection.current.contains(event.target)) {
				setShowModal(false)
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [refModal]);

	return (
		<div className='Faq-container'>
			<div>
				<div className='Faq-heading'>Global TV App FAQs</div>
			</div>
			<div style={{ width: '100%', position: 'relative' }}>
				<div ref={refWrapper} className={`Dropdown-Menu Dropdown-Menu--${showModal}`} onClick={() => { setShowModal(!showModal) }}>{selectedDevice}</div>
				{showModal && <div ref={refModal} className='Dropdown-overlay'>
					{dropDownOptions.map(option => {
						return <div className={`Dropdown-option ${option.id === selectedDevice ? 'active' : ''}`} key={option.id} onClick={() => { setSelectedDevice(option.id); setDeviceSpecificQuestion(option.value); setShowModal(!showModal) }}>{option.id}</div>
					})}
				</div>}
			</div>
			<div ref={refQuestionSection} className='Question-Container'>
				{selectedDevice && deviceSpecificQuestion.map(data => (<DeviceSpecificQuestion key={data.q} data={data} />))}
				{generalQuestions.map(item => (<GeneralFAQSection key={item.q} question={item.q} answer={item.a} />))}
			</div>
			<div className='Faq-Footer'>
				<p className='Footer-Text'>Can’t find what you’re looking for?</p>
				<a className='Footer-Link' href='https://www.globaltv.com/faq' target="_blank" rel="noopener noreferrer">View Full FAQ</a>
			</div>
		</div>
	)
}

export default Faq;