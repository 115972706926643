import React from 'react';
import './PromoPage.scss';
import PropTypes from 'prop-types'


const AppPromoBox = (props) => {
	const { logo, title1, title2, descriptionText, conditionalText, primaryButton, secondaryButton, promoHeroImage, callbackCTA, primaryLink, imageLabel } = props;

	return (
		<div className="AppPromo-container">
			<div className='AppPromo-ImageWrap' >
				<img className='AppPromo-heroImage' src={promoHeroImage} alt={imageLabel} />
			</div>
			<div className="AppPromo-textBox">
				{logo && <img className='AppPromo-logo' src={logo} />}
				<div className="AppPromo-title">{title1}</div>
				{title2 && <div className="AppPromo-title">{title2}</div>}
				{<div className='AppPromo-ImageWrap' >
					<img className='AppPromo-heroImage' src={promoHeroImage} alt={imageLabel} />
				</div>}
				<div className='AppPromo-body'>{descriptionText}</div>
				{conditionalText && <p className='AppPromo-conditionText'>{conditionalText}</p>}
				{secondaryButton ?
					<div className='buttonWrapper'>
						<a href={primaryLink} ><button className='button-primary'>{primaryButton}</button></a>
						<button className='button-secondary' onClick={() => callbackCTA()}>{secondaryButton}</button>
					</div>
					:
					<a href={primaryLink} ><button className='button-primary'>
						{primaryButton}</button></a>}
			</div>
		</div >
	)
}

AppPromoBox.propTypes = {
	logo: PropTypes.string,
	title1: PropTypes.string,
	title2: PropTypes.string,
	descriptionText: PropTypes.string,
	conditionalText: PropTypes.string,
	primaryButton: PropTypes.string,
	secondaryButton: PropTypes.string,
	promoHeroImage: PropTypes.string,
	callbackCTA: PropTypes.func,
	primaryLink: PropTypes.string,
	imageLabel: PropTypes.string
}

export default AppPromoBox;