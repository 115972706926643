import globalShowPlaceholder from '../assets/placeholders/show/GlobalTV.jpg';
import wNetworkShowPlaceholder from '../assets/placeholders/show/WNetwork.jpg';
import historyShowPlaceholder from '../assets/placeholders/show/History.jpg';
import sliceShowPlaceholder from '../assets/placeholders/show/Slice.jpg';
import showcaseShowPlaceholder from '../assets/placeholders/show/Showcase.jpg';
import globalNewsShowPlaceholder from '../assets/placeholders/show/GlobalNews.jpg';
import foodNetworkShowPlaceholder from '../assets/placeholders/show/FoodNetwork.jpg';
import hgtvShowPlaceholder from '../assets/placeholders/show/HGTV.jpg';
import natGeoShowPlaceholder from '../assets/placeholders/show/NatGeo.jpg';
import adultSwimShowPlaceholder from '../assets/placeholders/show/AdultSwim.jpg';
import ytvShowPlaceholder from '../assets/placeholders/show/YTV.jpg';
import lifetimeShowPlaceholder from '../assets/placeholders/show/Lifetime.jpg';
import magnoliaShowPlaceholder from '../assets/placeholders/show/Magnolia.jpg';

import globalVideoPlaceholder from '../assets/placeholders/video/GlobalTV-generic-video.jpg';
import wNetworkVideoPlaceholder from '../assets/placeholders/video/WNetwork-generic-video.jpg';
import historyVideoPlaceholder from '../assets/placeholders/video/History-generic-video.jpg';
import sliceVideoPlaceholder from '../assets/placeholders/video/Slice-generic-video.jpg';
import showcaseVideoPlaceholder from '../assets/placeholders/video/Showcase-generic-video.jpg';
import globalNewsVideoPlaceholder from '../assets/placeholders/video/GlobalNews-generic-video.jpg';
import foodNetworkVideoPlaceholder from '../assets/placeholders/video/FoodNetwork-generic-video.jpg';
import hgtvVideoPlaceholder from '../assets/placeholders/video/HGTV-generic-video.jpg';
import natGeoVideoPlaceholder from '../assets/placeholders/video/NatGeo-generic-video.jpg';
import adultSwimVideoPlaceholder from '../assets/placeholders/video/AdultSwim-generic-video.jpg';
import ytvVideoPlaceholder from '../assets/placeholders/video/YTV-generic-video.jpg';
import lifetimeVideoPlaceholder from '../assets/placeholders/video/Lifetime-generic-video.jpg';
import magnoliaVideoPlaceholder from '../assets/placeholders/video/Magnolia-generic-video.jpg';

import globalHeroPlaceholder from '../assets/placeholders/hero/GlobalTV-generic-hero.jpg';
import wNetworkHeroPlaceholder from '../assets/placeholders/hero/WNetwork-generic-hero.jpg';
import historyHeroPlaceholder from '../assets/placeholders/hero/History-generic-hero.jpg';
import sliceHeroPlaceholder from '../assets/placeholders/hero/Slice-generic-hero.jpg';
import showcaseHeroPlaceholder from '../assets/placeholders/hero/Showcase-generic-hero.jpg';
import globalNewsHeroPlaceholder from '../assets/placeholders/hero/GlobalNews-generic-hero.jpg';
import foodNetworkHeroPlaceholder from '../assets/placeholders/hero/FoodNetwork-generic-hero.jpg';
import hgtvHeroPlaceholder from '../assets/placeholders/hero/HGTV-generic-hero.jpg';
import natGeoHeroPlaceholder from '../assets/placeholders/hero/NatGeo-generic-hero.jpg';
import adultSwimHeroPlaceholder from '../assets/placeholders/hero/AdultSwim-generic-hero.jpg';
import ytvHeroPlaceholder from '../assets/placeholders/hero/YTV-generic-hero.jpg';
import lifetimeHeroPlaceholder from '../assets/placeholders/hero/Lifetime-generic-hero.jpg';
import magnoliaHeroPlaceholder from '../assets/placeholders/hero/Magnolia-generic-hero.jpg';

import globalTVIcon from '../assets/icons/brands/white/globaltv.svg';
import showcaseIcon from '../assets/icons/brands/white/showcase.svg';
import wnetworkIcon from '../assets/icons/brands/white/wnetwork.svg';
import sliceIcon from '../assets/icons/brands/white/slice.svg';
import historyIcon from '../assets/icons/brands/white/historyca.svg';
import globalNewsIcon from '../assets/icons/brands/white/global-news.svg';
import hgtvIcon from '../assets/icons/brands/white/hgtv.svg';
import foodNetworkIcon from '../assets/icons/brands/white/food-network.svg';
import natGeoIcon from '../assets/icons/brands/white/natgeo.svg';
import adultSwimIcon from '../assets/icons/brands/white/adultswim.svg';
import ytvIcon from '../assets/icons/brands/white/ytv.svg';

import globalTVIconGrey from '../assets/icons/brands/grey/globaltv.svg';
import showcaseIconGrey from '../assets/icons/brands/grey/showcase.svg';
import wnetworkIconGrey from '../assets/icons/brands/grey/wnetwork.svg';
import sliceIconGrey from '../assets/icons/brands/grey/slice.svg';
import historyIconGrey from '../assets/icons/brands/grey/historyca.svg';
import globalNewsIconGrey from '../assets/icons/brands/grey/global-news.svg';
import hgtvIconGrey from '../assets/icons/brands/grey/hgtv.svg';
import foodNetworkIconGrey from '../assets/icons/brands/grey/food-network.svg';
import natGeoIconGrey from '../assets/icons/brands/grey/natgeo.svg';
import adultSwimIconGrey from '../assets/icons/brands/grey/adultswim.svg';
import ytvIconGrey from '../assets/icons/brands/grey/ytv.svg';
import lifetimeIcon from '../assets/icons/brands/grey/lifetime.png';
import magnoliaIcon from '../assets/icons/brands/grey/magnolia.svg';


import globalTVLogoColoured from '../assets/icons/brands/coloured/GlobalTV.svg';
import showcaseLogoColoured from '../assets/icons/brands/coloured/Showcase.svg';
import wnetworkLogoColoured from '../assets/icons/brands/coloured/WNetwork.svg';
import sliceLogoColoured from '../assets/icons/brands/coloured/Slice.svg';
import historyLogoColoured from '../assets/icons/brands/coloured/History.svg';
import historyLogoBlack from '../assets/icons/brands/coloured/History_black.svg';
import globalNewsLogoColoured from '../assets/icons/brands/coloured/GlobalNews.svg';
import hgtvLogoColoured from '../assets/icons/brands/coloured/HGTV.svg';
import foodNetworkLogoColoured from '../assets/icons/brands/coloured/FoodNetwork.svg';
import natGeoLogoColoured from '../assets/icons/brands/coloured/NatGeo.svg';
import adultSwimLogoColoured from '../assets/icons/brands/coloured/AdultSwim.svg';
import ytvLogoColoured from '../assets/icons/brands/coloured/YTV.svg';

export const globalTV = {
	origin: 'global',
	images: {
		icon: {
			backgroundImage: globalTVIcon,
			backgroundImageGrey: globalTVIconGrey,
		},
		logo: 'https://www.globaltv.com/wp-content/themes/globaltv-child/assets/icons/sitelogo.svg',
		coloured: globalTVLogoColoured,
		altTag: 'Global'
	},
	placeholderImages: {
		showVertical: globalShowPlaceholder,
		showHero: globalHeroPlaceholder,
		video: globalVideoPlaceholder,
	}
};

export const wNetwork = {
	origin: 'wnetwork',
	images: {
		icon: {
			backgroundImage: wnetworkIcon,
			backgroundImageGrey: wnetworkIconGrey,
		},
		logo: 'https://www.wnetwork.com/wp-content/themes/wnetwork-child/assets/icons/sitelogo.svg',
		coloured: wnetworkLogoColoured,
		altTag: 'W Network'
	},
	placeholderImages: {
		showVertical: wNetworkShowPlaceholder,
		showHero: wNetworkHeroPlaceholder,
		video: wNetworkVideoPlaceholder,
	}
}

export const history = {
	origin: 'history',
	images: {
		icon: {
			backgroundImage: historyIcon,
			backgroundImageGrey: historyIconGrey,
		},
		logo: historyLogoColoured,
		coloured: historyLogoBlack,
		altTag: 'The HISTORY® Channel'
	},
	placeholderImages: {
		showVertical: historyShowPlaceholder,
		showHero: historyHeroPlaceholder,
		video: historyVideoPlaceholder,
	}
}

export const showcase = {
	origin: 'showcase',
	images: {
		icon: {
			backgroundImage: showcaseIcon,
			backgroundImageGrey: showcaseIconGrey,
		},
		logo: 'https://www.showcase.ca/wp-content/themes/showcase-child/assets/icons/sitelogo.svg',
		coloured: showcaseLogoColoured,
		altTag: 'Showcase'
	},
	placeholderImages: {
		showVertical: showcaseShowPlaceholder,
		showHero: showcaseHeroPlaceholder,
		video: showcaseVideoPlaceholder,
	}
}

export const slice = {
	origin: 'slice',
	images: {
		icon: {
			backgroundImage: sliceIcon,
			backgroundImageGrey: sliceIconGrey,
		},
		logo: 'https://www.slice.ca/Content/images/logos/slice-logo-2018.svg',
		coloured: sliceLogoColoured,
		altTag: 'Slice'
	},
	placeholderImages: {
		showVertical: sliceShowPlaceholder,
		showHero: sliceHeroPlaceholder,
		video: sliceVideoPlaceholder,
	}
}

export const hgtv = {
	origin: 'hgtv',
	images: {
		icon: {
			backgroundImage: hgtvIcon,
			backgroundImageGrey: hgtvIconGrey,
		},
		logo: hgtvIcon,
		coloured: hgtvLogoColoured,
		altTag: 'HGTV Canada'
	},
	placeholderImages: {
		showVertical: hgtvShowPlaceholder,
		showHero: hgtvHeroPlaceholder,
		video: hgtvVideoPlaceholder,
	}
}

export const foodNetwork = {
	origin: 'food',
	images: {
		icon: {
			backgroundImage: foodNetworkIcon,
			backgroundImageGrey: foodNetworkIconGrey,
		},
		logo: foodNetworkLogoColoured,
		coloured: foodNetworkLogoColoured,
		altTag: 'Food Network Canada'
	},
	placeholderImages: {
		showVertical: foodNetworkShowPlaceholder,
		showHero: foodNetworkHeroPlaceholder,
		video: foodNetworkVideoPlaceholder,
	}
}

export const globalNews = {
	origin: 'globalnewsott',
	images: {
		icon: {
			backgroundImage: globalNewsIcon,
			backgroundImageGrey: globalNewsIconGrey,
		},
		logo: globalNewsLogoColoured,
		coloured: globalNewsIconGrey,
		altTag: 'Global News - '
	},
	placeholderImages: {
		showVertical: globalNewsShowPlaceholder,
		showHero: globalNewsHeroPlaceholder,
		video: globalNewsVideoPlaceholder,
	}
}

export const GlobalNews = {
	origin: 'globalstream',
	images: {
		icon: {
			backgroundImage: globalNewsIcon,
			backgroundImageGrey: globalNewsIconGrey,
		},
		logo: globalNewsLogoColoured,
		coloured: globalNewsIconGrey,
		altTag: 'Global News - '
	},
	placeholderImages: {
		showVertical: globalNewsShowPlaceholder,
		showHero: globalNewsHeroPlaceholder,
		video: globalNewsVideoPlaceholder,
	}
}

export const natGeo = {
	origin: 'natgeo',
	images: {
		icon: {
			backgroundImage: natGeoIcon,
			backgroundImageGrey: natGeoIconGrey,
		},
		logo: natGeoIcon,
		coloured: natGeoLogoColoured,
		altTag: 'National Geographic'
	},
	placeholderImages: {
		showVertical: natGeoShowPlaceholder,
		showHero: natGeoHeroPlaceholder,
		video: natGeoVideoPlaceholder,
	}
};

export const adultSwim = {
	origin: 'adultswim',
	images: {
		icon: {
			backgroundImage: adultSwimIcon,
			backgroundImageGrey: adultSwimIconGrey,
		},
		logo: adultSwimIcon,
		coloured: adultSwimLogoColoured,
		altTag: 'Adult Swim'
	},
	placeholderImages: {
		showVertical: adultSwimShowPlaceholder,
		showHero: adultSwimHeroPlaceholder,
		video: adultSwimVideoPlaceholder,
	}
};

export const ytv = {
	origin: 'ytv',
	images: {
		icon: {
			backgroundImage: ytvIcon,
			backgroundImageGrey: ytvIconGrey,
		},
		logo: 'https://www.globaltv.com/wp-content/themes/globaltv-child/assets/icons/sitelogo.svg',
		coloured: ytvLogoColoured,
	},
	placeholderImages: {
		showVertical: ytvShowPlaceholder,
		showHero: ytvHeroPlaceholder,
		video: ytvVideoPlaceholder,
	}
};

// Default placeholders for lifetime origin.

export const lifetime = {
	origin: 'lifetime',
	images: {
		icon: {
			backgroundImage: lifetimeIcon,
		},
		altTag: 'Lifetime',
		logo: lifetimeIcon,
	},
	placeholderImages: {
		showVertical: lifetimeShowPlaceholder,
		showHero: lifetimeHeroPlaceholder,
		video: lifetimeVideoPlaceholder,
	}
};

export const magnolia = {
	origin: 'magnolia',
	images: {
		icon: {
			backgroundImage: magnoliaIcon,
		},
		altTag: 'Magnolia',
		logo: magnoliaIcon,
	},
	placeholderImages: {
		showVertical: magnoliaShowPlaceholder,
		showHero: magnoliaHeroPlaceholder,
		video: magnoliaVideoPlaceholder,
	}
};

export const byOrigin = (origin) => {
	switch (origin) {
	case globalTV.origin:
		return globalTV;

	case wNetwork.origin:
		return wNetwork;

	case history.origin:
		return history;

	case showcase.origin:
		return showcase;

	case slice.origin:
		return slice;

	case hgtv.origin:
		return hgtv;

	case foodNetwork.origin:
		return foodNetwork;

	case globalNews.origin:
		return globalNews;

	case GlobalNews.origin:
		return GlobalNews;

	case natGeo.origin:
		return natGeo;

	case adultSwim.origin:
		return adultSwim;

	case ytv.origin:
		return ytv;

	case lifetime.origin:
		return lifetime;

	case magnolia.origin:
		return magnolia;

	default:
		return globalTV;
	}
}

export default {
	globalTV,
	wNetwork,
	history,
	showcase,
	slice,
	hgtv,
	foodNetwork,
	globalNews,
	natGeo,
	adultSwim,
	ytv,
	lifetime
}
