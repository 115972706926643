import { computerQuestions } from './deviceBasedQuestionaries'
import { AppleMobileQuestions } from './deviceBasedQuestionaries'
import { AppleTVQuestions } from './deviceBasedQuestionaries'
import { AndroidMobileQuestions } from './deviceBasedQuestionaries'
import { AndroidTVQuestions } from './deviceBasedQuestionaries'
import { AmazonTVQuestions } from './deviceBasedQuestionaries'
import { Chromecast } from './deviceBasedQuestionaries'
import { LGTVQuestions } from './deviceBasedQuestionaries'
import { RokuQuestions } from './deviceBasedQuestionaries'
import  { SamsungQuestions } from './deviceBasedQuestionaries'

export const dropDownOptions = [
	{
		id: 'Computer Desktop',
		value: computerQuestions
	},
	{
		id: 'Apple iPhone/iPad',
		value: AppleMobileQuestions

	},
	{
		id: 'Apple TV',
		value: AppleTVQuestions

	},
	{
		id: 'Android Phone/Tablet',
		value: AndroidMobileQuestions

	},
	{
		id: ' Android TV',
		value: AndroidTVQuestions

	},
	{
		id: 'Amazon Fire TV',
		value: AmazonTVQuestions

	},
	{
		id: 'Chromecast',
		value: Chromecast

	},
	{
		id: 'LG Smart TV',
		value: LGTVQuestions

	},
	{
		id: 'Roku',
		value: RokuQuestions
	},
	{
		id: 'Samsung Smart TV',
		value: SamsungQuestions
	},
]