import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withSearch from '../../utility/withSearch';
import withView from '../../utility/withView';
import LiveTVView from './LiveTVView';
import NotFound from '../NotFound/NotFound';
import { byOrigin } from '../../../brands/brands';
import fromVms from '../../utility/fromVms';

import queryString from 'query-string';

class LiveTV extends Component {

	static propTypes = {
		loading: PropTypes.bool,
		location: PropTypes.object,
		viewId: PropTypes.string,
		items: PropTypes.array,
		match: PropTypes.shape({
			params: PropTypes.shape({
				brand: PropTypes.string,
				stream: PropTypes.string,
				channelId: PropTypes.string,
				collectionId: PropTypes.string,
			})
		})
	}

	render() {

		const { viewId, match, location } = this.props;
		let currentBrand = (match) && match.params.brand ? match.params.brand : 'global';

		if ( this.props.items.length > 0 ) {
			currentBrand = this.props.items[0].origin;
		}

		const query = queryString.parse(location.search);
		if ( query.origin ) {
			currentBrand = query.origin;
		}


		const availableBrands = Object.keys(byOrigin);
		if (!this.props.loading && !availableBrands.includes(currentBrand)) {
			return <NotFound />;
		}

		return (
			<div className="LiveTV">
				<LiveTVView viewId={viewId} channelId={match.params.channelId} collectionId={match.params.collectionId} selectedBrand={currentBrand} channel={location.selectedChannel}/>
			</div>
		)
	}
}

const propMapping = (props) => {

	// get the details of the channel using channelId
	return {
		guid: props.match.params.channelId,
	}
}

export default withSearch(withView(fromVms(LiveTV, propMapping), 'web_livetv_view'));
